export const HOST = process.env.REACT_APP_API_URL;
export const KOCEK_HOST = process.env.REACT_APP_KOCEK_API_URL;
export const API_ID = process.env.REACT_APP_API_ID;
export const API_KEY = process.env.REACT_APP_API_KEY;
export const PRTNRID = process.env.REACT_APP_PRTNRID;
const URI = "coin-conversion";
export const URIAdmin = `${URI}/admin`;
export const URIMerchant = `${URI}/merchant`;

export const LOGIN = `${URIAdmin}/login`;
export const VERIFY_EMAIL_CODE = `${URIAdmin}/verify-email-code`;
export const LOGIN_BY_TOKEN = `${URIAdmin}/login-by-token`;
export const RESET_PASSWORD = `${URIAdmin}/reset-password`;
export const CHANGE_PASSWORD = `${URIAdmin}/password`;
export const LOGOUT = `${URIAdmin}/logout`;
export const STORE_URL = `${URIAdmin}/merchant`;
export const TRANSACTION_URL = `${URIAdmin}/transaction`;
export const TRANSACTION_STATISTIC = `${URIAdmin}/statistic-transaction`;
export const TRANSACTION_STATISTIC_KO = `${KOCEK_HOST}/order/statistic-transaction`;

export const GET_BANKS = `${URI}/banks`;

export const ADMIN_URL = `${URIAdmin}/admins`;
export const MERCHANT_URL = `${URIMerchant}/merchant-account`;

export const SEND_EMAIL_INVITATION = `${URIAdmin}/send-invitation`;
export const SEND_EMAIL_INVITATION_ADMIN = `${URIAdmin}/send-invitation-admin`;
export const GET_LOGS = `${URIAdmin}/logs`;

export const GENERATE_WITHDRAWAL_TOKEN = `${KOCEK_HOST}/auth/generate-auth-token`;
export const GET_ORDER_LISTING = `${KOCEK_HOST}/order/admin/get-order-listing`;

export const GET_ORDER_LOG = `${KOCEK_HOST}/order/get-order-log`;
export const GET_PRODUCT_MERCHANT = `${KOCEK_HOST}/product/get-product-by-merchant`;
