import { Upload, Button, Image, message } from "antd";
import { UploadOutlined, CloseCircleFilled } from "@ant-design/icons";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const ImageStyle = styled.span`
  .relative-block {
    position: relative;
    width: 75%;
  }
  .clear-icon {
    position: absolute;
    top: 0;
    right: 0;
    color: red;
    font-size: 25px;
    z-index: 1;
    transform: translate(50%, -50%);
    background: white;
    border-radius: 50%;
    padding: 3px;
  }
`;

const UploadInput = (props) => {
  const { t } = useTranslation();
  const { value, onChange } = props;
  const handleChangeLogo = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
      return false;
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      onChange(reader.result);
    };
    return false;
  };
  const handleRemove = () => {
    onChange(null);
  };
  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Upload
          name="store_logo"
          accept="image/*"
          beforeUpload={handleChangeLogo}
          multiple={false}
          showUploadList={false}
          fileList={[]}
        >
          <Button icon={<UploadOutlined />}>{t("selectFile")}</Button>
        </Upload>
        {value && (
          <ImageStyle>
            <div className="relative-block">
              <CloseCircleFilled className="clear-icon" onClick={handleRemove} />
              <Image src={value} width="100%" />
            </div>
          </ImageStyle>
        )}
      </div>
    </>
  );
};

export default UploadInput;
