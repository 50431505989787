import { formatCurrency } from "utils";

const my = {
  routeName: {
    home: "Halaman utama",
    stores: "Lokasi",
    QR: "Jana kod QR",
    transactionHistory: "Sejarah transaksi",
    setting: "Tetapan",
    adminManage: "Pengurusan pentadbiran",
    logs: "Rekod tukaran syiling",
    globalFee: "Tetapan caj global",
    orders: "Orders",
    product: "Product",
    kocekOut: "Kocek Out",
    kocekIn: "Kocek In",
  },
  logOut: {
    label: "Log keluar",
    confirmTitle: "Sahkan log keluar",
    confirmMessage: "Adakah anda pasti mahu log keluar?",
  },
  openStore: {
    confirmTitle: "Sedang mengemaskini status buka",
    confirmMessage:
      "Membuka lokasi bermaksud pelanggan boleh datang untuk tukaran syiling. Sila sahkan anda mahu membuka lokasi",
  },
  closeStore: {
    confirmTitle: "Sedang mengemaskini status tutup",
    confirmMessage:
      "Menutup lokasi bermaksud pelanggan tidak boleh datang untuk tukaran syiling. Sila sahkan anda mahu menutup lokasi",
  },
  confirmActiveStore: {
    title: "Aktifkan lokasi",
    message:
      "Lokasi aktif dapat log masuk ke tapak web penjual dan menerima tukaran syiling. Sila sahkan untuk aktifkan",
  },
  confirmInactiveStore: {
    title: "Nyahaktifkan lokasi",
    message:
      "Lokasi yang tidak aktif tidak dapat log masuk ke tapak web penjual dan tidak dapat menerima tukaran syiling. Sila sahkan untuk nyahaktifkan",
  },

  confirmEditTransaction: {
    title: "Kemaskini data transaksi",
    message: "Data transaksi akan dikemaskinikan. Adakah anda ingin meneruskan?",
  },

  statisticTb: {
    totalTransaction: "Jumlah transaksi",
    totalRM: "Jumlah kutipan RM",
    totalCompleted: "Jumlah transaksi selesai",
    RMCompleted: "Jumlah RM selesai",
    toBank: "Jumlah transaksi ke bank",
    RMToBank: "Jumlah RM ke bank",
    toCharity: "Jumlah transaksi derma",
    RMToCharity: "Jumlah RM derma",
    RMFee: "Jumlah yuran transaksi",
  },

  address: "Alamat",
  postcode: "Poskod",
  state: "Negeri",
  closed: "Tutup",
  code: "Kod",
  digit: "Digit",
  email: "Emel",
  endTime: "Waktu Tutup",
  goBack: "Kembali",
  language: "Bahasa",
  open: "Buka",
  password: "Kata laluan",
  startTime: "Waktu buka",
  storeID: "ID lokasi",
  storeEmail: "Emel lokasi",
  storeName: "Nama lokasi",
  storePhone: "Nombor telefon lokasi",
  storeLogo: "Logo lokasi",
  selectFile: "Pilih fail",
  storeAddress: "Alamat lokasi",
  closeOn3rdSaturday: "Tutup pada Sabtu ke-3",
  closeOn3rdSaturdayToolTip: "Pilih untuk tutup pada Sabtu ke-3 setiap bulan",
  closeOnPublicHoliday: "Tutup pada cuti umum",
  closeOnPublicHolidayToolTip: "Pilih untuk tutup pada cuti umum",
  voucherIsOn: "Baucar",
  voucherIsOnToolTip: "Tanda untuk pilihan baucar",
  submit: "Hantar",
  save: "Simpan",
  emailDescription: `Akaun admint akan dibuat menggunakan emel ini.\nJemputan pendaftaran akan dihantar secara otomatik.`,
  emailSentNotification: (email) => `Jemputan telah dihantar ke emel ${email} anda.`,

  monday: "Isnin",
  tuesday: "Selasa",
  wednesday: "Rabu",
  thursday: "Khamis",
  friday: "Jumaat",
  saturday: "Sabtu",
  sunday: "Ahad",

  coinDeposit: "TUKARAN SYILING",
  status: "STATUS",
  success: "BERJAYA",
  failed: "Gagal",
  transactionId: "ID Transaksi",
  clientFullName: "NAMA PENUH PELANGGAN",
  clientPhoneNumber: "NOMBOR TELEFON BIMBIT PELANGGAN",
  timeCreated: "MASA DICIPTA",
  transactionFee: "TCAJ TRANSAKSI",
  back: "KEMBALI",
  depositAmount: "Amaun dipindahkan (RM)",
  amount: "JUMLAH (RM)",
  confirmGenerateQrCode: (amount) => `Penukaran syiling kepada RM${amount}. Jana kod QR?`,
  pleaseInputAmountRM: "Sla masukkan jumlah (RM)",
  generateQrCode: "Sediakan kod QR",
  expiresIn: "Luput dalam masa",
  qrCodeExpired: "KOD QR LUPUT",
  regenerateQrCode: "Jana kod QR yang baru",
  changeAmount: "Tukar jumlah",
  createTransactionSuccess: "Transaksi dimulakan",
  depositTimestamp: "Masa tukaran",

  id: "ID",
  transactionDate: "Tarikh transaksi",
  phoneNumber: "Nombor telefon bimbit",
  customerName: "Nama pelanggan",
  help: "Bantuan",
  requestHelp: "Mohon bantuan",
  timeAt: "Masa",
  dateRange: "Tempoh",
  updateSettingsSuccess: "Tetapan telah dikemaskini",
  resendEmail: "Hantar semula emel",
  emailVerifySent: "Kod 6-digit telah dihantar kepada anda melalui emel dan SMS.",
  latitude: "Latitud",
  longitude: "Longitud",
  miniumAmountRequired: "Jumlah minimum yang diperlukan",
  locationSetting: "Tetapan lokasi",
  needHelp: "Perlu bantuan?",
  tooltipFindLatitude: "Cari atau masukkan latitud dan longitud",
  createStore: "Cipta lokasi",
  editStore: "Ubah suai lokasi",
  storeNotFound: "Lokasi tidak dijumpai",
  backToListStore: "Kembali ke senarai lokasi",
  action: "Tindakan",
  transferred: "Syiling diterima",
  export: "Eksport",
  transactionDetail: "Butiran transaksi",
  sendTo: "Hantar kepada",
  charity: "Amal jariah",
  voucher: "Baucar",
  bank: "Bank",
  bankAccountName: "Nama pemegang akaun bank",
  bankAccountNumber: "Nombor akaun bank",
  transactionStatus: "Status transaksi",
  bankName: "Nama bank",
  search: "Cari",
  resetFilter: "Set semula tapisan",
  login: "Log masuk",
  verifyEmail: "Sahkan alamat emel",
  changeOnlineHours: "Kemaskini waktu operasi",
  clickToAddTimeSlot: "Klik sini untuk tambah slot waktu yang baru bagi hari",
  clickHereToOnline: "Klik di sini untuk membuka lokasi",
  offline: "Tutup",
  online: "Buka",
  active: "Aktif",

  forgotPassword: "Lupa kata laluan?",
  account: "Akaun",
  changePassword: "Ubah kata laluan",
  currentPassword: "Kata laluan kini",
  newPassword: "Kata laluan baharu",
  confirmNewPassword: "Sahkan kata laluan baharu",
  passwordNotMatch: "Kata laluan baharu tidak sepadan",
  changePasswordSuccess: "Kata laluan berjaya diubah",
  backToLogin: "Kembali ke log masuk",
  forgotPass: {
    emailSent: "Semak emel anda",
    message1: "Emel telah dikirim kepada",
    message2: "Ikuti arahan dalam emel untuk set semula kata laluan anda",
    messageEnterMail: "Masukkan alamat emel anda. Kami akan mengirim pautan untuk set semula kata laluan anda",
  },
  resetPassword: "Set semula kata laluan",
  continue: "Teruskan",

  createAdmin: "Cipta admin",
  fullName: "Nama penuh",
  role: "Peranan",
  admin: "Admin",
  superAdmin: "Super admin",
  createSuccess: "Berjaya dicipta",
  sendInvitationEmail: "Hantar emel undangan",
  objSendInvitation: {
    title: "Hantar emel undangan kepada lokasi",
    content: "Anda akan menghantar emel undangan. Teruskan?",
    success: "Emel undangan berjaya dihantar",
    error1: "Tidak dapat menghantar emel kepada ",
    btnReSend: "Hantar semula emel",
    sendingEmail: "Emel sedang dihantar",
  },
  objExportError: {
    title: "Ralat data",
    isDone: "Transaksi SELESAI",
    isMissingData: "Data tak lengkap dalam transaksi",
    isConfirmed: "Transaksi DISAHKAN",
  },
  wantToContinue: "Teruskan?",
  exportBulkPayment: "Eksport untuk Bayaran Pukal",
  requestBody: "Badan Peminta",
  responseMessage: "Mesej Balasan",
  statusCode: "Kod status",
  api: "API",
  createdAt: "Dicipta pada",
  adminNotFound: "Admin tidak dijumpai",
  backToAdminManage: "Kembali kepada pengurusan admin",
  confirmInactiveAdmin: {
    title: "Nyahaktifkan akaun admin",
    message: "Akaun ini akan dilog keluar daripada semua peranti dan tidak dapat log masuk semula. Teruskan?",
  },
  confirmActiveAmin: {
    title: "Aktifkan akaun pengurusan",
    message: "Akaun ini boleh log masuk ke halaman ini. Teruskan?",
  },
  editAdmin: "Ubah suai admin",
  updateSuccess: "Kemaskini berjaya",
  waitingTransfer: "Menunggu pemindahan",
  coinReceived: "Wang syiling diterima",
  paid: "Dibayar",
  requesthelpTitle: "MOHON BANTUAN UNTUK TRANSAKSI INI",
  purchaseRequired: "Pembelian disyaratkan",
  purchaseRequiredToolTip:
    "Aktifkan pilihan ini jika pembelian di lokasi ini disyaratkan sebelum tukaran syiling dibenarkan",
  alertPurchaseRequire: (amount) =>
    amount ? `Pembelian minimum RM${formatCurrency(amount)} disyaratkan` : "Pembelian disyaratkan",
  exportingFile: "Mengeksport fail",
  statisticBy: "Statistik mengikut",
  date: "Tarikh",
  week: "Minggu",
  day: "Hari",
  month: "Bulan",
  year: "Tahun",
  createdBy: "Dikendalikan Oleh",
  requireBankNumberAccountLength: (min, max) =>
    `Nombor akaun mestilah ${min === max ? `${min} angka` : `${min} hingga ${max} angka`}`,

  selectBank: "Pilih Bank",
  pleaseInputBankAccNo: "Sila masukkan nombor akaun Bank",
  bankAccNoInvalid: "Nombor akaun Bank tidak sah",
  pleaseInputBankAccName: "Sila masukkan nama pemegang akaun Bank",
  bankAccNameInvalid: "Nama pemegang akaun Bank tidak sah",
  lastEditByAdmin: "Penukaran terakhir oleh Pengurus",
  edited: "Ditukar",
  editBankInfo: "Kemaskini Informasi Bank",
  logId: "Log masuk ID",
  fee: "Caj",
  manageFees: "Kemaskini caj",
  globalFee: "Caj Global",
  delete: "Padam",
  minRequiredCoinsToConvert: "Amaun minima untuk penukaran wang syiling",
  feeValue: "Nilai Caj Bayaran",
  fixedAmount: "Amaun tetap",
  percentage: "Peratusan",
  type: "Jenis",
  addNewTier: "Tambah peringkat baharu",
  createNewGlobalFee: "Cipta yuran global baharu",
  feeName: "Nama Caj",
  tiers: "Peringkat",
  cancel: "Batal",
  selectFeeYouWantToApplyGlobally: "Sila pilih Caj yang anda ingin memohon secara global",
  coinAmount: "Jumlah wang syiling",
  coinAmountRM: "Jumlah wang syiling (RM)",
  serviceFee: "Caj perkhidmatan",
  applyFeeAsActiveGlobalFee: (feeName) => (
    <div>
      Mohon <b>{feeName}</b> sebagai Caj global yang aktif?
    </div>
  ),
  allMerchantAreUsingGlobalFeeWillBeAffected:
    "Semua peniaga yang menggunakan Caj global akan terkesan/terjejas/dipengaruhi",
  minimumRequiredCoinsToBeConverted: "Jumlah minima wang syiling yang ditukarkan",
  useGlobalFeeForThisLocation: "Gunakan Caj global untuk lokasi ini",
  thisMerchantWillUseGlobalFee: (locationName) => (
    <div>
      <b>{locationName}</b> akan menggunakan Caj Asal dan bukannya Caj Tersuai
    </div>
  ),
  applyGlobalFeeToThisLocation: "Mohon Caj global bagi lokasi ini?",
  applyFeeAsActiveOfThisLocation: (feeName) => (
    <div>
      Tetapkan <b>{feeName}</b> sebagai caj aktif untuk lokasi ini?
    </div>
  ),
  createNewCustomFee: "Cipta Caj tersuai baharu",
  createACustomFee: "Cipta Caj tersuai",
  editGlobalFee: "Kemaskini Caj global",
  editFee: "Kemaskini Caj",
  orSelectACustomFeeYouWannaApply: "Gunakan caj tersuai sedia ada",
  feeAmount: "Jumlah Caj (RM)",
  bookingUrl: "Booking URL",
  pendingBankApproval: "Menunggu kelulusan bank",
  updateStatus: "Kemaskini status",
  updateTransactionStatus: "Kemas kini Status Transaksi",
  amountTransferred: "Jumlah yang dipindahkan:",
  statusLabel: "Status:",
  newStatus: "Status Baru:",
  transactionIdLabel: "ID Transaksi:",
  pleaseSelect: "Sila pilih status",
  viewLogs: "Lihat log",
  other: "Other",
  description: "Description",
  pleaseInsertYourReason: "Please insert your reason",
  invalidAccountNumber: "Invalid account number",
  kiosk: "Kiosk",
  kioskType: "Jenis Kiosk",
};

export default my;
