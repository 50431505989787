import React, { useEffect, useRef, useState } from "react";
import { Select, Form } from "antd";
import Highcharts from "highcharts";
import HighchartsExporting from "highcharts/modules/exporting";
// import HighchartsReact from 'highcharts-react-official';
const { Option } = Select;

HighchartsExporting(Highcharts);
function Graph({ dataSource, statisticBy }) {
  const [graphOption, setGraphOption] = useState({
    title: "Total Collection",
    prop: "total_collection_RM",
  });
  const chartRef = useRef(null);

  useEffect(() => {
    const getWeekNumber = (date) => {
      if (!(date instanceof Date) || isNaN(date)) {
        return date; // Return the input if it's not a valid date
      }

      const oneJan = new Date(date.getFullYear(), 0, 1);
      const days = Math.floor((date - oneJan) / (24 * 60 * 60 * 1000));
      return Math.ceil((days + oneJan.getDay() + 1) / 7);
    };

    if (chartRef.current) {
      const convertedCategories = [];
      const nonConvertedCategories = [];

      const options = {
        chart: {
          type: "column",
          zoomType: "x",
          panning: true,
          panKey: "shift",
        },
        title: {
          text: graphOption.title,
        },
        yAxis: {
          title: {
            text: "Amount (RM)",
          },
        },
        xAxis: {
          title: {
            text: "Date",
          },
          categories: dataSource?.map((item) => {
            var date = new Date(item.date);
            if (statisticBy === "DAILY") {
              return date.toLocaleDateString("en-GB");
            } else if (statisticBy === "WEEKLY") {
              if (!isNaN(date)) {
                const weekNumber = getWeekNumber(date);
                convertedCategories.push(`2023-${weekNumber}`);
                return `2023-${weekNumber}`;
              } else {
                nonConvertedCategories.push(item.date);
                return item.date;
              }
              //  return item.date;
              // const weekNumber = getWeekNumber(date);
              // return `2023-${weekNumber}`;
            } else if (statisticBy === "MONTHLY") {
              return date.toLocaleDateString("en-GB", { month: "short" });
            } else if (statisticBy === "YEARLY") {
              return date.toLocaleDateString("en-GB", { year: "numeric" });
            } else {
              return [];
            }
          }),
        },
        series: [
          {
            showInLegend: false,
            name: "RM",
            data: dataSource?.map((item) => {
              return graphOption.prop === "average_transaction_fee"
                ? [Math.round((item.total_collection_RM_to_bank / item.total_transaction) * 100) / 100]
                : [item[graphOption.prop] || 0];
            }),
          },
        ],
        exporting: {
          enabled: true,
        },
      };

      Highcharts.chart(chartRef.current, options);
    }
  }, [dataSource, graphOption, statisticBy]);

  return (
    <div>
      <div>
        <Form>
          <label>Graph Data: </label>
          <Select
            style={{ width: "25%" }}
            placeholder={graphOption.title}
            value={graphOption.prop}
            onChange={(e, option) => {
              setGraphOption({
                title: option.children,
                prop: option.value,
              });
            }}
          >
            <Option value="total_collection_RM">Total Collection</Option>
            <Option value="total_transaction_fee">Total Transaction fee</Option>
            <Option value="total_transaction">Total Transaction</Option>
            <Option value="average_transaction_fee">Average RM per transaction</Option>
          </Select>
        </Form>
      </div>
      <div ref={chartRef} />
    </div>
  );
}

export default Graph;
