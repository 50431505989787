import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import get from "lodash/get";
import { ACTIONS_MAP } from "./constants";

const useStoreColumns = () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        title: t("logId"),
        dataIndex: "id",
        key: "id",
        width: "100px",
      },
      {
        title: t("action"),
        dataIndex: "action",
        key: "action",
        render: (action) => get(ACTIONS_MAP, action, action),
      },
      {
        title: t("api"),
        dataIndex: "api",
        key: "api",
      },
      {
        title: <div style={{ textTransform: "capitalize" }}>{t("status").toLowerCase()}</div>,
        dataIndex: "status_code",
        key: "status_code",
        width: "120px",
        render: (status_code) =>
          status_code === 200 ? (
            <div style={{ textTransform: "capitalize" }}>{t("success").toLowerCase()}</div>
          ) : (
            t("failed")
          ),
      },
      {
        title: t("responseMessage"),
        dataIndex: "message",
        key: "message",
      },
      {
        title: t("createdBy"),
        dataIndex: "actor_name",
        key: "actor_name",
      },
      {
        title: t("role"),
        dataIndex: "actor_role",
        key: "actor_role",
      },
      {
        title: t("createdAt"),
        dataIndex: "created_at",
        key: "created_at",
        render: (date) => date && moment(date).format("MMMM Do YYYY h:mm:ss A"),
      },
    ],
    [t]
  );
};

export default useStoreColumns;
