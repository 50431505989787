import React from "react";
import get from "lodash/get";
import { PlusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Form, Button, Row, Col } from "antd";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { defaultWorkingTime } from "variables";
import { validateWorkingTime } from "utils";
import ShiftSetting from "./ShiftSetting";

const WorkingTimeStyle = styled.div`
  margin-left: 15px;
  border-left: 1px solid;

  &.disabled {
    opacity: 0.5;
  }

  .header {
    padding: 0 15px;
  }

  .day-row {
    padding: 15px 0 15px 15px;

    &:nth-child(odd) {
      background: #eeeeee;
    }
  }

  .remove-btn {
    background: #ff4d4f;
    color: white;
    margin: 0 15px;
  }

  .status-close {
    margin-left: 20px;
    font-weight: bold;
    color: #ff4d4f;
    line-height: 30px;
  }

  .btn-going-online {
    margin-left: 10px;
  }

  .shift-block {
    width: 100%;
    max-width: 500px;
    margin-bottom: 10px;
    text-align: center;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const days = [
  { name: "monday", value: "mon" },
  { name: "tuesday", value: "tue" },
  { name: "wednesday", value: "wed" },
  { name: "thursday", value: "thu" },
  { name: "friday", value: "fri" },
  { name: "saturday", value: "sat" },
  { name: "sunday", value: "sun" },
];

const WorkingTimeSetting = (props) => {
  const { is_available } = props;
  const { t } = useTranslation();

  const disabled = !is_available;

  return (
    <WorkingTimeStyle className={disabled ? "disabled" : ""}>
      <div className="header">
        <h3>{t("locationOperationHours")}</h3>
      </div>
      {days.map((day) => {
        return (
          <Form.List key={day.value} name={["open_day", day.value, "shifts"]}>
            {(fields, { add, remove }) => {
              const isOffline = get(fields, "length", 0) === 0;
              return (
                <Row className="day-row">
                  <Col flex="110px">
                    <h3>{t(day.name)}</h3>
                  </Col>
                  <Col flex="auto">
                    {!isOffline ? (
                      <>
                        {fields.map((field) => {
                          return (
                            <div className="shift-block">
                              <Form.Item
                                noStyle
                                key={field.key}
                                name={field.name}
                                rules={[
                                  {
                                    validator(rule, value) {
                                      if (validateWorkingTime(value)) {
                                        return Promise.resolve();
                                      }

                                      return Promise.reject(t("error:workingTimeInvalid"));
                                    },
                                  },
                                ]}
                              >
                                <ShiftSetting
                                  disabled={disabled}
                                  onRemove={() => {
                                    remove(field.name);
                                  }}
                                />
                              </Form.Item>
                            </div>
                          );
                        })}
                        <div className="shift-block">
                          <Button
                            block
                            type="link"
                            size="small"
                            icon={<PlusOutlined />}
                            disabled={disabled}
                            onClick={() => add(defaultWorkingTime)}
                          >
                            <span>
                              {t("clickToAddTimeSlot")} <b>{t(day.name)}</b>
                            </span>
                          </Button>
                        </div>
                      </>
                    ) : (
                      <div className="status-close">
                        {t("offline")}
                        <Button
                          className="btn-going-online"
                          size="small"
                          icon={<PlusCircleOutlined />}
                          disabled={disabled}
                          onClick={() => add(defaultWorkingTime)}
                        >
                          {t("clickHereToOnline")}
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>
              );
            }}
          </Form.List>
        );
      })}
    </WorkingTimeStyle>
  );
};

export default WorkingTimeSetting;
