import { useTranslation } from "react-i18next";
import { notification } from "antd";
import { useState, useEffect } from "react";
import useAPILoading from "hooks/useAPILoading";
import { useSelector } from "react-redux";
import { userInfoSelector } from "../../../reduxStore/auth/selectors";
import { apiSetTransactionBankInfo } from "APIService/transactionService";
import Modal from "components/antdCustom/Modal";
import { LoadingOutlined } from "@ant-design/icons";
import EditBankInfoForm from "./EditBankInfoForm";
import { apiGetBanks } from "APIService/utils";
import { get, find } from "lodash";

const getBankNameById = (bankId, banks) => {
  return get(find(banks, { id: bankId }), "name");
};

const ModalEditBankInfo = (props) => {
  const { visible, onCompleteEdit, transaction } = props;
  const { t } = useTranslation();
  const userInfo = useSelector(userInfoSelector);
  const [getBank, loadingBank] = useAPILoading(apiGetBanks);
  const [banksData, setBanks] = useState([]);

  const [updateBankInfoApi, loadingUpdateBankInfo] = useAPILoading(apiSetTransactionBankInfo);

  useEffect(() => {
    getBank().then((res) => {
      const banks = get(res, "data.banks");
      setBanks(banks);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    transaction_detail_bank_account_number: oldBankAccNo,
    transaction_detail_bank_account_name: oldBankAccName,
    bank_id: oldBankId,
  } = transaction;

  const onUpdateBankInfo = (form) => {
    const { newBank, newBankAccName, newBankAccNo } = form;
    if (!newBank || !newBankAccName || !newBankAccNo) return;

    if (oldBankId === newBank && oldBankAccNo === newBankAccNo && oldBankAccName === newBankAccName) {
      onCompleteEdit();
      return;
    }

    updateBankInfoApi(transaction.id, {
      newBankId: newBank,
      newBankAccNo,
      newBankAccName,
    }).then((response) => {
      notification.success({
        message: response.data.message,
        placement: "bottomRight",
      });

      const updatedTransaction = {
        ...transaction,
        bank_id: newBank,
        transaction_detail_bank_account_number: newBankAccNo,
        transaction_detail_bank_account_name: newBankAccName,
        bank_name: getBankNameById(newBank, banksData),
        recent_edit_admin_id: userInfo.id,
        recent_edit_admin_name: userInfo.full_name,
      };
      onCompleteEdit(updatedTransaction);
    });
  };

  return (
    <Modal
      title={t("confirmEditTransaction.title")}
      visible={visible}
      okButtonProps={{
        form: "edit-bank-form",
        key: "submit",
        htmlType: "submit",
      }}
      onCancel={onCompleteEdit}
      okText={loadingUpdateBankInfo ? <LoadingOutlined /> : undefined}
      destroyOnClose
    >
      <EditBankInfoForm
        banks={banksData}
        currentBankInfo={transaction}
        onUpdateBankInfo={onUpdateBankInfo}
        loadingBank={loadingBank}
      />
    </Modal>
  );
};

export default ModalEditBankInfo;
