import { URIAdmin } from "./APILinks";
import axios from "./axios";

/**
 * @returns {Promise<Array<{
 *  id: Number
 *  name: String
 *  createdAt: Date
 *  updatedAt: Date
 *  createdBy: Number
 *  feeTiers: {
 *    feeType: 'fixed' | 'percentage'
 *    feeValue: Number
 *    minTierAmount: Number
 *  }
 *  isActive: Boolean
 * }>>}
 */
export const getGlobalFee = () => {
  return axios.get(`${URIAdmin}/global-fee`);
};

export const setGlobalActiveFee = (feeId) => {
  return axios.post(`${URIAdmin}/global-fee/${feeId}/set-active`);
};
