import { Layout } from "antd";

import { getConversionLogs } from "APIService/utils";
import usePagePaginationLogic from "./usePagePaginationLogic";
import Table from "./Table";
import FilterBar from "./FilterBar";

const { Content } = Layout;

const CoinConversionLog = (props) => {
  const { filterBarProps, handleFilterBarChange, tableProps, handleTableChange } = usePagePaginationLogic({
    apiInput: getConversionLogs,
  });

  return (
    <Content
      style={{
        padding: 20,
      }}
    >
      <div style={{ backgroundColor: "white", padding: 20 }}>
        <FilterBar {...filterBarProps} onSubmit={handleFilterBarChange} />
        <Table {...tableProps} onChange={handleTableChange} />
      </div>
    </Content>
  );
};

export default CoinConversionLog;
