import axios from "./axios";
import { STORE_URL, SEND_EMAIL_INVITATION } from "./APILinks";

export const fetchStoreById = (id) => axios.get(STORE_URL + "/" + id);

export const fetchStores = (params) =>
  axios.get(STORE_URL, {
    params,
  });

/**
 * @param {object} store
 * @param {object} store.open_day
 * @param {string} store.is_available set '1' if available and '0' if unavailable
 * @returns {Promise<void>}
 * @example
 * {
 *   is_available: '1',
 *   open_day: {
 *     mon: {
 *       shifts: [
 *         { startTime: new Date().toISOString(), endTime: moment().toISOString() }
 *       ]
 *     }
 *   }
 * }
 */
export const createStore = async (store) => {
  return axios.post(STORE_URL, store);
};

/**
 * @param {object} store
 * @param {object} store.open_day
 * @param {string} store.is_available set '1' if available and '0' if unavailable
 * @returns {Promise<void>}
 * @example
 * {
 *   is_available: '1',
 *   open_day: {
 *     mon: {
 *       shifts: [
 *         { startTime: new Date().toISOString(), endTime: moment().toISOString() }
 *       ]
 *     }
 *   }
 * }
 */
export const updateStore = async (id, store) => {
  return axios.put(STORE_URL + "/" + id, store);
};

export const sendInvitationToStore = (email) => axios.post(SEND_EMAIL_INVITATION, { email });
