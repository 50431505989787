import { useState } from "react";
import { Button, Modal, Checkbox, Dropdown, notification, Radio, Space, Input } from "antd";
import { DownOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { STATUS_TEXT } from "../constant";
import "./style.css";
import { useTranslation } from "react-i18next";
import useAPI from "src/hooks/useAPILoading";
import { apiBulkSetTransactionStatus } from "src/APIService/transactionService";

const getNextStatusText = (status, option = "") => {
  if (status === "WAITING_FOR_TRANSFER") {
    return STATUS_TEXT["PENDING_BANK_APPROVAL"];
  }

  if (status === "FAILED") {
    return STATUS_TEXT["WAITING_FOR_TRANSFER"];
  }

  if (status === "PENDING_BANK_APPROVAL" && option) {
    return option === "DONE" ? STATUS_TEXT["DONE"] : STATUS_TEXT["FAILED"];
  }
};

const nextStatusParam = (status, option = "") => {
  if (status === "WAITING_FOR_TRANSFER") {
    return "PENDING_BANK_APPROVAL";
  }

  if (status === "FAILED") {
    return "WAITING_FOR_TRANSFER";
  }

  if (status === "PENDING_BANK_APPROVAL" && option) {
    return option;
  }
};

const BulkUpdateStatusButton = ({ status, selectedKeys, fetchTransaction, setSelectedKeys, setSelectedItems }) => {
  const { t } = useTranslation();
  const [bulkUpdateStatus, loadingBulkUpdateStatus] = useAPI(apiBulkSetTransactionStatus);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPaidOrFailed, setSelectedPaidOrFailed] = useState("");
  const [isCheckboxSelected, setCheckboxSelected] = useState(false);
  const [failureDescriptionOption, setFailureDescriptionOption] = useState(null);
  const [customFailureDescriptionMsg, setCustomFailureDescriptionMsg] = useState(null);

  const showModal = () => {
    setCheckboxSelected(false);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onChange = (e) => {
    setCheckboxSelected(e.target.checked);
  };

  const handleMenuClick = (item) => {
    setSelectedPaidOrFailed(item.key);
    setCheckboxSelected(false);
    setIsModalOpen(true);
  };

  const isSubmitDisabled = () => {
    if (!(isCheckboxSelected && selectedKeys?.length > 0)) {
      return true
    }

    if (selectedPaidOrFailed === "DONE") {
      return false
    }

    if (selectedPaidOrFailed === "FAILED") {
      if (!failureDescriptionOption) {
        return true
      }
      return failureDescriptionOption === "other" && !customFailureDescriptionMsg ? true : false
    }

    return false
  }

  const items = [
    { label: "Paid", key: "DONE" }, // remember to pass the key prop
    { label: "Failed", key: "FAILED", danger: true },
  ];

  const showError = () => {
    notification.error({
      message: "Please select at least 2 transactions to perform bulk status update.",
      placement: "bottomRight",
    });
  };

  const menuProps = {
    items,
    onClick: selectedKeys && selectedKeys.length > 1 ? handleMenuClick : showError,
  };

  const handleBulkUpdateStatus = () => {
    bulkUpdateStatus({
      newStatus: nextStatusParam(status, selectedPaidOrFailed),
      description: failureDescriptionOption === "other" ? customFailureDescriptionMsg : failureDescriptionOption,
      transactionId: selectedKeys,
    }).then(({ data }) => {
      if (data && data.message) {
        notification.success({
          message: data.message,
          placement: "bottomRight",
        });
        handleCancel();
        fetchTransaction();
        setSelectedItems([]);
        setSelectedKeys([]);
        setCheckboxSelected(false)
        setSelectedPaidOrFailed("")
        setFailureDescriptionOption(null)
        setCustomFailureDescriptionMsg(null)
      }
    });
  };

  const FailedReasonDescription = () => {
    if (selectedPaidOrFailed !== "FAILED" || status !== "PENDING_BANK_APPROVAL") {
      return ""
    }
    return (
      <div style={{ flexDirection: "row", display: "flex", justifyContent: "space-between", marginBottom: "15px" }}>
        <span>Description</span>
        <Radio.Group onChange={e => setFailureDescriptionOption(e.target.value)} value={failureDescriptionOption}>
          <Space direction="horizontal">
            <Radio value="Invalid  account number">{t("invalidAccountNumber")}</Radio>
            <Radio value="other">{t("other")}</Radio>
          </Space>
        </Radio.Group>
      </div>
    )
  }


  return (
    <div>
      {status === "PENDING_BANK_APPROVAL" ? (
        <Dropdown menu={menuProps}>
          <Button type="primary" icon={<DownOutlined />}>
            Bulk Status Update
          </Button>
        </Dropdown>
      ) : (
        <Button type="primary" onClick={selectedKeys && selectedKeys.length > 1 ? showModal : showError}>
          Bulk Status Update
        </Button>
      )}

      {isModalOpen && (
        <Modal
          title="Bulk Status Update"
          open={isModalOpen}
          onCancel={handleCancel}
          footer={[
            <Button key="close" onClick={handleCancel}>
              Close
            </Button>,
            <Button
              key="submit"
              disabled={isSubmitDisabled()}
              type="primary"
              loading={loadingBulkUpdateStatus}
              onClick={handleBulkUpdateStatus}
            >
              Update all transaction
            </Button>,
          ]}
        >
          <p>Please ensure the following transaction are correct before updating to a new status.</p>
          <div className="box">
            <div className="status-title">
              <div
                className="chip"
                style={{
                  backgroundColor: STATUS_TEXT[status].backgroundColor,
                  color: STATUS_TEXT[status].textColor,
                }}
              >
                {STATUS_TEXT[status].textStatus}
              </div>
              <ArrowRightOutlined />
              <div
                className="chip"
                style={{
                  backgroundColor: getNextStatusText(status, selectedPaidOrFailed).backgroundColor,
                  color: getNextStatusText(status, selectedPaidOrFailed).textColor,
                }}
              >
                {getNextStatusText(status, selectedPaidOrFailed).textStatus}
              </div>
            </div>
            <div className="status-body">
              <span style={{ color: "#6C757D", marginBottom: 5 }}>{selectedKeys?.length} Transaction ID: #</span>
              {selectedKeys?.map((item, index) => {
                return index === selectedKeys.length - 1 ? `${item}` : `${item}, `;
              })}
            </div>

          </div>
          {FailedReasonDescription()}
          {failureDescriptionOption === "other" && status === "PENDING_BANK_APPROVAL" && (
            <Input.TextArea
              style={{
                margin: "5px 0 15px 0",
              }}
              value={customFailureDescriptionMsg}
              onChange={(e) => setCustomFailureDescriptionMsg(e.target.value)}
              placeholder={t("pleaseInsertYourReason")}
              autoSize={{ minRows: 3 }}
            />
          )}
          <Checkbox onChange={onChange}>
            I am aware that this is final and will take full responsibility for any necessary reconcilation actions if
            needed
          </Checkbox>
        </Modal>
      )}
    </div>
  );
};

export default BulkUpdateStatusButton;
