import React, { useState, useEffect } from "react";
import { Layout } from "antd";
import FilterBar from "./FilterBar";
import useAPILoading from "hooks/useAPILoading";
import TableFilteredOrders from "./TableFilteredOrders";
import moment from "moment";
import { fetchOrders } from "src/APIService/orders";

const { Content } = Layout;

const getOrdersParams = ({ filter, order_status, pagination = {} } = {}) => {
  const output = {
    ...filter,
    order_status,
    pageSize: pagination.pageSize,
    page: pagination.current,
  };
  return output;
};

const FilteredOrdersList = ({ category, loading, storeList }) => {
  const [getOrders, filterLoading] = useAPILoading(fetchOrders);
  const [resData, setResData] = useState();
  const [params, setParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    order_status: category,
    filter: {},
    sort: {},
  });

  const handleTableChange = (pagination) => {
    setParams({ ...params, pagination });
  };

  const handleFilterChange = (filter) => {
    let { pagination } = params;
    if (filter?.date) {
      filter.date = moment(filter.date).format("YYYY-MM-DD");
    }
    pagination = { ...pagination, current: 1 };
    //set page to 1 when change filter
    setParams({ ...params, filter, pagination });
  };

  const { filter, pagination } = params;

  useEffect(() => {
    getOrders(getOrdersParams(params)).then(({ data }) => {
      setResData(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return (
    <Content
      style={{
        padding: 20,
        background: "white",
      }}
    >
      <FilterBar
        value={filter}
        onChange={handleFilterChange}
        setResData={setResData}
        loading={filterLoading}
        storeList={storeList}
      />
      <TableFilteredOrders
        dataSource={resData?.data}
        pagination={{ ...pagination, total: resData?.total }}
        loading={loading || filterLoading}
        category={category}
        onChange={handleTableChange}
      />
    </Content>
  );
};

export default FilteredOrdersList;
