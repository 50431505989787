import { React } from "react";
import { Tabs } from "antd";
import UserCreateButton from "./UserCreateButton";

const { TabPane } = Tabs;

export default function TabRoles({ onChange, role }) {
  return (
    <Tabs tabBarExtraContent={<UserCreateButton />} defaultActiveKey="" activeKey={role} onChange={onChange}>
      <TabPane tab="All Users" key="" />
      <TabPane tab="Super Admin" key="super_admin" />
      <TabPane tab="Admin" key="admin" />
      <TabPane tab="Retail Staff" key="retail_staff" />
    </Tabs>
  );
}
