import React from "react";
import { Space, Row, Col } from "antd";
import styled from "styled-components";
import { formatCurrency } from "utils";
import { sumTotal } from "../constant";
const TotalItemStyled = styled.b`
  .value-span {
    color: red;
    padding: 30px;
  }
`;
const roundNumber = (number, roundNumber) => +Number.parseFloat(number).toFixed(roundNumber);
const Footer = React.memo(({ dataSource, columns }) => {
  return (
    <Space size="large">
      <Row>
        {columns.map((column) => (
          <Col sm={12} lg={12} xxl={12}>
            <TotalItemStyled>
              {column.title}:{" "}
              <span className="value-span">
                {column.dataIndex === "total_collection_RM_to_bank"
                  ? formatCurrency(
                      roundNumber(
                        sumTotal(dataSource, "total_collection_RM") - sumTotal(dataSource, "total_transaction_fee"),
                        2
                      ) || "0"
                    )
                  : formatCurrency(roundNumber(sumTotal(dataSource, column.dataIndex), 2) || "0")}
              </span>
            </TotalItemStyled>
          </Col>
        ))}
      </Row>
    </Space>
  );
});
export default Footer;