import { useMemo } from "react";
import { Button, Row, Col } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { FEE_TYPE } from "src/variables";
import { Link } from "react-router-dom";

const useStoreColumns = (onClickSendInvitationEmail, itemsLoadingSendEmail) => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        title: t("id"),
        dataIndex: "id",
        key: "id",
        width: "80px",
      },
      {
        title: t("email"),
        dataIndex: "email",
        key: "email",
      },

      {
        title: t("storeName"),
        dataIndex: "store_name",
        key: "store_name",
      },
      {
        title: t("kioskType"),
        dataIndex: "kiosk_type",
        key: "kiosk_type",
        render: (kiosk_type) => {
          return <div style={{ textTransform: "capitalize" }}>{kiosk_type}</div>;
        },
      },
      {
        title: t("address"),
        dataIndex: "address",
        key: "address",
      },
      {
        title: t("fee"),
        dataIndex: "active_fee",
        key: "active_fee",
        render: (active_fee) => {
          return (
            active_fee && (
              <>
                <div style={{ fontWeight: "bold", textTransform: "capitalize" }}>{active_fee.type}</div>
                {active_fee.feeTiers?.map((tier, idx) => {
                  const displayFeeValue =
                    tier.feeType === FEE_TYPE.PERCENTAGE ? `${tier.feeValue * 100}%` : `RM ${tier.feeValue} `;

                  // The maxTierAmount for this tier is the next tier's minTierAmount - 0.01. We don't show maxTierAmount for the last tier.
                  const maxTierAmount =
                    idx <= active_fee.feeTiers.length - 2 ? active_fee.feeTiers[idx + 1].minTierAmount - 0.01 : "";
                  return (
                    <Row key={idx}>
                      <Col flex={1}>
                        {idx === active_fee.feeTiers.length - 1
                          ? `From RM ${tier.minTierAmount}`
                          : `RM ${tier.minTierAmount}  - RM ${maxTierAmount}:`}
                      </Col>
                      <Col flex={1} style={{ textAlign: "end" }}>
                        {displayFeeValue}
                      </Col>
                    </Row>
                  );
                })}
              </>
            )
          );
        },
      },
      {
        title: t("active"),
        dataIndex: "is_active",
        key: "is_active",
        width: "80px",
        align: "center",
        render: (is_active = true) => (is_active ? <CheckOutlined /> : <CloseOutlined style={{ color: "#8b0000" }} />),
      },
      {
        title: t("action"),
        key: "action",
        width: "210px",
        align: "center",
        resizable: false,
        render: (rowData) => {
          const { is_active = true } = rowData;
          if (!is_active) return null;

          const isLoadingSendMail = itemsLoadingSendEmail.includes(rowData.id);
          return (
            <>
              <Button
                loading={isLoadingSendMail}
                disabled={isLoadingSendMail}
                onClick={(e) => {
                  e.stopPropagation();
                  onClickSendInvitationEmail(rowData);
                }}
              >
                {t("sendInvitationEmail")}
              </Button>
              <Button
                style={{ marginTop: 10, borderColor: "#3DED97" }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Link to={`stores/${rowData.id}/fee-list`}>{t("manageFees")}</Link>
              </Button>
            </>
          );
        },
      },
    ],
    [onClickSendInvitationEmail, itemsLoadingSendEmail, t]
  );
};

export default useStoreColumns;
