import React, { useMemo, useState } from "react";
import { Button, Space, notification } from "antd";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import classnames from "classnames";
import { CloseOutlined, DownCircleOutlined } from "@ant-design/icons";

import Modal from "components/antdCustom/Modal";
import ButtonExport from "components/ButtonExport";
import ModalChangeStatus from "./ModalChangeStatus";
import ModalEditBankInfo from "./ModalEditBankInfo";
import getColumnsTranslated from "./columns";
import { validateData, exportColumns } from "./exportBulkPayment";
import TableTransactionStyled from "./styled";
import exportFile from "utils/exportHandler";
import TableResizable from "components/antdCustom/TableResizable";

const getRowKey = (record) => record.id;
const pageSizeOptions = [10, 20, 50];

const TableTransactions = (props) => {
  const {
    dataSource,
    loading,
    onChange,
    pagination,
    onUpdateTransactionTransfer,
    onUpdateTransactionBankInfo,
    onSelectItem,
    selectedItems = [],
  } = props;
  const { t } = useTranslation();
  // record of transaction to change status
  const [editStatusTransaction, setEditStatusTransaction] = useState(false);
  const [recordTransaction, setRecordTransaction] = useState(false);

  const columns = useMemo(() => getColumnsTranslated(t, setEditStatusTransaction, setRecordTransaction), [t]);
  const onCancel = () => {
    setEditStatusTransaction(false);
  };
  const onOk = (id, status, failedDescription) => {
    onUpdateTransactionTransfer(id, status, failedDescription);
    setEditStatusTransaction(false);
  };

  const onCompleteEditBankInfo = (updatedTransaction) => {
    if (updatedTransaction) {
      onUpdateTransactionBankInfo(updatedTransaction);
    }
    setRecordTransaction(false);
  };

  const onExportFile = (data, columns, fileName, fileType) => {
    exportFile(data, columns, fileName, fileType);
    notification.success({
      message: t("exportingFile"),
      placement: "bottomLeft",
      icon: <DownCircleOutlined style={{ color: "#52c41a" }} />,
    });
  };

  const selectedRowKeys = useMemo(() => {
    return selectedItems && selectedItems.map(getRowKey);
  }, [selectedItems]);

  const onClickExportBulkPayment = (fileType) => () => {
    const errors = validateData(selectedItems, t);
    if (isEmpty(errors)) {
      onExportFile(selectedItems, exportColumns, "transactionsForBulkPayment", fileType);
    } else {
      Modal.confirm({
        title: t("objExportError.title"),
        content: (
          <div>
            <ul>
              {errors.map((err, index) => (
                <li key={index}>{err}</li>
              ))}
            </ul>
            <div>{t("wantToContinue")}</div>
          </div>
        ),
        onOk: () => {
          onExportFile(selectedItems, exportColumns, "transactionsForBulkPayment", fileType);
        },
      });
    }
  };
  return (
    <TableTransactionStyled>
      {selectedItems.length > 0 && (
        <Space className="action-bar">
          <ButtonExport onExportCSV={onClickExportBulkPayment("csv")} onExportExcel={onClickExportBulkPayment("xlsx")}>
            {t("exportBulkPayment")} ({selectedItems.length})
          </ButtonExport>
          <ButtonExport
            onExportCSV={() => {
              onExportFile(selectedItems, columns, "transaction", "csv");
            }}
            onExportExcel={() => {
              onExportFile(selectedItems, columns, "transaction", "xlsx");
            }}
          >
            {t("export")} ({selectedItems.length})
          </ButtonExport>

          <Button onClick={() => onSelectItem()} icon={<CloseOutlined />} type="primary" size="large" danger />
        </Space>
      )}
      <TableResizable
        rowKey={getRowKey}
        rowSelection={{
          onChange: onSelectItem,
          selectedRowKeys,
          preserveSelectedRowKeys: true,
        }}
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        onChange={onChange}
        pagination={{ ...pagination, pageSizeOptions }}
        scroll={{ x: 1800, y: "calc(100vh - 350px)" }}
        rowClassName={(record) =>
          classnames("transaction-status", {
            "waiting-transfer": get(record, "status") === "WAITING_FOR_TRANSFER",
            "edited-by-admin": get(record, "recent_edit_admin_name"),

            done: get(record, "status") === "DONE",
          })
        }
        size="small"
      />
      <ModalChangeStatus
        visible={!!editStatusTransaction}
        transaction={editStatusTransaction}
        onOk={onOk}
        onCancel={onCancel}
      />
      <ModalEditBankInfo
        transaction={recordTransaction}
        visible={!!recordTransaction}
        onCompleteEdit={onCompleteEditBankInfo}
      />
    </TableTransactionStyled>
  );
};

export default TableTransactions;
