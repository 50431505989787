import React, { useState, useEffect, useCallback } from "react";
import { Layout } from "antd";
import get from "lodash/get";

import FilterBar from "./FilterBar";
import TableTransactions from "./TableTransactions";
import { apiGetTransaction, apiSetTransactionStatus } from "APIService/transactionService";
import useAPILoading from "hooks/useAPILoading";
import getPaginationParams from "utils/getPaginationParams";
import styled from "styled-components";
import TabTransactionStatus from "./TabTransactionStatus";

const { Content } = Layout;

const Container = styled(Content)`
  padding: 20px;
  .group {
    background: #fff;
    padding: 24px 20px;
  }
`;

const PageTransactionsHistory = (props) => {
  const [getTransaction, loading] = useAPILoading(apiGetTransaction);
  const [apiUpdateTransactionStaus, loadingUpdateTransaction] = useAPILoading(apiSetTransactionStatus);

  const [resData, setResData] = useState();
  const [params, setParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    filter: {
      transactionStatus: "WAITING_FOR_TRANSFER",
    },
    sort: {},
  });
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const fetchTransaction = useCallback(() => {
    getTransaction(getPaginationParams(params)).then(({ data }) => {
      setResData(data);
    });
  }, [getTransaction, params]);

  useEffect(() => {
    fetchTransaction();
  }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTableChange = (pagination, filters, sort) => {
    setParams({ ...params, pagination, sort });
  };

  const handleFilterChange = (newfilter) => {
    let { pagination, filter } = params;
    //set page to 1 when change filter
    pagination = { ...pagination, current: 1 };
    if (newfilter.store_name) {
      newfilter.store_name = newfilter.store_name.split(",")[1];
    }

    filter = { ...filter, ...newfilter };
    //reset selected item when change filter
    setSelectedItems([]);
    setParams({ ...params, filter, pagination });
  };

  const { filter, pagination } = params;

  const onUpdateTransactionTransfer = (id, newStatus, failedDescription) => {
    apiUpdateTransactionStaus(id, newStatus, failedDescription).then(() => {
      updateTableStatus(id, newStatus, failedDescription);
    });
  };

  const onUpdateTransactionBankInfo = (updatedTransaction) => {
    if (!updatedTransaction) return;
    const updateTransactionBankInfo = (record) => {
      if (record.id === updatedTransaction.id) {
        return { ...updatedTransaction };
      }
      return record;
    };
    const { data, total } = resData;
    const newData = data.map(updateTransactionBankInfo);
    setResData({ data: newData, total });
    //update selected item when update dataSource
    setSelectedItems((items) => items && items.map(updateTransactionBankInfo));
  };

  const updateTableStatus = (id, newStatus, failedDescription) => {
    const updateTransactionStatus = (record) => {
      if (record.id === id) {
        return { ...record, status: newStatus, failedDescription };
      }
      return record;
    };
    const { data, total } = resData;
    const newData = data.map(updateTransactionStatus);
    setResData({ data: newData, total });

    //update selected item when update dataSource
    setSelectedItems((items) => items && items.map(updateTransactionStatus));
  };

  const onSelectItem = (selectedRowKeys, selectedItems = []) => {
    setSelectedKeys(selectedRowKeys);
    setSelectedItems(selectedItems);
  };

  const onChangeTab = (status) => {
    let { pagination, filter } = params;
    filter = { transactionStatus: status };
    pagination = { ...pagination, current: 1 };
    setParams({ ...params, filter, pagination });
    setSelectedKeys([]);
    setSelectedItems([])
  };

  return (
    <Container>
      <TabTransactionStatus
        status={filter.transactionStatus}
        onChange={onChangeTab}
        selectedKeys={selectedKeys}
        fetchTransaction={fetchTransaction}
        setSelectedKeys={setSelectedKeys}
        setSelectedItems={setSelectedItems}
      />
      <div className="group">
        <FilterBar value={filter} onSubmit={handleFilterChange} loading={loading || loadingUpdateTransaction} />
        <TableTransactions
          dataSource={get(resData, "data")}
          pagination={{ ...pagination, total: get(resData, "total") }}
          loading={loading || loadingUpdateTransaction}
          onChange={handleTableChange}
          selectedItems={selectedItems}
          onSelectItem={onSelectItem}
          onUpdateTransactionTransfer={onUpdateTransactionTransfer}
          onUpdateTransactionBankInfo={onUpdateTransactionBankInfo}
        />
      </div>
    </Container>
  );
};

export default PageTransactionsHistory;
