import React, { useEffect, useState } from "react";
import OrderListingStyled from "./styled";
import { Tabs } from "antd";
import AllOrdersList from "./AllOrder";
import FilteredOrdersList from "./OrderStatus";
import useAPILoading from "hooks/useAPILoading";
import { fetchOrders } from "src/APIService/orders";
import { fetchStores } from "src/APIService/store";

const { TabPane } = Tabs;

const OrderListing = () => {
  const [orders, setOrders] = useState();
  const [getOrders, loading] = useAPILoading(fetchOrders);
  const [getStore] = useAPILoading(fetchStores);
  const [storeList, setStoreList] = useState();

  useEffect(() => {
    allOrders();
    allStores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const params = {
    page: 1,
    pageSize: 10,
  };

  const allOrders = () => {
    getOrders(params).then(({ data }) => setOrders(data));
  };

  const allStores = () => {
    getStore()
      .then(({ data }) => {
        const { data: storeListData } = data;
        if (storeListData) {
          const mappedStoreList = storeListData.map(({ store_name, id }) => ({
            value: id,
            label: store_name,
          }));
          setStoreList(mappedStoreList);
        }
      })
      .catch((error) => console.error("Store API Error:", error));
  };

  return (
    <OrderListingStyled>
      <div className="new-order-button-container">
        <Tabs defaultActiveKey="1">
          <TabPane tab={`All orders (${orders?.total || 0})`} key="1">
            <AllOrdersList loading={loading} orders={allOrders} storeList={storeList} />
          </TabPane>
          <TabPane tab={`Received (${orders?.totalReceived || 0})`} key="2">
            <FilteredOrdersList loading={loading} category="received" storeList={storeList} />
          </TabPane>
          <TabPane tab={`Ready for collection (${orders?.totalCollection || 0})`} key="3">
            <FilteredOrdersList loading={loading} category="ready_for_collection" storeList={storeList} />
          </TabPane>
          <TabPane tab={`Completed (${orders?.totalCompleted || 0})`} key="4">
            <FilteredOrdersList loading={loading} category="completed" storeList={storeList} />
          </TabPane>
        </Tabs>
      </div>
    </OrderListingStyled>
  );
};

export default OrderListing;
