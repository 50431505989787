import axios from "./axios";
import { API_ID, API_KEY, GENERATE_WITHDRAWAL_TOKEN, PRTNRID, GET_BANKS, GET_LOGS } from "./APILinks";

export const apiGetBanks = () => axios.get(GET_BANKS);

/**
 * @param {object} params
 * @param {object} params.filter
 * @param {number} params.page
 * @param {number} params.pageSize
 * @returns {Promise<[{
 *  action: string,
 *  actor: number,
 *  actor_role: string,
 *  api: string,
 *  created_at: string,
 *  id: number,
 *  message: string,
 *  req_body: string,
 *  status_code: number,
 * }]>}
 */
export const getConversionLogs = (params) =>
  axios.get(GET_LOGS, {
    params,
  });

export const apiCreateKocekOutToken = () => {
  return axios.post(
    GENERATE_WITHDRAWAL_TOKEN,
    {
      apiId: API_ID,
      apiKey: API_KEY,
    },
    {
      headers: {
        prtnrId: PRTNRID,
      },
    }
  );
};
