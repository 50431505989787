import { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu } from "antd";
import { LogoutOutlined, TranslationOutlined, UserOutlined } from "@ant-design/icons";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { VERSION } from "variables";
import PopupLogout from "./PopupLogout";
import { LANGUAGES } from "i18n/configs";
import LayoutStyled from "./styled";
import { kocekWhite, leftIcon } from "images";
import { mapLanguageToLocale } from "setting/moment";
const { Header, Sider } = Layout;
const { SubMenu } = Menu;

export default function PageLayout(props) {
  const { routes } = props;
  const { t, i18n } = useTranslation();
  const [openModalLogout, setOpenModalLogout] = useState(false);
  const [isSiderCollapsed, setIsSiderCollapsed] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [openKeys, setOpenKeys] = useState(["sub1", "sub2", "sub3"]);

  const handleSiderCollapse = (collapsed) => {
    setIsSiderCollapsed(collapsed);
    if (collapsed) {
      setOpenKeys([]); // Close all submenus when the sider is collapsed
    } else {
      setOpenKeys(["sub1", "sub2", "sub3"]); // Open the specified submenu when the sider is expanded
    }
  };

  const menuKey = isSiderCollapsed ? "collapsed" : "expanded";

  const onClickChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    moment.locale(mapLanguageToLocale[lang]);
  };

  const showModalLogout = () => {
    setOpenModalLogout(true);
  };
  const onClosePopup = () => {
    setOpenModalLogout(false);
  };

  const routesToShowWithoutCategory = useMemo(() => {
    return routes.filter((route) => {
      const { showOnSideBar = true } = route;
      return showOnSideBar && !route.category;
    });
  }, [routes]);

  const routesToShowWithCategory = useMemo(() => {
    return routes.filter((route) => {
      const { showOnSideBar = true } = route;
      return showOnSideBar && route.category;
    });
  }, [routes]);

  const routesToShow = useMemo(() => {
    return routes.filter((route) => {
      const { showOnSideBar = true } = route;
      return showOnSideBar;
    });
  }, [routes]);

  const coinStatisticsRoutes = useMemo(() => {
    return routesToShowWithCategory.filter((route) => route.category === "statistics");
  }, [routesToShowWithCategory]);

  const coinDepositRoutes = useMemo(() => {
    return routesToShowWithCategory.filter((route) => route.category === "kocek_in");
  }, [routesToShowWithCategory]);

  const coinWithdrawalRoutes = useMemo(() => {
    return routesToShowWithCategory.filter((route) => route.category === "kocek_out");
  }, [routesToShowWithCategory]);

  const coinGeneralRoutes = useMemo(() => {
    return routesToShowWithCategory.filter((route) => route.category === "general");
  }, [routesToShowWithCategory]);

  const selectedMenuItem = useMemo(() => {
    const selected = routesToShow.find((route) => route.path.includes(location.pathname));
    return [selected?.path + ""];
  }, [location.pathname, routesToShow]);

  const selectedPath = useMemo(() => {
    return routes.find((path) => {
      const pathSegments = path.path.split("/");
      const lastSegment = pathSegments.pop();
      if (lastSegment === ":id") {
        // Extract the base path without the :id part
        const basePath = pathSegments.join("/");
        // Check if the location.pathname starts with the basePath
        if (location.pathname.startsWith(basePath)) {
          return true;
        }
      }
      return path.path === location.pathname;
    });
  }, [location.pathname, routes]);

  const headerPath = () => {
    if (selectedPath?.name === "order_details") {
      return `#${location.pathname.split("/").pop()}`;
    }

    return t(selectedPath?.name);
  };

  return (
    <LayoutStyled>
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          className="ant-sider"
          breakpoint="md"
          collapsedWidth="0"
          collapsed={isSiderCollapsed}
          onCollapse={handleSiderCollapse}
        >
          <div className="logo-area">
            <img src={kocekWhite} alt="" className="store-logo" />
          </div>
          <Menu
            key={menuKey}
            theme="dark"
            mode="inline"
            openKeys={openKeys}
            selectedKeys={selectedMenuItem}
            inlineCollapsed={isSiderCollapsed}
          >
            {/* route with no categroy */}
            {routesToShowWithoutCategory?.length > 0 ? (
              <>
                {routesToShowWithoutCategory.map((routeItem, index) => (
                  <Menu.Item key={routeItem.path} icon={routeItem.icon}>
                    <Link to={routeItem.path}>{t(routeItem.name)}</Link>
                  </Menu.Item>
                ))}
              </>
            ) : null}

            {/* Route with categroy */}
            {coinStatisticsRoutes?.length > 0 ? (
              <SubMenu className="submenu" key="sub1" title="STATISTICS">
                {coinStatisticsRoutes.map((routeItem) =>
                    routeItem.children && routeItem.children.length > 0 ? (
                      <Menu theme="dark" mode="vertical" selectedKeys={[i18n.language]}>
                        <SubMenu title={t(routeItem.name)} icon={routeItem.icon}>
                          {routeItem.children.map((childItem) => (
                            <Menu.Item>
                              <Link to={childItem.path}>{t(childItem.name)}</Link>
                            </Menu.Item>
                          ))}
                        </SubMenu>
                      </Menu>
                    ) : (
                      <Menu.Item className="submenu-item" key={routeItem.path} icon={routeItem.icon}>
                        <Link to={routeItem.path}>{t(routeItem.name)}</Link>
                      </Menu.Item>
                    )
                  )}
              </SubMenu>
            ) : null}

            {coinDepositRoutes?.length > 0 ? (
              <SubMenu className="submenu" key="sub2" title="KOCEK IN">
                {coinDepositRoutes.map((routeItem) => (
                  <Menu.Item className="submenu-item" key={routeItem.path} icon={routeItem.icon}>
                    <Link to={routeItem.path}>{t(routeItem.name)}</Link>
                  </Menu.Item>
                ))}
              </SubMenu>
            ) : null}

            {coinWithdrawalRoutes?.length > 0 ? (
              <SubMenu className="submenu" key="sub2" title="KOCEK OUT">
                {coinWithdrawalRoutes.map((routeItem) => (
                  <Menu.Item className="submenu-item" key={routeItem.path} icon={routeItem.icon}>
                    <Link to={routeItem.path}>{t(routeItem.name)}</Link>
                  </Menu.Item>
                ))}
              </SubMenu>
            ) : null}

            {coinGeneralRoutes?.length > 0 ? (
              <SubMenu className="submenu" key="sub3" title="GENERAL">
                {coinGeneralRoutes.map((routeItem) => (
                  <Menu.Item className="submenu-item" key={routeItem.path} icon={routeItem.icon}>
                    <Link to={routeItem.path}>{t(routeItem.name)}</Link>
                  </Menu.Item>
                ))}
              </SubMenu>
            ) : null}
          </Menu>

          <Menu theme="dark" mode="vertical" className="menu-fixed-bottom" selectedKeys={[i18n.language]}>
            <SubMenu title={t("account")} icon={<UserOutlined />}>
              <Menu.Item>
                <Link to="/account/change-password">{t("changePassword")}</Link>
              </Menu.Item>
            </SubMenu>

            <SubMenu title={t("language")} icon={<TranslationOutlined />}>
              {LANGUAGES.map((langItem) => (
                <Menu.Item key={langItem.value} onClick={() => onClickChangeLanguage(langItem.value)}>
                  {langItem.label}
                </Menu.Item>
              ))}
            </SubMenu>
            <Menu.Item onClick={showModalLogout} icon={<LogoutOutlined />}>
              {t("logOut.label")}
            </Menu.Item>
          </Menu>
        </Sider>
        <PopupLogout visible={openModalLogout} onClose={onClosePopup} />

        <Layout className="site-layout">
          {/* Header */}
          <Header className="site-header">
            <div className="path-name">
              {selectedPath?.backIcon ? (
                <div onClick={history.goBack} className="back-icon-container">
                  <img className="back-icon" src={leftIcon} alt="back-icon" />
                </div>
              ) : null}
              <p>{headerPath()}</p>
            </div>
          </Header>
          {props.children}{" "}
          <div className="footer-text">
            &copy; Dapat Vista Sdn Bhd. All rights reserved&nbsp;&nbsp;
            <span>&bull;&nbsp;&nbsp;Version {VERSION}</span>
          </div>
        </Layout>
      </Layout>
    </LayoutStyled>
  );
}
