import { Form, Input, Row, Col, DatePicker, Select, Button } from "antd";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobileAlt, faStore } from "@fortawesome/free-solid-svg-icons";
import { ReloadOutlined, SearchOutlined } from "@ant-design/icons";

const FilterBar = (props) => {
  const { t } = useTranslation();
  const { loading, storeList } = props;
  const orderPlatform = [
    {
      label: "All",
      value: "",
    },
    {
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ width: 15, textAlign: "center", marginRight: 10 }}>
            <FontAwesomeIcon icon={faStore} color="#909090" />
          </div>
          <p style={{ marginBottom: 0 }}>Kiosk</p>
        </div>
      ),
      value: "Kiosk",
    },
    {
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ width: 15, textAlign: "center", marginRight: 10 }}>
            <FontAwesomeIcon icon={faMobileAlt} color="#909090" />
          </div>
          <p style={{ marginBottom: 0 }}>Online</p>
        </div>
      ),
      value: "Online",
    },
  ];
  return (
    <Form layout="horizontal" colon={false} onFinish={props.onChange}>
      <Row gutter={8}>
        <Col className="gutter-row" xs={12} md={8} lg={6} xxl={4}>
          <Form.Item name="order_id_or_client_name" label="Search" labelCol={{ span: 24 }}>
            <Input placeholder={"Customer name or order ID"} allowClear />
          </Form.Item>
        </Col>
        <Col className="gutter-row" xs={12} md={8} lg={6} xxl={4}>
          <Form.Item name="date" label="Date" labelCol={{ span: 24 }}>
            <DatePicker inputReadOnly={true} style={{ width: "100%" }} allowClear placeholder="All" />
          </Form.Item>
        </Col>
        <Col className="gutter-row" xs={12} md={8} lg={6} xxl={4}>
          <Form.Item name="merchants" label="Location" labelCol={{ span: 24 }}>
            <Select
              mode="multiple"
              options={storeList}
              filterOption={(input, option) => (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())}
              placeholder={"All"}
            />
          </Form.Item>
        </Col>
        <Col className="gutter-row" xs={12} md={8} lg={6} xxl={4}>
          <Form.Item name="order_type" label="Order via" labelCol={{ span: 24 }}>
            <Select defaultValue={"All"} options={orderPlatform} filterOption={false} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8} style={{ marginBottom: 25 }}>
        <Col className="gutter-row" xs={12} md={8} lg={6} xxl={4}>
          <Form.Item>
            <Button block type="primary" htmlType="submit" loading={loading} icon={<SearchOutlined />}>
              {t("search")}
            </Button>
          </Form.Item>
        </Col>
        <Col className="gutter-row" xs={12} md={8} lg={6} xxl={4}>
          <Form.Item noStyle shouldUpdate>
            {(form) => {
              return (
                <Button
                  block
                  onClick={() => {
                    form.resetFields();
                    props.onChange();
                  }}
                  icon={<ReloadOutlined />}
                >
                  {t("resetFilter")}
                </Button>
              );
            }}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default FilterBar;
