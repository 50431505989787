import { Row, Radio, InputNumber, Button } from "antd";
import { formatCurrency } from "src/utils";
import { PlusOutlined } from "@ant-design/icons";

import styled from "styled-components";
import { FEE_TYPE } from "src/variables";
import { useTranslation } from "react-i18next";

const TierItemStyled = styled.div`
  padding: 20px;
  border: 1px solid black;
  width: fit-content;

  p {
    margin: 0;
  }

  .input-row {
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    gap: 10px;
  }

  .input-field {
    min-width: 250px;
  }
`;

const TiersInputContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
/**
 * @param {object} props
 * @param {Array<{feeType: 'fixed' | 'percentage', minTierAmount: Number, feeValue: Number}>} props.value
 * @param {(newState: Array<{feeType: 'fixed' | 'percentage', minTierAmount: Number, feeValue: Number}>)} props.onChange
 */
const TiersInput = ({ value: feeTiers = [], onChange: onChangeFeeTiers }) => {
  const { t } = useTranslation();
  const onChangeFeeType = (type, index) => {
    // If the type changed from percentage to fixed, we should multiply value by 100. Example: 10% = 0.1, but we want it to be 10 RM so 0.1 * 100 = 10.
    // If the type changed from fixed to percentage, we should divide value by 100. Example: 0.5 = 50%, but we want it to be 0.5% so 0.5 / 100 = 0.005.
    if (type === "fixed") feeTiers[index].feeValue *= 100;
    else feeTiers[index].feeValue /= 100;

    feeTiers[index].feeType = type;
    feeTiers[index].error = null;
    onChangeFeeTiers([...feeTiers]);
  };

  const onChangeFeeValue = (value, index) => {
    feeTiers[index].feeValue = value;
    feeTiers[index].error = null;
    onChangeFeeTiers([...feeTiers]);
  };

  const onChangeMinTierAmount = (value, index) => {
    feeTiers[index].minTierAmount = value;
    feeTiers[index].error = null;
    onChangeFeeTiers([...feeTiers]);
  };

  const onRemoveTier = (index) => {
    const tiers = [...feeTiers];
    tiers.splice(index, 1);
    onChangeFeeTiers([...tiers]);
  };

  const onAddTier = () => {
    const tiers = [...feeTiers];
    tiers.push({
      feeType: "fixed",
      feeValue: 0,
      minTierAmount: 0,
    });
    onChangeFeeTiers([...tiers]);
  };

  return (
    <TiersInputContainer>
      {feeTiers.map(({ feeType, feeValue, minTierAmount, error }, index) => (
        <div key={index} style={{ marginBottom: "20px" }}>
          <TierItem
            feeType={feeType}
            feeValue={feeValue}
            minTierAmount={minTierAmount}
            onChangeFeeType={(e) => onChangeFeeType(e, index)}
            onChangeFeeValue={(e) => onChangeFeeValue(e, index)}
            onChangeMinTierAmount={(e) => onChangeMinTierAmount(e, index)}
            onRemoveTier={() => onRemoveTier(index)}
            error={error}
          />
        </div>
      ))}
      <Button type="outline" icon={<PlusOutlined />} onClick={onAddTier}>
        {t("addNewTier")}
      </Button>
    </TiersInputContainer>
  );
};

const TierItem = ({
  feeType,
  feeValue,
  minTierAmount,
  onChangeFeeType,
  onChangeFeeValue,
  onChangeMinTierAmount,
  onRemoveTier,
  error,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <TierItemStyled style={{ borderColor: !!error ? "red" : "black" }}>
        <Row className="input-row">
          <p>{t("type")}</p>
          <Radio.Group className="input-field" onChange={(e) => onChangeFeeType(e.target.value)} value={feeType}>
            <Radio value={FEE_TYPE.FIXED}>{t("fixedAmount")}</Radio>
            <Radio value={FEE_TYPE.PERCENTAGE}>{t("percentage")}</Radio>
          </Radio.Group>
        </Row>
        <Row className="input-row">
          <p>{t("feeValue")}</p>
          {feeType === FEE_TYPE.FIXED ? (
            <InputNumber
              className="input-field"
              addonBefore="RM"
              formatter={formatCurrency}
              onChange={onChangeFeeValue}
              value={feeValue}
              min={0}
              name="feeValue"
            />
          ) : (
            <InputNumber
              className="input-field"
              addonBefore="%"
              onChange={onChangeFeeValue}
              parser={(value) => value / 100}
              // toFixed(4) to fix bug: Input 3.5 and 3.50000000000004 is displayed, input 56 and 55.9999999999 is displayed
              // parseFloat to remove insignificant trailing zeroes. If we don't use parseFloat, it'll show 3.5000 instead of 3.5
              formatter={(value) => parseFloat((value * 100).toFixed(4)).toString()}
              value={feeValue}
              min={0}
              max={1}
              name="feeValue"
            />
          )}
        </Row>
        <Row className="input-row">
          <p>{t("minRequiredCoinsToConvert")}</p>
          <InputNumber
            className="input-field"
            addonBefore="RM"
            formatter={formatCurrency}
            onChange={onChangeMinTierAmount}
            value={minTierAmount}
            min={0}
            name="amount"
          />
        </Row>
        <Button type="outline" danger onClick={onRemoveTier}>
          {t("delete")}
        </Button>
      </TierItemStyled>
      <p style={{ color: "red" }}>{error}</p>
    </>
  );
};

export default TiersInput;
