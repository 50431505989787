import { Form, Input, Button, Space } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";

const layout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 10,
  },
};

const tailLayout = {
  wrapperCol: {
    xs: { offset: 0 },
    md: { offset: 4 },
  },
};

const FilterBar = (props) => {
  const { initialValues, onFinish } = props;
  const { t } = useTranslation();
  const { loading } = props;
  return (
    <Form layout="horizontal" initialValues={initialValues} onFinish={onFinish} {...layout}>
      <Form.Item label={t("storeName")} name="store_name">
        <Input allowClear />
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Space align="start">
          <Button
            htmlType="submit"
            style={{ width: "100%", borderColor: "#1890ff", borderWidth: 2 }}
            loading={loading}
            icon={<SearchOutlined />}
          >
            {t("search")}
          </Button>
          <Link to="/stores/create">
            <Button type="primary" icon={<PlusOutlined />}>
              {t("createStore")}
            </Button>
          </Link>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default FilterBar;
