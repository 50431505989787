import { Button, Form, Input, notification, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import get from "lodash/get";

import { editFee, getFee } from "src/APIService/fees";
import TiersInputContainer from "src/components/TiersInput";
import useAPI from "src/hooks/useAPILoading";

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  @media only screen and (min-width: 600px) {
    padding: 20px 40px;
  }

  @media only screen and (min-width: 768px) {
    padding: 30px 60px;
  }

  @media only screen and (min-width: 992px) {
    padding: 50px 80px;
  }

  @media only screen and (min-width: 1200px) {
    padding: 50px 120px;
    max-width: 1440px;
  }
  .form-content {
    max-width: 645px;
    flex: 1;
    width: 100%;
  }
  .ant-form {
    flex: 1;
    width: 100%;
  }
  .toolbar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    @media only screen and (min-width: 600px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }
`;

export default function EditGlobalFee(props) {
  const id = get(props, "match.params.id");

  const [form] = Form.useForm();
  const [fee, setFee] = useState();

  const { t } = useTranslation();
  const history = useHistory();

  const [handleFinish, loading] = useAPI(async (values) => {
    try {
      await editFee(id, {
        ...values,
        merchantId: null,
        updatedAt: fee.updatedAt,
      });
      notification.success({ message: t("updateSuccess") });
      history.push("/global-fee");
    } catch (error) {
      const { response } = error;
      if (response) {
        const { status, data } = response;

        const message = data?.message || "SOMETHING_WENT_WRONG";

        if (status === 404) {
          // Fee is not found. Back to the list
          history.replace("/global-fee");
        }

        if (status === 400) {
          // With status is 400, the message is meaningful
          if (data) {
            const { tiers } = data;
            if (tiers) form.setFieldValue("tiers", tiers);
          }
          notification.error({ message });
        } else {
          notification.error({ message: t(`error:${message}`) });
        }
      } else {
        notification.error({ message: t("error:SOMETHING_WENT_WRONG") });
      }
    }
  });

  const [handleGetFee, getFeeLoading] = useAPI(async () => {
    try {
      const { data } = await getFee(id);
      if (data) setFee(data);
    } catch {
      notification.error({ message: t("error:SOMETHING_WENT_WRONG") });
      history.replace("/global-fee");
    }
  });

  useEffect(() => {
    handleGetFee();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Spin spinning={loading || getFeeLoading || !fee}>
      <Container>
        {!!fee && (
          <Form form={form} layout="horizontal" initialValues={fee} onFinish={handleFinish} className="ant-form">
            <Form.Item>
              <div className="toolbar">
                <h2>{t("editGlobalFee")}</h2>
                <div style={{ marginLeft: -10, marginRight: -10 }}>
                  <Button style={{ marginLeft: 10, marginRight: 10 }}>
                    <Link to="/global-fee">{t("cancel")}</Link>
                  </Button>
                  <Button style={{ marginLeft: 10, marginRight: 10 }} type="primary" htmlType="submit">
                    {t("save")}
                  </Button>
                </div>
              </div>
            </Form.Item>
            <div className="form-content">
              <Form.Item name="name" label={t("name")} style={{ width: 300 }} rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item name="tiers" label={t("tiers")} rules={[{ required: true }]}>
                <TiersInputContainer />
              </Form.Item>
            </div>
          </Form>
        )}
      </Container>
    </Spin>
  );
}
