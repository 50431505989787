import axios from "./axios";
import { ADMIN_URL, MERCHANT_URL, SEND_EMAIL_INVITATION_ADMIN } from "./APILinks";

export const fetchAdmins = (params) => {
  return axios.get(ADMIN_URL, { params });
};

export const createAdmin = (admin) => {
  return axios.post(ADMIN_URL, admin);
};

export const createMerchant = (merchant) => {
  return axios.post(MERCHANT_URL, merchant);
};
/**
 * @param {string} id
 * @returns {Promise<{
 *  id: number,
 *  fullName: string,
 *  isActive: boolean,
 *  email: string,
 *  role: "admin" ||"super_admin"
 * }>}
 */
export const fetchAdminById = (id) => {
  return axios.get(ADMIN_URL + "/" + id).then((response) => ({
    ...response,
    data: formatDataAdminAccount(response.data),
  }));
};

export const updateAdmin = (salt, email, admin) => {
  return axios.put(ADMIN_URL + "/" + salt + "/" + email, admin);
};

export const updateMerchant = (email, id, merchant) => {
  return axios.put(MERCHANT_URL + "/" + email + "/" + id, merchant);
};

export const sendInvitationToAdmin = (email) => axios.post(SEND_EMAIL_INVITATION_ADMIN, { email });

const formatDataAdminAccount = (data = {}) => {
  const { id, fullName, full_name, isActive, is_active, email, role = "admin", ...others } = data;

  return {
    id,
    fullName: fullName || full_name,
    isActive: !!(isActive || is_active),
    email,
    role,
    ...others,
  };
};
