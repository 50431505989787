import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Form, Button, Row, Col } from "antd";
import get from "lodash/get";
import { useTranslation } from "react-i18next";

import InputTime from "components/InputTime";

const layout = {
  labelCol: {
    xs: { span: 24, offset: 2 },
    sm: { span: 8, offset: 2 },
  },
  wrapperCol: {
    xs: { span: 24, offset: 2 },
    sm: { span: 16 },
  },
};

const styleFormItem = { marginBottom: 0 };

const ShiftSetting = (props) => {
  const { value, onChange, disabled, onRemove } = props;
  const { t } = useTranslation();

  const onChangeFieldValue = (fieldName) => (fieldValue) => {
    onChange({ ...value, [fieldName]: fieldValue });
  };
  const getFieldValue = (fieldName) => get(value, fieldName);

  return (
    <Row wrap={false} align="bottom">
      <Col flex={1}>
        <Form.Item {...layout} style={styleFormItem} label={t("startTime")}>
          <InputTime
            value={getFieldValue("startTime")}
            onChange={onChangeFieldValue("startTime")}
            disabled={disabled}
          />
        </Form.Item>
      </Col>
      <Col flex={1}>
        <Form.Item {...layout} style={styleFormItem} label={t("endTime")}>
          <InputTime value={getFieldValue("endTime")} onChange={onChangeFieldValue("endTime")} disabled={disabled} />
        </Form.Item>
      </Col>
      <Form.Item {...layout} style={{ margin: 0 }}>
        <Button
          danger
          size="small"
          shape="circle"
          className="remove-btn"
          icon={<CloseOutlined />}
          onClick={onRemove}
          disabled={disabled}
        ></Button>
      </Form.Item>
    </Row>
  );
};

export default ShiftSetting;
