import React, { useCallback, useState } from "react";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import get from "lodash/get";

import Modal from "components/antdCustom/Modal";
import TableResizable from "components/antdCustom/TableResizable";
import { sendInvitationToStore } from "APIService/store";
import useStoreColumns from "./useStoreColumns";
import errorNotification from "components/antdCustom/notification/errorNotification.js";

const TableStoreStyled = styled.div`
  .ant-table-tbody > tr.ant-table-row {
    &.inactive {
      background: #f3f3f3;
      color: #9d9d9d;

      &:hover > td {
        background: #efefef;
      }
    }

    &.store-row {
      cursor: pointer;
    }
  }
`;

const TableStores = (props) => {
  const { dataSource, loading, onChange, pagination } = props;
  const [itemsLoadingSendEmail, setItemsLoadingSendEmail] = useState([]);
  const history = useHistory();
  const { t } = useTranslation();

  const onSendEmail = useCallback(
    (rowData) => {
      if (itemsLoadingSendEmail.includes(rowData.id)) return;

      setItemsLoadingSendEmail((itemLoadings) => [...itemLoadings, rowData.id]);
      sendInvitationToStore(rowData.email)
        .then(() => {
          notification.success({
            message: t("objSendInvitation.success"),
            placement: "bottomRight",
          });
        })
        .catch((err) => {
          errorNotification({
            message: (
              <span>
                {t("objSendInvitation.error1")} <b>{rowData.email}</b>
              </span>
            ),
            placement: "bottomRight",
            okText: t("objSendInvitation.btnReSend"),
            onOK: () => {
              onSendEmail(rowData);
            },
          });
        })
        .finally(() => {
          //remove loading from array

          setItemsLoadingSendEmail((itemLoadings) => itemLoadings.filter((item) => item !== rowData.id));
        });
    },
    [itemsLoadingSendEmail, t]
  );

  const onClickSendInvitationEmail = useCallback(
    (rowData) => {
      Modal.confirm({
        title: t("objSendInvitation.title"),
        content: t("objSendInvitation.content"),
        onOk: () => onSendEmail(rowData),
      });
    },
    [onSendEmail, t]
  );

  const columns = useStoreColumns(onClickSendInvitationEmail, itemsLoadingSendEmail);
  return (
    <TableStoreStyled>
      <TableResizable
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        onChange={onChange}
        pagination={pagination}
        scroll={{ y: "calc(100vh - 300px)" }}
        rowClassName={(store) => {
          const is_active = get(store, "is_active", true);
          if (is_active) return "store-row";
          return "store-row inactive";
        }}
        onRow={(record) => {
          return {
            onClick: () => {
              history.push("/stores/" + record.id);
            },
          };
        }}
      />
    </TableStoreStyled>
  );
};

export default TableStores;
