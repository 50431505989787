import { notification, Layout } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory, Link } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { LeftSquareOutlined } from "@ant-design/icons";
import useAPILoading from "hooks/useAPILoading";
import { createAdmin, createMerchant, sendInvitationToAdmin } from "APIService/admin";
import { sendInvitationToStore } from "APIService/store";
import FormAdminDetail from "./FormAdminDetail";
import { generateRandomString } from "utils";
import stickyNotification from "components/antdCustom/notification/stickyNotification.js";
import styled from "styled-components";

const { Content } = Layout;

const Header = styled(Content)`
  background: #fff;
  width: 100%;
  height: 7vh;
  font-size: 22px;
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 1;
`;

const CreateAdmin = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [callCreateAdmin, loadingAdmin] = useAPILoading(createAdmin);
  const [callCreateMerchant, loadingMerchant] = useAPILoading(createMerchant);
  const adminDefault = {
    isActive: true,
    password: generateRandomString(8),
    role: "",
  };

  const onCreateUser = (user) => {
    const { merchant_id, full_name, email, ...others } = user;
    const name = full_name;
    const fullName = full_name;

    if (merchant_id) {
      callCreateMerchant({
        ...others,
        name,
        email,
        merchant_id,
        phone_prefix: "+60",
      }).then(() => {
        const closeNotification = stickyNotification.success({
          icon: <LoadingOutlined />,
          message: t("objSendInvitation.sendingEmail"),
          placement: "bottomRight",
          style: { background: "#ffeba8", color: "#7a6b2a" },
        });
        sendInvitationToStore(email)
          .then(() => {
            closeNotification();
            notification.success({
              message: t("objSendInvitation.success"),
              description: t("emailSentNotification")(email),
              duration: 7,
              placement: "bottomRight",
            });
          })
          .catch(() => {
            notification.error({
              message: t("objSendInvitation.sendEmailFail"),
              placement: "bottomRight",
            });
          })
          .finally(() => {
            history.goBack();
          });
      });
    } else {
      callCreateAdmin({ ...others, phonePrefix: "+60", fullName, email }).then(() => {
        const closeNotification = stickyNotification.success({
          icon: <LoadingOutlined />,
          message: t("objSendInvitation.sendingEmail"),
          placement: "bottomRight",
          style: { background: "#ffeba8", color: "#7a6b2a" },
        });
        sendInvitationToAdmin(email)
          .then(() => {
            closeNotification();
            notification.success({
              message: t("objSendInvitation.success"),
              description: t("emailSentNotification")(email),
              duration: 7,
              placement: "bottomRight",
            });
          })
          .catch(() => {
            notification.error({
              message: t("objSendInvitation.sendEmailFail"),
              placement: "bottomRight",
            });
          })
          .finally(() => {
            history.goBack();
          });
      });
    }
  };

  return (
    <div>
      <Header>
        <div style={{ padding: "10px", marginLeft: "10px" }}>
          <Link to="/admins" style={{ color: "inherit", textDecoration: "inherit" }}>
            <LeftSquareOutlined />
          </Link>
          &nbsp; Add User
        </div>
      </Header>
      <FormAdminDetail
        onSubmit={onCreateUser}
        loading={loadingAdmin ? loadingAdmin : loadingMerchant}
        value={adminDefault}
      />
    </div>
  );
};

export default CreateAdmin;
