import { URIAdmin } from "../APILinks";
import axios from "../axios";

/**
 * @param {object} params
 * @param {boolean} params.useGlobalFee
 * @param {number} params.feeId
 * @param {number} params.merchantId
 * @param {Array<{feeType: 'fixed' | 'percentage', minTierAmount: Number, feeValue: Number}>} params.tiers
 * @returns {Promise<{data: {
 *  feeId: number
 * }}>}
 */

export const setActiveFeeForMerchant = ({ merchantId, feeId, useGlobalFee }) => {
  const body = {};
  if (useGlobalFee) body.useGlobalFee = true;
  else {
    body.useGlobalFee = false;
    body.feeId = feeId;
  }
  return axios.post(`${URIAdmin}/${merchantId}/active-fee`, body);
};
