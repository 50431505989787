import React, { useMemo } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const PreviewBodyStyle = styled.div`
  max-height: 400px;
  overflow: auto;
`;

const getJsonStringFormatted = (JSONString = "") => {
  let output = JSONString;
  try {
    output = JSON.stringify(JSON.parse(output), undefined, 2);
  } catch (e) {}

  return output;
};

const ExpandedRow = ({ record }) => {
  const { t } = useTranslation();

  const JsonStringFormatted = useMemo(() => getJsonStringFormatted(record.req_body), [record.req_body]);

  return (
    <div>
      <b>{t("requestBody")}</b>
      <PreviewBodyStyle>
        <pre>{JsonStringFormatted}</pre>
      </PreviewBodyStyle>
      <div>
        <b>{t("statusCode")}</b>
      </div>
      <div>{record.status_code}</div>
    </div>
  );
};

export default ExpandedRow;
