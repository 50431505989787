import { useState, useEffect } from "react";
import useRouterQuery from "hooks/useRouterQuery";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";

import useAPILoading from "hooks/useAPILoading";
import usePrevious from "hooks/usePrevious";
import getPaginationParams from "utils/getPaginationParams";

/**
 * this hook return a value && onChange event for filter bar & table display tha value. For pages using pagination UI (include FilterBar, Table, Pagination panel)
 * @param {function} store.apiInput //input api function to be called to get data //
 * this API should support pagination: Request sharp: {page: Number, pageSize: Number, filter: Object}.
 * Response sharp: {total: Number, data: Array}.
 * @returns {Object} { filterBarProps: Object, handleFilterBarChange: function, tableProps: Object, handleTableChange: function}
 */

const usePagePaginationLogic = ({ apiInput } = {}) => {
  const [callAPI, loading] = useAPILoading(apiInput);
  const [resData, setResData] = useState();
  const [params, setParams] = useRouterQuery({
    pagination: { current: 1, pageSize: 10 },
    filter: {},
  });
  const prevParams = usePrevious(params);

  useEffect(() => {
    if (!isEqual(prevParams, params)) {
      const { pageSize, page, fromDate, toDate } = getPaginationParams(params);
      callAPI({
        pageSize,
        page,
        fromDate,
        toDate,
        transactionId: get(params, "filter.transactionId"),
        actions: get(params, "filter.actions"),
      }).then(({ data }) => {
        setResData(data);
      });
    }
  }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTableChange = (pagination = {}) => {
    const { current, pageSize } = pagination;
    setParams({ ...params, pagination: { current, pageSize } });
  };

  const handleFilterBarChange = ({ dateRange, transactionId, actions }) => {
    const sanitizedFilter = {
      dateRange,
      transactionId: transactionId !== "" ? transactionId : null, // prevent empty string
      actions,
    };
    let { pagination } = params;
    pagination = { ...pagination, current: 1 };
    //set page to 1 when change filter

    setParams({ ...params, filter: sanitizedFilter, pagination });
  };

  let initialValues = cloneDeep(params.filter);
  // dateRange parsed from query of url is [string, string]
  // have to transform it to [moment, moment]
  const initialDateRange = get(initialValues, "dateRange");
  if (initialDateRange) {
    const [fromDate, toDate] = initialDateRange;
    if (typeof fromDate === "string") {
      initialValues.dateRange[0] = moment(fromDate);
    }
    if (typeof toDate === "string") {
      initialValues.dateRange[1] = moment(toDate);
    }
  }
  const filterBarProps = { initialValues, loading: loading };
  const tableProps = {
    dataSource: get(resData, "data"),
    pagination: { ...params.pagination, total: get(resData, "total") },
    loading: loading,
  };

  return {
    filterBarProps,
    handleFilterBarChange,
    tableProps,
    handleTableChange,
  };
};

export default usePagePaginationLogic;
