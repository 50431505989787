import { Button, notification, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { PlusOutlined } from "@ant-design/icons";
import FeeList from "src/components/FeeList";
import useAPI from "src/hooks/useAPILoading";
import { getGlobalFee, setGlobalActiveFee } from "src/APIService/global-fee";
import CustomModal from "src/components/antdCustom/Modal";

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  @media only screen and (min-width: 600px) {
    padding: 20px 40px;
  }

  @media only screen and (min-width: 768px) {
    padding: 30px 60px;
  }

  @media only screen and (min-width: 992px) {
    padding: 50px 80px;
  }

  @media only screen and (min-width: 1200px) {
    padding: 50px 120px;
    max-width: 1440px;
  }
  .toolbar {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .fee-list {
    margin-top: 30px;
    width: 100%;
    height: 100%;
  }
  .ant-spin-nested-loading {
    width: 100%;
    height: 100%;
  }
  .ant-spin-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

const ToolBar = () => {
  const { t } = useTranslation();
  return (
    <div className="toolbar">
      <Button type="primary">
        <Link to="/global-fee/create">
          <PlusOutlined /> {t("createNewGlobalFee")}
        </Link>
      </Button>
    </div>
  );
};

export default function GlobalFee() {
  const { t } = useTranslation();
  const history = useHistory();
  const [globalFees, setGlobalFees] = useState();
  const [handleGetGlobalFees, getGlobalFeeLoading] = useAPI(getGlobalFee);
  const [handleActiveNewGlobalFee, activeGlobalFeeLoading] = useAPI(async ({ id, name }) => {
    CustomModal.confirm({
      title: t("applyFeeAsActiveGlobalFee")(name),
      content: t("allMerchantAreUsingGlobalFeeWillBeAffected"),
      onOk: async () => {
        await setGlobalActiveFee(id);
        notification.success({ message: t("updateSuccess") });
        handleGetGlobalFees().then(({ data }) => {
          if (data) setGlobalFees(data);
        });
      },
    });
  });

  const handleClickEdit = (id) => {
    history.push(`/global-fee/${id}`);
  };

  useEffect(() => {
    // active is used to prevent from fetching data when component is unmounted
    let active = true;
    handleGetGlobalFees().then(({ data }) => {
      if (active && data) setGlobalFees(data);
    });
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Spin spinning={getGlobalFeeLoading || activeGlobalFeeLoading}>
        <ToolBar />
        <h2>{t("selectFeeYouWantToApplyGlobally")}</h2>
        <div className="fee-list">
          <FeeList data={globalFees} onActiveFee={handleActiveNewGlobalFee} onClickEdit={handleClickEdit} />
        </div>
      </Spin>
    </Container>
  );
}
