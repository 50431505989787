import { React, useState, useEffect } from "react";
import { Layout, Form, Input, Button, Select, Checkbox, Space, Tag } from "antd";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import useAPILoading from "hooks/useAPILoading";
import { fetchStores } from "APIService/store";
import SwitchConfirm from "components/SwitchConfirm";
// import { CheckOutlined } from '@ant-design/icons';
// import usePasswordRules from 'hooks/FormItemRules/usePasswordRules';

const { Content } = Layout;
const { Option } = Select;
const layout = {
  labelCol: { md: { offset: 0, span: 4 } },
  wrapperCol: { md: { offset: 0, span: 14 } },
};

const Container = styled(Content)`
  background: #fff;
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  // width: 50%;
  margin: 20px;
`;

const FormAdminDetail = ({ value, onSubmit, loading, editMode }) => {
  const [getStore, loadingGetStore] = useAPILoading(fetchStores);
  const { t } = useTranslation();
  // var newValue = {}

  // const passwordRules = usePasswordRules();
  const [selectedRole, setSelectedRole] = useState(value.role);
  const [isCheckboxSelected, setIsCheckboxSelected] = useState(
    value.role === "admin" && "merchant_id" in value ? true : false
  );
  const [storeList, setStoreList] = useState([]);
  const [form] = Form.useForm();
  let newValue = {};

  if ("merchant_id" in value) {
    const { merchant_id, ...others } = value;
    newValue = {
      merchant_id: merchant_id.map((location) => ({
        value: location.merchant_id,
        label: location.store_name,
      })),
      ...others,
    };
  } else {
    newValue = value;
  }

  useEffect(() => {
    if (selectedRole === "retail_staff" || (isCheckboxSelected && selectedRole === "admin")) {
      getStore()
        .then(({ data }) => {
          const { data: storeListData } = data;
          if (storeListData) {
            const mappedStoreList = storeListData.map(({ store_name, id }) => ({
              value: id,
              label: store_name,
            }));
            setStoreList(mappedStoreList);
          }
        })
        .catch((error) => console.error("Store API Error:", error));
    }
  }, [selectedRole, getStore, isCheckboxSelected]);

  const onChangeRole = (val) => {
    setSelectedRole(val);
    setIsCheckboxSelected(false);
  };

  const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "10px",
          margin: "20px",
        }}
      >
        <Space wrap>
          <Button size="large" type="primary" onClick={() => form.submit()}>
            Confirm
          </Button>
        </Space>
      </div>

      <Container>
        <Content>
          <Form
            {...layout}
            form={form}
            colon={false}
            layout="vertical"
            scrollToFirstError
            onFinish={onSubmit}
            initialValues={newValue}
          >
            <Form.Item noStyle dependencies={["isActive"]}>
              {(form) => {
                const isActive = editMode ? form.getFieldValue("is_active") : true;
                return (
                  <div>
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      User Details &nbsp;
                      {editMode && (isActive ? <Tag color="blue">Active</Tag> : <Tag color="default">Inactive</Tag>)}
                    </p>
                    {/* fullname */}
                    <Form.Item
                      name="full_name"
                      label={t("fullName")}
                      rules={[
                        {
                          required: true,
                          message: t("error:fullNameRequired"),
                        },
                        {
                          pattern: new RegExp(/^(?!\s)[a-zA-Z0-9\s]+$/),
                          message: t("error:phone.pattern"),
                        },
                      ]}
                    >
                      <Input disabled={!isActive} />
                    </Form.Item>
                    {/* IC */}
                    <Form.Item
                      name="ic"
                      label={t("ic")}
                      rules={[
                        {
                          pattern: new RegExp(/^[0-9]*$/),
                          message: t("error:ic.pattern"),
                        },
                        { required: true, message: t("error:ic.required") },
                      ]}
                    >
                      <Input disabled={!isActive} />
                    </Form.Item>
                    {/* email */}
                    <Form.Item
                      name="email"
                      label={t("email")}
                      F
                      rules={[
                        { required: true, message: t("error:email.required") },
                        { type: "email", message: t("error:email.valid") },
                      ]}
                    >
                      <Input disabled={!isActive} />
                    </Form.Item>
                    {/* phone */}
                    <Form.Item
                      name="phone"
                      label={t("phone")}
                      rules={[
                        {
                          pattern: new RegExp(/^[0-9]*$/),
                          message: t("error:phone.pattern"),
                        },
                        { required: true, message: t("error:phone.required") },
                      ]}
                    >
                      <Input disabled={!isActive} addonBefore="+60" placeholder="123456789" />
                    </Form.Item>

                    {/* {!editMode && (
                  <Form.Item
                    name='password'
                    label={t('password')}
                    rules={passwordRules}>
                    <Input disabled={!isActive} />
                  </Form.Item>
                )} */}
                  </div>
                );
              }}
            </Form.Item>
            {/* active */}
            {editMode && (
              <Form.Item label={t("active")} name={"is_active"} valuePropName="checked">
                <SwitchConfirm
                  enableConfirm={{
                    title: t("confirmActiveAmin.title"),
                    content: t("confirmActiveAmin.message"),
                  }}
                  disableConfirm={{
                    title: t("confirmInactiveAdmin.title"),
                    content: t("confirmInactiveAdmin.message"),
                  }}
                />
              </Form.Item>
            )}
          </Form>
        </Content>
      </Container>

      <Container>
        <Content>
          <Form
            {...layout}
            colon={false}
            form={form}
            layout="vertical"
            scrollToFirstError
            onFinish={onSubmit}
            initialValues={newValue}
          >
            {/* role */}
            <Form.Item noStyle dependencies={["isActive"]}>
              {(form) => {
                const isActive = editMode ? form.getFieldValue("is_active") : true;
                return (
                  <div>
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Role and location access
                    </p>
                    <Form.Item name="role" label={t("role")}>
                      <Select onChange={onChangeRole} value={selectedRole} disabled={!isActive}>
                        <Option value="super_admin">{t("superAdmin")}</Option>
                        <Option value="admin">{t("admin")}</Option>
                        <Option value="retail_staff">{t("retailStaff")}</Option>
                      </Select>
                    </Form.Item>
                    {selectedRole === "admin" && (
                      <div
                        style={
                          {
                            // marginLeft: '21%'
                          }
                        }
                      >
                        <Form.Item style={{ marginTop: "-25px" }}>
                          <Checkbox
                            style={{ fontSize: "12px", margin: 0 }}
                            onChange={(e) => setIsCheckboxSelected(e.target.checked)}
                            checked={isCheckboxSelected}
                            disabled={!isActive}
                          >
                            Grant access to both Admin and Merchant Portals
                          </Checkbox>
                        </Form.Item>
                      </div>
                    )}

                    {(selectedRole === "retail_staff" || (isCheckboxSelected && selectedRole === "admin")) && (
                      <Form.Item
                        name="merchant_id"
                        label={t("kioskLocation")}
                        rules={[
                          {
                            required: true,
                            message: t("error:kioskLocation.required"),
                          },
                        ]}
                      >
                        <Select
                          mode="multiple"
                          placeholder="Select kiosk location"
                          options={storeList}
                          loading={loadingGetStore}
                          disabled={!isActive}
                          filterOption={filterOption}
                        />
                      </Form.Item>
                    )}
                  </div>
                );
              }}
            </Form.Item>
          </Form>
        </Content>
      </Container>
    </>
  );
};

export default FormAdminDetail;
