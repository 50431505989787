import React, { useEffect, useState, useCallback } from "react";
import { Button, Spin, Table, Card, Form, Row, Col, Select } from "antd";
import { fetchProducts } from "src/APIService/product";
import { ProductListingStyled } from "./styled";

const ProductListing = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalValue, setTotalValue] = useState();
  const [selectedStores, setSelectedStores] = useState(null);
  const [stores, setStoreNamesData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
  };

  const calculateTotalValue = useCallback((transformedData) => {
    let sumTotalValue = 0;

    for (const entry of transformedData) {
        sumTotalValue += entry.total_sum_value;
    }

    setTotalValue(sumTotalValue);
  }, []);

  const transformDataForTable = useCallback(() => {

    const transformedData = [];

    for (const storeName in products) {
        const store = products[storeName];

        if (storeName !== 'total_sum_stock_current_balance' && storeName !== 'total_sum_value' && storeName !== 'null') {
          const entry = {
              merchant_id: store.items[0].merchant_id,
              store_name: store.store_name,
              total_sum_stock_current_balance: store.total_sum_stock_current_balance,
              total_sum_value: store.total_sum_value,
          };

          transformedData.push(entry);
        }
    }

    // console.log(transformedData);
    return transformedData;
  }, [products]);


  const fetchData = useCallback(async () => {
    setLoading(true);

    try {
      const response = await fetchProducts();

      const storeData = Object.keys(response.data)
        .filter(storeName => storeName !== 'null') // Exclude 'null'
        .map(storeName => ({
            label: storeName,
            value: response.data[storeName].items[0].merchant_id,
        }));

      const allOption = { label: "All", value: -1 };
      setProducts(response.data);
      setStoreNamesData([allOption, ...storeData]);

    } catch (error) {
        console.error("Error fetching products:", error);
    }

    setLoading(false);
  }, [setProducts]);

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const response = await fetchProducts(selectedStores);
      setProducts(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (!products || Object.keys(products).length === 0) {
      fetchData();
    } else {
      transformDataForTable();
    }
  }, [products, fetchData, transformDataForTable]);

  useEffect(() => {
    const transformedData = transformDataForTable();
    calculateTotalValue(transformedData);
  }, [transformDataForTable, calculateTotalValue]);

  
  const columns = [
    {
      title: "Location",
      dataIndex: "store_name", 
      width: 150,
      key: "store_name",
    },
    {
      title: "Total stock (RM)",
      dataIndex: "total_sum_value",
      key: "total_sum_value",
      width: 150,
      render: (text, record) => (
        <span>{parseFloat(text).toFixed(2)}</span>
      ),
    },
  ];

  return (
    <ProductListingStyled>
      <Spin spinning={loading}>

        <Card className="product-listing-card">
          <div
            style={{
              width: "100%",
              height: "100%",
              justifyContent: "flex-start",
              alignItems: "center",
              display: "inline-flex",
            }}
          >
            <div className="summary-details">
              <div className="summary-details-title">Total in stock</div>
              <div className="summary-details-text">
                RM {parseFloat(totalValue || 0).toFixed(2)}
              </div>
            </div>
          </div>
        </Card>
        <Card className="product-listing-card">
          <Row
            gutter={8}
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 24,
              paddingBottom: 10,
            }}
          >
            <Col className="gutter-row" xs={24} md={16} lg={12} xxl={8}>
              <Form onFinish={(values) => handleSubmit(values)}>
                <Row gutter={8}>
                  <Col span={24}>
                    <h4>Search</h4>
                  </Col>
                  <Col xs={12} md={12} lg={12} xxl={12}>
                    <Form.Item>
                      <Select
                        name="storeName"
                        showSearch
                        options={stores}
                        filterOption={(input, option) => (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())}
                        placeholder={"All"}
                        onChange={(value) => setSelectedStores(value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={8} md={8} lg={8} xxl={8}>
                    <Form.Item>
                      <Button
                        block
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                      >
                        Search
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <Table
            scroll={{ x: "100%" }}
            columns={columns}
            dataSource={transformDataForTable()}  
            style={{ cursor: "pointer" }}
            pagination={{
              current: currentPage,
              pageSize: 5,
              total: transformDataForTable().length,
              showSizeChanger: true,
              onChange: handleTableChange,
            }}
          />
        </Card>
      </Spin>
    </ProductListingStyled>
  );
};

export default ProductListing;

