import { Button, Form, Input, notification, Spin } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { createFee } from "src/APIService/fees";
import TiersInputContainer from "src/components/TiersInput";
import useAPI from "src/hooks/useAPILoading";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  @media only screen and (min-width: 600px) {
    padding: 20px 40px;
  }

  @media only screen and (min-width: 768px) {
    padding: 30px 60px;
  }

  @media only screen and (min-width: 992px) {
    padding: 50px 80px;
  }

  @media only screen and (min-width: 1200px) {
    padding: 50px 120px;
    max-width: 1440px;
  }
  .form-content {
    max-width: 645px;
    flex: 1;
    width: 100%;
  }
  .ant-form {
    flex: 1;
    width: 100%;
  }
  .toolbar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    @media only screen and (min-width: 600px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }
`;

export default function CreateGlobalFee() {
  const { t } = useTranslation();
  const history = useHistory();
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      await createFee(values);
      notification.success({ message: t("createSuccess") });
      history.push("/global-fee");
    } catch (err) {
      const { response } = err;
      if (response) {
        const { data } = response;
        if (data) {
          const { tiers } = data;
          if (tiers) form.setFieldValue("tiers", tiers);
        }
      }
    }
  };

  const [handleFinish, loading] = useAPI(onFinish);

  return (
    <Spin spinning={loading}>
      <Container>
        <Form
          form={form}
          layout="horizontal"
          initialValues={{ name: "", tiers: [] }}
          onFinish={handleFinish}
          className="ant-form"
        >
          <Form.Item>
            <div className="toolbar">
              <h2>{t("createAGlobalFee")}</h2>
              <div style={{ marginLeft: -10, marginRight: -10 }}>
                <Button style={{ marginLeft: 10, marginRight: 10 }}>
                  <Link to="/global-fee">{t("cancel")}</Link>
                </Button>
                <Button style={{ marginLeft: 10, marginRight: 10 }} type="primary" htmlType="submit">
                  {t("save")}
                </Button>
              </div>
            </div>
          </Form.Item>
          <div className="form-content">
            <Form.Item name="name" label={t("feeName")} style={{ width: 300 }} rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="tiers" label={t("tiers")} rules={[{ required: true }]}>
              <TiersInputContainer />
            </Form.Item>
          </div>
        </Form>
      </Container>
    </Spin>
  );
}
