import React from "react";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import moment from "moment";
import styled from "styled-components";
import ButtonExport from "components/ButtonExport";
import exportFile from "utils/exportHandler";
import { typeStatisticBy } from "../constant";
const StatisticStyled = styled.div`
  margin-bottom: 20;
  .weekend {
    td {
      border-width: 2px;
      border-color: #bbb;
    }
  }
  .ant-table-small .ant-table-thead > tr > th,
  .background-gray {
    background: #f5f5f5;
  }
  .float-right {
    text-align: right;
    margin-bottom: 20px;
  }
`;
const getRowKey = (record) => record.id;
const TableTransactions = ({ dataSource, loading, onChange, statisticBy, columns }) => {
  const { t } = useTranslation();
  return (
    <StatisticStyled>
      <div className="float-right">
        <ButtonExport
          style={{ textAlign: "right", display: "inline" }}
          disabled={loading || get(dataSource, "length", 0) === 0}
          onExportCSV={() => {
            exportFile(dataSource, columns, "transactionStatistic", "csv");
          }}
          onExportExcel={() => {
            exportFile(dataSource, columns, "transactionStatistic", "xlsx");
          }}
        >
          {t("export")}
        </ButtonExport>
      </div>
      <Table
        rowKey={getRowKey}
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        onChange={onChange}
        bordered
        pagination={false}
        size="small"
        rowClassName={(rowData) => {
          if (statisticBy !== typeStatisticBy.daily) return "";
          const { date } = rowData;
          if (!date) return "";
          //weekday return 0 to 6
          return moment(date).weekday() === 6 ? "weekend" : "";
        }}
        scroll={{ x: 1200, y: "calc(100vh - 250px)" }}
      />
    </StatisticStyled>
  );
};
export default TableTransactions;