import get from "lodash/get";

import { defaultOpenDay, SETTING_AVAILABILITY } from "variables";

export const formatStore = (store) => {
  //transform data form sv model to UI model to showing on UI
  const newStoreData = { ...store };
  const is_active = get(store, "is_active", 0);
  const purchase_required = get(store, "purchase_required", 0);
  const is_3rd_saturday_off = get(store, "is_3rd_saturday_off", 0);
  const is_public_holiday_off = get(store, "is_public_holiday_off", 0);
  const is_voucher_on = get(store, "is_voucher_on", 0);

  newStoreData.is_active = !!is_active;
  newStoreData.is_3rd_saturday_off = !!is_3rd_saturday_off;
  newStoreData.is_public_holiday_off = !!is_public_holiday_off;
  newStoreData.purchase_required = !!purchase_required;
  newStoreData.is_voucher_on = !!is_voucher_on;

  if (newStoreData.open_day === null) {
    newStoreData.open_day = defaultOpenDay;
  }

  if (newStoreData.is_available === SETTING_AVAILABILITY.AVAILABLE) {
    newStoreData.is_available = true;
  }

  if (newStoreData.is_available === SETTING_AVAILABILITY.UNAVAILABLE) {
    newStoreData.is_available = false;
  }

  return newStoreData;
};

export const parseStore = (store) => {
  //transform data form UI model to sv modal to submit
  const { open_day, is_available } = store;
  const is_active = get(store, "is_active", false);
  const purchase_required = get(store, "purchase_required", false);
  const is_3rd_saturday_off = get(store, "is_3rd_saturday_off", false);
  const is_public_holiday_off = get(store, "is_public_holiday_off", false);
  const is_voucher_on = get(store, "is_voucher_on", false);

  let transformedOpenDay = open_day;
  if (open_day) {
    transformedOpenDay = {};
    for (let day in open_day) {
      const { shifts = [] } = open_day[day];
      const temp = {};
      // remove null shift
      temp.shifts = shifts.filter(Boolean);
      // assign to transformedOpenDay
      transformedOpenDay[day] = temp;
    }
  }

  // transform is_available from boolean to string
  const transformedIsAvailable = is_available ? SETTING_AVAILABILITY.AVAILABLE : SETTING_AVAILABILITY.UNAVAILABLE;

  return {
    ...store,
    is_active: is_active ? 1 : 0,
    purchase_required: purchase_required ? 1 : 0,
    open_day: transformedOpenDay,
    is_available: transformedIsAvailable,
    is_3rd_saturday_off: is_3rd_saturday_off ? 1 : 0,
    is_public_holiday_off: is_public_holiday_off ? 1 : 0,
    is_voucher_on: is_voucher_on ? 1 : 0,
  };
};
