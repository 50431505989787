import { useState, useEffect } from "react";
import { notification, Spin, Empty } from "antd";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import useAPILoading from "hooks/useAPILoading";
import { fetchStoreById, updateStore } from "APIService/store";
import FormStoreDetail from "../components/FormStoreDetail";

const CenterPageStyle = styled.div`
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EditStore = (props) => {
  const id = get(props, "match.params.id");

  const { t } = useTranslation();
  const history = useHistory();
  const [store, setStore] = useState();
  const [getStoreById, loadingGetStore] = useAPILoading(fetchStoreById);
  const [requestUpdateStore, loading] = useAPILoading(updateStore);

  useEffect(() => {
    getStoreById(id).then(({ data }) => {
      setStore(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loadingGetStore)
    return (
      <CenterPageStyle>
        <Spin size="large" />
      </CenterPageStyle>
    );
  if (!store)
    return (
      <CenterPageStyle>
        <Empty
          description={
            <span>
              {t("storeNotFound")} <Link to="/stores">{t("backToListStore")}</Link>
            </span>
          }
        ></Empty>
      </CenterPageStyle>
    );

  const onUpdateStore = (newStore) => {
    //deep coppy store object from form
    const transformStore = { ...newStore };
    // check whether change new logo ornot
    if (transformStore.store_logo === store.store_logo) {
      delete transformStore.store_logo;
    } else if (store.store_logo) {
      // if store had logo send logo with body
      transformStore.current_store_logo = store.store_logo;
    }
    requestUpdateStore(id, transformStore).then(() => {
      notification.success({
        message: t("updateSettingsSuccess"),
        placement: "bottomRight",
      });
      history.goBack();
    });
  };

  return (
    <div>
      <h1> {t("editStore")} </h1>
      <FormStoreDetail editMode onSubmit={onUpdateStore} loading={loading} store={store} />
    </div>
  );
};

export default EditStore;
