const my = {
  email: {
    required: "Sila masukkan emel anda",
    valid: "Alamat emel tidak sah",
  },
  password: {
    required: "Sila masukkan kata laluan anda",
    min: "Perlu lebih daripada 6 aksara",
  },
  url: {
    invalid: "Invalid URL",
  },
  addressRequired: "Sila masukkan alamat lokasi",
  postcodeRequired: "Sila masukkan poskod lokasi",
  stateRequired: "Sila pilih negeri lokasi",
  kioskTypeRequired: "Sila pilih jenis kiosk",
  invalidPostcode: "Poskod tidak sah",
  storeNameRequired: "Sila masukkan nama lokasi",
  miniumAmountRequired: "Sila masukkan nilai minimum yang diperlukan",
  latitudeRequired: "Sila masukkan latitud",
  longitudeRequired: "Sila masukkan longitud",
  workingTimeInvalid: "Masa buka mesti sebelum masa tutup",
  digit: "Sila masukkan nombor sahaja",
  updateSettingsFailed: "Gagal mengemas kini tetapan",
  emptyRequestHelp: "Sila masukkan sesuatu",

  INVALID_EMAIL: "Alamat emel tidak sah",
  INVALID_EMAIL_OR_PASSWORD: "Alamat emel atau kata laluan tidak sah",
  INVALID_VERIFY_CODE: "Kod penentusahan tidak sah",
  CAN_NOT_FIND_STORE: "Lokasi tidak dapat dikesan",
  PLEASE_INPUT_CORRECT_DATA: "Sila masukkan data yang betul",
  // INVALID_PASSWORD: 'Invalid password',

  fullNameRequired: "Nama penuh diperlukan",
  EMAIL_ADDRESS_EXISTS: "Alamat emel sudah wujud", //api error when create admin account
  SOMETHING_WENT_WRONG: "Terdapat masalah yang berlaku",
  YOUR_DATA_IS_OUT_OF_DATE: "Maklumat tidak dikemaskinikan.  Sila muat semula muka halaman anda",
  FEE_DOES_NOT_EXIST: "Caj tidak wujud",
};

export default my;
