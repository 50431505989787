import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SectionCard from "./SectionCard";
import useAPILoading from "hooks/useAPILoading";
import { fetchOrders } from "src/APIService/orders";
import "./styled.css";

const OrderDetails = () => {
  const [orders, setOrders] = useState();
  const [getOrders] = useAPILoading(fetchOrders);
  const location = useLocation();
  const orderId = location.state && location.state.orderId;

  const stringOrderId = ` ${orderId}`;

  const detailsOrders = () => {
    getOrders({
      order_id_or_client_name: stringOrderId,
    }).then(({ data }) => setOrders(data));
  };

  useEffect(() => {
    detailsOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <SectionCard title="Section" content="Content" orders={orders} />;
};

export default OrderDetails;
