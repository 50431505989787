import React from "react";
import { Layout, Form, Input, Button, Tooltip, InputNumber, Select, Switch, Checkbox } from "antd";
import { useTranslation } from "react-i18next";
import { CheckOutlined } from "@ant-design/icons";
import styled from "styled-components";

import SwitchConfirm from "components//SwitchConfirm";
import { formatCurrency } from "utils";
import { phoneCountryCode } from "variables";
import WorkingTimeSetting from "./WorkingTimeSetting";
import { formatStore, parseStore } from "./helpers";
import UploadFile from "./UploadFile";

const { Content } = Layout;
const { Option } = Select;
const layout = { labelCol: { span: 5 }, wrapperCol: { span: 6 } };
const columnLayout = {
  labelCol: { xs: { span: 4 } },
  wrapperCol: { xs: { span: 6 } },
};

const tailLayout = {
  wrapperCol: { xs: { offset: 0, span: 6 }, md: { offset: 4, span: 6 } },
};
const settingTimeLayout = {
  wrapperCol: { xs: { span: 24 }, md: { offset: 3, span: 21 } },
};

const StoreDetailStyled = styled.div`
  &.in-active {
    display: none;
  }
`;

const FormStoreDetail = ({ store, onSubmit, loading, editMode }) => {
  const { t } = useTranslation();

  const onFinish = (store) => {
    if (store.booking_url === "") store.booking_url = null;
    onSubmit(parseStore(store));
  };

  const prefixSelector = (
    <Form.Item name="store_country_code" noStyle>
      <Select style={{ width: 70 }}>
        {phoneCountryCode.map((code, idx) => (
          <Option key={idx.toString()} value={code}>
            {code}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
  return (
    <Content
      style={{
        padding: 20,
        background: "white",
        borderTop: "1px black solid",
      }}
    >
      <Form
        {...layout}
        colon={false}
        layout="horizontal"
        scrollToFirstError
        onFinish={onFinish}
        initialValues={formatStore(store)}
      >
        {editMode && (
          <Form.Item label={t("active")} name={"is_active"} valuePropName="checked">
            <SwitchConfirm
              enableConfirm={{
                title: t("confirmActiveStore.title"),
                content: t("confirmActiveStore.message"),
              }}
              disableConfirm={{
                title: t("confirmInactiveStore.title"),
                content: t("confirmInactiveStore.message"),
              }}
            />
          </Form.Item>
        )}
        <Form.Item noStyle dependencies={["is_active"]}>
          {(form) => {
            const is_active = form.getFieldValue("is_active");

            return (
              <StoreDetailStyled className={is_active ? null : "in-active"}>
                <Form.Item
                  name="store_name"
                  label={t("storeName")}
                  rules={[{ required: true, message: t("error:storeNameRequired") }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item name="store_phone" label={t("storePhone")}>
                  <Input type="number" addonBefore={prefixSelector} name="store_phone" />
                </Form.Item>
                <Form.Item name="store_logo" label={t("storeLogo")}>
                  <UploadFile />
                </Form.Item>
                <Form.Item
                  name="purchase_required"
                  label={t("purchaseRequired")}
                  valuePropName="checked"
                  initialValue={true}
                  tooltip={t("purchaseRequiredToolTip")}
                >
                  <Switch />
                </Form.Item>
                <Form.Item noStyle dependencies={["purchase_required"]}>
                  {(form) => {
                    const purchase_required = form.getFieldValue("purchase_required");

                    return (
                      <Form.Item name="min_amount" label={t("miniumAmountRequired")}>
                        <InputNumber
                          disabled={!purchase_required}
                          min={0}
                          addonBefore="RM"
                          style={{ width: "100%" }}
                          formatter={formatCurrency}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>

                <Form.Item
                  name="address"
                  label={t("address")}
                  rules={[{ required: true, message: t("error:addressRequired") }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="postcode"
                  label={t("postcode")}
                  rules={[
                    { required: true, message: t("error:postcodeRequired") },
                    { pattern: new RegExp("^\\d{5}$"), message: t("error:invalidPostcode") }, // post code pattern!
                  ]}
                >
                  <Input style={{ width: "100%" }} />
                </Form.Item>

                <Form.Item
                  name="state"
                  label={t("state")}
                  rules={[{ required: true, message: t("error:stateRequired") }]}
                >
                  <Select style={{ width: "100%" }}>
                    <Option value="Selangor">Selangor</Option>
                    <Option value="Kuala Lumpur">Kuala Lumpur</Option>
                    <Option value="Johor">Johor</Option>
                    <Option value="Kedah">Kedah</Option>
                    <Option value="Kelantan">Kelantan</Option>
                    <Option value="Melaka">Melaka</Option>
                    <Option value="Negeri Sembilan">Negeri Sembilan</Option>
                    <Option value="Pahang">Pahang</Option>
                    <Option value="Penang">Penang</Option>
                    <Option value="Perak">Perak</Option>
                    <Option value="Perlis">Perlis</Option>
                    <Option value="Sabah">Sabah</Option>
                    <Option value="Sarawak">Sarawak</Option>
                    <Option value="Terengganu">Terengganu</Option>
                    <Option value="Labuan">Labuan</Option>
                    <Option value="Putrajaya">Putrajaya</Option>
                  </Select>
                </Form.Item>
                <div
                  style={{
                    background: "#dee5ea",
                    padding: "15px",
                    marginBottom: "30px",
                  }}
                >
                  <Form.Item label={t("locationSetting")} {...columnLayout}>
                    <Tooltip title={t("tooltipFindLatitude")}>
                      <a href="https://support.google.com/maps/answer/18539?hl=en" rel="noreferrer" target="_blank">
                        {t("needHelp")}
                      </a>
                    </Tooltip>
                  </Form.Item>
                  <Form.Item
                    name="latitude"
                    label={t("latitude")}
                    rules={[{ required: true, message: t("error:latitudeRequired") }]}
                  >
                    <InputNumber style={{ width: "100%" }} />
                  </Form.Item>
                  <Form.Item
                    name="longitude"
                    label={t("longitude")}
                    rules={[{ required: true, message: t("error:longitudeRequired") }]}
                  >
                    <InputNumber style={{ width: "100%" }} />
                  </Form.Item>
                </div>

                <Form.Item
                  name="kiosk_type"
                  label={t("kioskType")}
                  rules={[{ required: true, message: t("error:kioskTypeRequired") }]}
                >
                  <Select style={{ width: "100%" }}>
                    <Option value="permanent">Permanent Kiosk</Option>
                    <Option value="flexi">Flexi Kiosk</Option>
                    <Option value="mobile">Mobile Kiosk</Option>
                  </Select>
                </Form.Item>

                <Form.Item label={t("online")} name={"is_available"} valuePropName="checked">
                  <SwitchConfirm
                    enableConfirm={{
                      title: t("openStore.confirmTitle"),
                      content: t("openStore.confirmMessage"),
                    }}
                    disableConfirm={{
                      title: t("closeStore.confirmTitle"),
                      content: t("closeStore.confirmMessage"),
                    }}
                  />
                </Form.Item>
                <Form.Item dependencies={["is_available"]} {...settingTimeLayout}>
                  {(form) => {
                    const is_available = form.getFieldValue("is_available");

                    return <WorkingTimeSetting is_available={is_available} />;
                  }}
                </Form.Item>
                {/* insert a new checkbox form item for is_3rd_saturday_off */}

                <Form.Item
                  name={"is_3rd_saturday_off"}
                  label={t("closeOn3rdSaturday")}
                  valuePropName="checked"
                  tooltip={t("closeOn3rdSaturdayToolTip")}
                >
                  <Checkbox />
                </Form.Item>

                <Form.Item
                  name={"is_public_holiday_off"}
                  label={t("closeOnPublicHoliday")}
                  valuePropName="checked"
                  tooltip={t("closeOnPublicHolidayToolTip")}
                >
                  <Checkbox />
                </Form.Item>

                <Form.Item
                  name={"is_voucher_on"}
                  label={t("voucherIsOn")}
                  valuePropName="checked"
                  tooltip={t("voucherIsOnToolTip")}
                >
                  <Checkbox />
                </Form.Item>

                <Form.Item
                  name="email"
                  label={t("email")}
                  extra={
                    editMode ? null : (
                      <div
                        style={{
                          fontSize: "12px",
                          textAlign: "justify",
                          color: "#8c8c8c",
                        }}
                      >
                        {t("emailDescription")}
                      </div>
                    )
                  }
                  rules={
                    editMode
                      ? []
                      : [
                          {
                            required: true,
                            message: t("error:email.required"),
                          },
                          { type: "email", message: t("error:email.valid") },
                        ]
                  }
                >
                  <Input disabled={editMode} />
                </Form.Item>
                <Form.Item
                  name="booking_url"
                  label={t("bookingUrl")}
                  rules={[{ type: "url", message: t("error:url.invalid") }]}
                >
                  <Input />
                </Form.Item>
              </StoreDetailStyled>
            );
          }}
        </Form.Item>
        <Form.Item {...tailLayout} style={{ paddingTop: "2vh" }}>
          <Button block type="primary" htmlType="submit" loading={loading} icon={<CheckOutlined />}></Button>
        </Form.Item>
      </Form>
    </Content>
  );
};

export default FormStoreDetail;
