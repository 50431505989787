import React, { useState, useEffect, useMemo } from "react";
import { Layout } from "antd";
import { get, sum } from "lodash";
import moment from "moment";
import styled from "styled-components";

import useAPILoading from "hooks/useAPILoading";
import { apiTransactionStatistic } from "APIService/transactionService.js";
import FilterBar from "./FilterBar";
import { typeStatisticBy } from "./constant";
import Table from "./Table";
import Footer from "./Table/Footer";
import useColumns from "./Table/useColumns";

const { Content } = Layout;

const Container = styled(Content)`
  padding: 20px;
  .group {
    background: #fff;
    padding: 20px;
  }

  .search-bar {
    margin-bottom: 30px;
  }
`;

const formatDataToCallAPI = (filter) => {
  const { dateRange, storeId, ...others } = filter;
  return {
    ...others,
    storeId,
    fromDate: get(dateRange, "[0]"),
    toDate: get(dateRange, "[1]"),
  };
};

const TransactionsStatistic = (props) => {
  const [statisticData, setStatisticData] = useState({
    data: null,
    statisticBy: typeStatisticBy.daily,
  });
  const [fetchTransactionStatistic, loading] = useAPILoading(apiTransactionStatistic);
  const { data, statisticBy } = statisticData;

  const columns = useColumns({ statisticBy });
  const footerColumns = useMemo(() => columns.filter((row) => row.showingInFooter), [columns]);

  const initFilter = useMemo(
    () => ({
      statisticBy,
      dateRange: [moment().subtract(1, "months").startOf("day"), moment().endOf("day")],
    }),
    [statisticBy]
  );

  const onFetchTransactionStatistic = (filterData) => {
    fetchTransactionStatistic(formatDataToCallAPI(filterData)).then((data) => {
      setStatisticData({
        data,
        statisticBy: filterData.statisticBy,
      });
    });
  };

  useEffect(() => {
    onFetchTransactionStatistic(initFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitFilter = (filterData) => {
    onFetchTransactionStatistic(filterData);
  };

  const isNotEmptyRecord = sum(data?.map(({ total_transaction }) => total_transaction)) > 0;

  return (
    <Container>
      <div className="group search-bar">
        <FilterBar initialValues={initFilter} onFinish={onSubmitFilter} loading={loading} />
      </div>
      <div className="group">
        {isNotEmptyRecord && <Table dataSource={data} loading={loading} statisticBy={statisticBy} columns={columns} />}
        <Footer dataSource={data} columns={footerColumns} />
      </div>
    </Container>
  );
};

export default TransactionsStatistic;
