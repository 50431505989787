import { useState } from "react";
import { useMemo, useCallback } from "react";
import moment from "moment";
import { TimePicker } from "antd";
import { workingTimeFormat } from "variables";
import "./InputTime.css";
/**
 *
 * @param {object} props
 * @param {function} props.onChange (ISODateString) => any
 */

const InputTime = (props) => {
  const { value, onChange, ...other } = props;

  const [selectedTime, setSelectedTime] = useState(value);
  const formattedValue = useMemo(() => moment(selectedTime), [selectedTime]);
  // const customOnchange = useCallback(
  //   (value) => onChange(value && value.toISOString()),
  //   [onChange],
  // );

  const customOnSelect = useCallback(
    (value) => {
      setSelectedTime(value);
      onChange(value && value.toISOString());
    },
    [onChange]
  );

  return (
    <TimePicker
      // onChange={customOnchange}
      value={formattedValue}
      popupClassName="timepicker"
      onSelect={customOnSelect}
      format={workingTimeFormat}
      allowClear={false}
      {...other}
    />
  );
};

export default InputTime;
