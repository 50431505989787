import { Button, notification, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { PlusOutlined } from "@ant-design/icons";
import get from "lodash/get";
import some from "lodash/some";

import FeeList from "src/components/FeeList";
import useAPI from "src/hooks/useAPILoading";
import CustomModal from "src/components/antdCustom/Modal";
import { getFees } from "src/APIService/fees";
import { setActiveFeeForMerchant } from "src/APIService/[merchantId]/active-fee";
import { fetchStoreById } from "src/APIService/store";

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  @media only screen and (min-width: 600px) {
    padding: 20px 40px;
  }

  @media only screen and (min-width: 768px) {
    padding: 30px 60px;
  }

  @media only screen and (min-width: 992px) {
    padding: 50px 80px;
  }

  @media only screen and (min-width: 1200px) {
    padding: 50px 120px;
    max-width: 1440px;
  }
  .toolbar {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .fee-list {
    margin-top: 30px;
    width: 100%;
    height: 100%;
  }
  .ant-spin-nested-loading {
    width: 100%;
    height: 100%;
  }
  .ant-spin-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

const ToolBar = ({ merchantId }) => {
  const { t } = useTranslation();
  return (
    <div className="toolbar">
      <Button type="primary">
        <Link to={`/stores/${merchantId}/fee-list/create`}>
          <PlusOutlined /> {t("createNewCustomFee")}
        </Link>
      </Button>
    </div>
  );
};

export default function StoreFeeList(props) {
  const merchantId = get(props, "match.params.id");
  const history = useHistory();

  const { t } = useTranslation();
  const [fees, setFees] = useState();
  const [store, setStore] = useState();
  const [getStoreById, getStoreLoading] = useAPI(fetchStoreById);
  const [handleGetFees, getFeesLoading] = useAPI(getFees);
  const [handleActiveFee, activeFeeLoading] = useAPI(async ({ id, name }) => {
    CustomModal.confirm({
      content: t("applyFeeAsActiveOfThisLocation")(name),
      onOk: async () => {
        await setActiveFeeForMerchant({
          merchantId,
          feeId: id,
        });
        notification.success({ message: t("updateSuccess") });
        handleGetFees(merchantId).then(({ data }) => {
          if (data) setFees(data);
        });
      },
    });
  });
  const [handleActiveGlobalFee, activeGlobalFeeLoading] = useAPI(async () => {
    CustomModal.confirm({
      title: t("applyGlobalFeeToThisLocation"),
      content: t("thisMerchantWillUseGlobalFee")(store.store_name),
      onOk: async () => {
        await setActiveFeeForMerchant({ merchantId, useGlobalFee: true });
        notification.success({ message: t("updateSuccess") });
        handleGetFees(merchantId).then(({ data }) => {
          if (data) setFees(data);
        });
      },
    });
  });

  const handleClickEdit = (id) => {
    history.push(`/stores/${merchantId}/fee-list/${id}`);
  };

  const isUsingCustomizedFee = some(fees, "isActive");

  // TODO: find optimal ways to get name of merchant
  useEffect(() => {
    getStoreById(merchantId).then(({ data }) => {
      setStore(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // active is used to prevent from fetching data when component is unmounted
    let active = true;
    handleGetFees(merchantId).then(({ data }) => {
      if (active && data) setFees(data);
    });
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Spin spinning={getFeesLoading || activeFeeLoading || activeGlobalFeeLoading || getStoreLoading}>
        <div>
          <ToolBar merchantId={merchantId} />
          <Button
            disabled={!isUsingCustomizedFee}
            onClick={handleActiveGlobalFee}
            type="primary"
            style={{ marginTop: 20 }}
          >
            {t("useGlobalFeeForThisLocation")}
          </Button>
          <div className="fee-list">
            <h3 style={{ marginTop: 20 }}>{t("orSelectACustomFeeYouWannaApply")}</h3>
            <FeeList data={fees} onActiveFee={handleActiveFee} onClickEdit={handleClickEdit} />
          </div>
        </div>
      </Spin>
    </Container>
  );
}
