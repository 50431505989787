import React from "react";
import styled from "styled-components";
import { UpOutlined, DownOutlined } from "@ant-design/icons";

import TableResizable from "components/antdCustom/TableResizable";
import useColumns from "./useColumns";
import ExpandedRow from "./ExpandedRow";

const TableLogsStyled = styled.div`
  .ant-table-tbody > tr.ant-table-row {
    &.inactive {
      background: #f3f3f3;
      color: #9d9d9d;

      &:hover > td {
        background: #efefef;
      }
    }

    &.row-green {
      background: #f9fbef;
      color: #153704;
    }
    &.row-red {
      color: #7d2000;
      background: #fff7f0;
    }

    &.row-clickable {
      cursor: pointer;
    }
  }
`;

const TableLogs = (props) => {
  const { dataSource, loading, onChange, pagination } = props;

  const columns = useColumns();
  return (
    <TableLogsStyled>
      <TableResizable
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        onChange={onChange}
        pagination={pagination}
        rowClassName={(rowData) =>
          rowData.status_code === 200 ? "row-clickable  row-green" : "row-clickable  row-red"
        }
        expandable={{
          expandedRowRender: (record) => <ExpandedRow record={record} />,
          expandRowByClick: true,
          expandIconColumnIndex: 8,
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <UpOutlined onClick={(e) => onExpand(record, e)} />
            ) : (
              <DownOutlined onClick={(e) => onExpand(record, e)} />
            ),
        }}
        scroll={{ x: "1000px", y: "calc(100vh - 200px)" }}
      />
    </TableLogsStyled>
  );
};

export default TableLogs;
