import { formatCurrency } from "utils";

const en = {
  routeName: {
    home: "Home",
    stores: "Locations",
    QR: "Generate QR code",
    transactionHistory: "Transactions History",
    setting: "Settings",
    adminManage: "Users",
    logs: "Coin conversion logs",
    statistic: "Statistic",
    graphAnalytic: " Graph Analytic",
    globalFee: "Global fee settings",
    orders: "Orders",
    product: "Product",
    kocekOut: "Kocek Out",
    kocekIn: "Kocek In",
  },
  logOut: {
    label: "Log out",
    confirmTitle: "Log out confirmation",
    confirmMessage: "Are you sure you want to logout?",
  },
  openStore: {
    confirmTitle: "Online",
    confirmMessage:
      "Customers are able to visit registered stores for coins conversion based on chosen operation hours.",
  },
  closeStore: {
    confirmTitle: "Going offline",
    confirmMessage:
      "Going offline means customer can't come to you for coin conversion. Please confirm that you want to go offline",
  },
  confirmActiveStore: {
    title: "Activate a location",
    message:
      "An active location can login to the merchant website and accept coin conversion. Please confirm to activate",
  },
  confirmInactiveStore: {
    title: "Deactivate a location",
    message:
      "A deactivated location cannot login to the merchant website and cannot accept coin conversion. Please confirm to deactivate",
  },

  confirmEditTransaction: {
    title: "Update transaction data",
    message: "This will update the transaction data. Do you want to continue?",
  },

  statisticTb: {
    totalTransaction: "Total transaction",
    totalRM: "Total collection RM",
    totalCompleted: "Total transaction completed",
    RMCompleted: "Total RM completed",
    toBank: "Total transaction to bank",
    RMToBank: "Total RM to bank",
    toCharity: "Total transaction to charity",
    RMToCharity: "Total RM to charity",
    average: "Average RM per transaction",
    RMFee: "Total transaction fee",
    totalSales: "Total sales RM",
    totalServiceFee: "Total service fee RM",
  },

  address: "Address",
  postcode: "Postcode",
  state: "State",
  closed: "Closed",
  code: "Code",
  digit: "Digit",
  email: "Email",
  ic: "NRIC Number",
  phone: "Phone Number",
  endTime: "End time",
  goBack: "Go back",
  language: "Language",
  open: "Open",
  password: "Password",
  startTime: "Start time",
  storeID: "Location ID",
  storeEmail: "Location email",
  storeName: "Location Name",
  storePhone: "Location telephone number",
  storeLogo: "Location logo",
  selectFile: "Select file",
  storeAddress: "Location address",
  closeOn3rdSaturday: "Close on 3rd Saturday",
  closeOn3rdSaturdayToolTip: "Check if location is closed on 3rd Saturday every month",
  closeOnPublicHoliday: "Close on public holiday",
  closeOnPublicHolidayToolTip: "Check if location is closed on public holiday",
  voucherIsOn: "Voucher",
  voucherIsOnToolTip: "Check if voucher selection is visible",
  submit: "Submit",
  save: "Save",
  emailDescription: `An admin account will be created under this email.\nInvitation will be sent automatically for registration.`,
  emailSentNotification: (email) => `Invitation has been sent to ${email}.`,

  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",

  coinDeposit: "COIN CONVERSION",
  status: "STATUS",
  success: "SUCCESS",
  failed: "Failed",
  transactionId: "Transaction ID",
  clientFullName: "CUSTOMER FULL NAME",
  clientPhoneNumber: "CUSTOMER MOBILE PHONE NUMBER",
  timeCreated: "TIME CREATED",
  transactionFee: "TRANSACTION FEE",
  back: "BACK",
  depositAmount: "Payout Amount (RM)",
  voucherIncentive: "Voucher Incentive (RM)",
  amount: "Amount (RM)",
  confirmGenerateQrCode: (amount) => `Converting coins to RM${amount}. Generate QR code?`,
  pleaseInputAmountRM: "Enter the value of coins the customer wants to deposit. Eg: RM10.20",
  generateQrCode: "Generate QR code",
  expiresIn: "Expires in",
  qrCodeExpired: "QR code expired",
  regenerateQrCode: "Regenerate QR code",
  changeAmount: "Change amount",
  createTransactionSuccess: "Transaction started",
  depositTimestamp: "Conversion timestamp",

  id: "ID",
  phoneNumber: "Mobile phone number",
  transactionDate: "Transaction date",
  customerName: "Customer name",
  help: "Help",
  requestHelp: "Request help",
  timeAt: "Time at",
  dateRange: "Date range",
  updateSettingsSuccess: "Settings updated",
  resendEmail: "Resend email",
  emailVerifySent: "A 6-digit code has been sent to you via email. Enter it below",
  latitude: "Latitude",
  longitude: "Longitude",
  miniumAmountRequired: "Minimum amount required",
  locationSetting: "Location setting",
  needHelp: "Need help?",
  tooltipFindLatitude: "Find or enter latitude and longitude",
  createStore: "Create location",
  editStore: "Edit location",
  storeNotFound: "Store not found",
  backToListStore: "Back to location list",
  action: "Action",
  transferred: "Transfer completed",
  export: "Export",
  transactionDetail: "Transaction detail",
  sendTo: "Payout Method",
  charity: "Charity",
  voucher: "Voucher",
  bank: "Bank Transfer",
  bankAccountName: "Bank account holder name",
  bankAccountNumber: "Bank account number",
  transactionStatus: "Transaction status",
  bankName: "Bank name",
  search: "Search",
  searchUser: "Name, email address, NRIC, phone number",
  resetFilter: "Reset filter",
  login: "Login",
  verifyEmail: "Verify email address",
  changeOnlineHours: "Edit operating hours",
  clickToAddTimeSlot: "Click here to add another time slot for",
  clickHereToOnline: "Click here to go online",
  offline: "Offline",
  online: "Online",
  active: "Active",
  inactive: "Inactive",
  account: "Account",
  changePassword: "Change password",
  currentPassword: "Current password",
  newPassword: "New password",
  confirmNewPassword: "Confirm new password",
  passwordNotMatch: "New passwords do not match!",
  changePasswordSuccess: "Password changed successfully",
  forgotPassword: "Forgot password?",
  backToLogin: "Back to log in",
  forgotPass: {
    emailSent: "Check your email",
    message1: "An email has been sent to",
    message2: "Follow the directions in the email to reset your password",
    messageEnterMail: "Enter your email address. We'll send you a link to reset your password",
  },
  resetPassword: "Reset password",
  continue: "Continue",

  welcome: "Welcome",
  messCreateYourPassword: "Create your password so we can get it to work",
  createPasswordSuccess: "Password created successfully",
  loginToStart: "Let's login to start your work",
  createPassword: "Create password",
  createAdmin: "Add new user",
  fullName: "Full name",
  role: "Role",
  admin: "Admin",
  superAdmin: "Super admin",
  retailStaff: "Retail Staff",
  kioskLocation: "Kiosk Location",
  createSuccess: "Create successfully",
  sendInvitationEmail: "Send invitation email",
  objSendInvitation: {
    title: "Send invitation email to location",
    content: "You will send an invitation email. Do you want to continue?",
    success: "Email sent successfully",
    error1: "Unable to send email to",
    btnReSend: "Resend email",
    sendingEmail: "Sending invitation email",
  },
  objExportError: {
    title: "Data error",
    isDone: "Transaction DONE",
    isMissingData: "Missing data transaction",
    isConfirmed: "Transaction CONFIRMED",
  },
  wantToContinue: "Continue?",
  exportBulkPayment: "Export for Bulk Payment",
  requestBody: "Request Body",
  responseMessage: "Response message",
  statusCode: "Status code",
  api: "API",
  all: "All",
  createdAt: "Created at",
  adminNotFound: "Admin not found.",
  backToAdminManage: "Back to Admin management",
  confirmInactiveAdmin: {
    title: "Deactivate an admin account",
    message: "This account will be logged out from every device and will no longer be able to login again. Continue?",
  },
  confirmActiveAmin: {
    title: "Activate an admin account",
    message: "This account can login to this page. Continue?",
  },
  editAdmin: "Edit admin",
  updateSuccess: "Update successfully",
  waitingTransfer: "Waiting for transfer",
  coinReceived: "Coin received",
  paid: "Paid",
  requestHelpTitle: "REQUEST HELP FOR THIS TRANSACTION",
  locationOperationHours: "MyPay Coin Conversion Operating Hours",
  purchaseRequired: "Purchase required",
  purchaseRequiredToolTip:
    "Activate this option if a purchase at this location is required before coin conversion is allowed",
  alertPurchaseRequire: (amount) =>
    amount ? `Minimum RM${formatCurrency(amount)} purchase required` : "Purchase required",
  exportingFile: "Exporting file",
  statisticBy: "Statistic by",
  date: "Date",
  week: "Week",
  day: "Day",
  month: "Month",
  year: "Year",
  createdBy: "Created By",
  requireBankNumberAccountLength: (min, max) =>
    `Account number must be ${min === max ? `exactly ${min} digits` : `${min} to ${max} digits`}`,
  selectBank: "Select Bank",
  pleaseInputBankAccNo: "Please input bank account number",
  bankAccNoInvalid: "Bank account number is not valid",
  pleaseInputBankAccName: "Please input bank account holder name",
  bankAccNameInvalid: "Bank account name is not valid",
  lastEditByAdmin: "Last edited by admin",
  edited: "Edited",
  editBankInfo: "Edit bank info",
  logId: "Log ID",
  fee: "Fee",
  manageFees: "Manage fees",
  globalFee: "GLOBAL FEE",
  delete: "Delete",
  minRequiredCoinsToConvert: "Minimum conversion amount",
  feeValue: "Fee value",
  fixedAmount: "Fixed amount",
  percentage: "Percentage",
  type: "Type",
  addNewTier: "Add new tier",
  createNewGlobalFee: "Create new global fee",
  createAGlobalFee: "Create a global fee",
  feeName: "Fee name",
  tiers: "Tiers",
  cancel: "Cancel",
  selectFeeYouWantToApplyGlobally: "Select fee that you want to apply globally:",
  coinAmount: "Coin amount",
  coinAmountRM: "Coin Amount (RM)",
  serviceFee: "Service fee",
  applyFeeAsActiveGlobalFee: (feeName) => (
    <div>
      Apply <b>{feeName}</b> as active global fee?
    </div>
  ),
  allMerchantAreUsingGlobalFeeWillBeAffected: "All merchants are using global fee will be affected",
  minimumRequiredCoinsToBeConverted: "Minimum required coins to be converted",
  useGlobalFeeForThisLocation: "Use global fee for this location",
  thisMerchantWillUseGlobalFee: (locationName) => (
    <div>
      <b>{locationName}</b> will be using the Default Fee instead of the Custom Fee
    </div>
  ),
  applyGlobalFeeToThisLocation: "Apply global fee to this location?",
  applyFeeAsActiveOfThisLocation: (feeName) => (
    <div>
      Apply <b>{feeName}</b> as active fee to this location?
    </div>
  ),
  createNewCustomFee: "Create new custom fee",
  createACustomFee: "Create a custom fee",
  editGlobalFee: "Edit global fee",
  editFee: "Edit fee",
  orSelectACustomFeeYouWannaApply: "Use existing custom fee",
  feeAmount: "Fee amount (RM)",
  bookingUrl: "Booking URL",
  pendingBankApproval: "Pending bank approval",
  updateStatus: "Update status",
  updateTransactionStatus: "Update Transaction Status",
  amountTransferred: "Amount Transferred:",
  statusLabel: "Status:",
  newStatus: "New status:",
  transactionIdLabel: "Transaction ID:",
  pleaseSelect: "Please select",
  viewLogs: "View logs",
  other: "Other",
  description: "Description",
  pleaseInsertYourReason: "Please insert your reason",
  invalidAccountNumber: "Invalid account number",
  kiosk: "Kiosk",
  kioskType: "Kiosk type",
};

export default en;
