import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import get from "lodash/get";
import { useHistory } from "react-router-dom";
import { Badge } from "antd";
import TableResizable from "components/antdCustom/TableResizable";

const renderLocationColumn = (merchant) => {
  if (merchant) {
    const remainingLocations = merchant
      .slice(3)
      .map((item) => item.store_name)
      .join(", ");
    return merchant.map((item, index) => {
      const locationInitials = item.store_name
        .split(" ")
        .map(([initial]) => initial.toUpperCase())
        .join("");
      return (
        index < 4 && (
          <Badge
            count={index > 2 ? ` + ${merchant.length - 3}` : locationInitials}
            title={index > 2 ? remainingLocations : item.store_name}
            style={{ backgroundColor: "#ebfaff", color: "#6c757d" }}
          />
        )
      );
    });
  }
};

const getColumnsTranslated = (t, role) => [
  {
    title: t("id"),
    dataIndex: "id",
    key: "id",
    width: "80px",
  },
  {
    title: t("email"),
    dataIndex: "email",
    key: "email",
  },
  {
    title: t("fullName"),
    dataIndex: "full_name",
    key: "full_name",
  },
  {
    title: t("ic"),
    dataIndex: "ic",
    key: "ic",
  },
  {
    title: t("phone"),
    dataIndex: "phone",
    key: "phone",
  },
  ...(role === "retail_staff" || role === "admin"
    ? [
        {
          title: t("kioskLocation"),
          dataIndex: "merchant_id",
          key: "merchant_id",
          render: (merchant) => renderLocationColumn(merchant),
        },
      ]
    : []),
  {
    title: t("role"),
    dataIndex: "role",
    key: "role",
    render: (role = "") => role.toUpperCase(),
  },
  {
    title: t("active"),
    dataIndex: "is_active",
    key: "is_active",
    width: "90px",
    align: "center",
    render: (is_active = true) => (is_active ? <CheckOutlined /> : <CloseOutlined />),
  },
];

const TableAdminStyled = styled.div`
  .inactive {
    opacity: 0.3;
  }
  .row {
    cursor: pointer;
  }
`;

const TableAdmins = (props) => {
  const { dataSource, role, loading, onChange, pagination } = props;
  const history = useHistory();
  const { t } = useTranslation();

  const columns = useMemo(() => getColumnsTranslated(t, role), [t, role]);
  return (
    <TableAdminStyled>
      <TableResizable
        rowKey={(record) => record.salt}
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        onChange={onChange}
        pagination={pagination}
        scroll={{ y: "calc(100vh - 300px)" }}
        rowClassName={(admin) => {
          const is_active = get(admin, "is_active", true);
          if (is_active) return "row";
          return "row inactive";
        }}
        onRow={(record) => {
          return {
            onClick: () => {
              history.push({
                pathname: `/admins/${record.full_name}`,
                state: record,
              });
              // history.push('/admins/' + record.salt);
            },
          };
        }}
      />
    </TableAdminStyled>
  );
};

export default TableAdmins;
