import React, { useMemo } from "react";
import { notification, Empty, Layout } from "antd";
import { useTranslation } from "react-i18next";
import { LeftSquareOutlined, LoadingOutlined } from "@ant-design/icons";
import { useHistory, Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import useAPILoading from "hooks/useAPILoading";
import { updateAdmin, updateMerchant, sendInvitationToAdmin } from "APIService/admin";
import { sendInvitationToStore } from "APIService/store";
import FormAdminDetail from "./FormAdminDetail";
import stickyNotification from "components/antdCustom/notification/stickyNotification.js";

const { Content } = Layout;

const CenterPageStyle = styled.div`
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Header = styled(Content)`
  background: #fff;
  width: 100%;
  height: 7vh;
  font-size: 22px;
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 1;
`;

const EditAdmin = (props) => {
  // const id = get(props, 'match.params.id');
  const location = useLocation();
  const { is_active, id, salt, email, ...others } = location.state;
  const history = useHistory();
  const { t } = useTranslation();
  const admin = useMemo(() => {
    return { ...others, email, is_active: is_active === 1 ? true : false };
  }, [is_active, others, email]);
  const [callUpdateAdmin, loadingAdmin] = useAPILoading(updateAdmin);
  const [callUpdateMerchant, loadingMerchant] = useAPILoading(updateMerchant);

  const sendEmailNotification = (isMerchant, email) => {
    const closeNotification = stickyNotification.success({
      icon: <LoadingOutlined />,
      message: t("objSendInvitation.sendingEmail"),
      placement: "bottomRight",
      style: { background: "#ffeba8", color: "#7a6b2a" },
    });

    const emailSent = isMerchant ? sendInvitationToStore(email) : sendInvitationToAdmin(email);
    emailSent
      .then(() => {
        closeNotification();
        notification.success({
          message: t("objSendInvitation.success"),
          description: t("emailSentNotification")(email),
          duration: 7,
          placement: "bottomRight",
        });
      })
      .catch(() => {
        notification.error({
          message: t("objSendInvitation.sendEmailFail"),
          placement: "bottomRight",
        });
      })
      .finally(() => {
        history.goBack();
      });
  };

  // if (loadingGetAdmin)
  //   return (
  //     <CenterPageStyle>
  //       <Spin size='large' />
  //     </CenterPageStyle>
  //   );
  if (!admin)
    return (
      <CenterPageStyle>
        <Empty
          description={
            <span>
              {t("adminNotFound")} <Link to="/admins">{t("backToAdminManage")}</Link>
            </span>
          }
        ></Empty>
      </CenterPageStyle>
    );

  const onEditAdmin = (adminData) => {
    const { merchant_id, full_name, email: newEmail, is_active, ...others } = adminData;
    const name = full_name;

    if (merchant_id) {
      callUpdateMerchant(email, id, {
        ...others,
        phone_prefix: "+60",
        name,
        email: newEmail,
        merchant_id: typeof merchant_id[0] === "object" ? merchant_id.map((item) => item.value) : merchant_id,
        is_active: is_active === true ? 1 : 0,
        current_role: admin.role === "admin" && !admin.merchant_id ? "admin_without_merchant" : admin.role,
      }).then(() => {
        notification.success({
          message: t("updateSuccess"),
          placement: "bottomRight",
        });
        email !== newEmail && sendEmailNotification(true, newEmail);
        history.goBack();
      });
    } else {
      callUpdateAdmin(id, admin.email, {
        ...others,
        fullName: name,
        email: newEmail,
        phone_prefix: "+60",
        merchant_id,
        isActive: is_active,
        current_role:
          admin.role === "admin" && admin.merchant_id && admin.merchant_id.length > 0 ? "admin_merchant" : admin.role,
      }).then(() => {
        notification.success({
          message: t("updateSuccess"),
          placement: "bottomRight",
        });
        email !== newEmail && sendEmailNotification(false, newEmail);
        history.goBack();
      });
    }
  };

  return (
    <div>
      <Header>
        <div style={{ padding: "10px", marginLeft: "10px" }}>
          <Link to="/admins" style={{ color: "inherit", textDecoration: "inherit" }}>
            <LeftSquareOutlined />
          </Link>
          &nbsp; Edit User
        </div>
      </Header>
      <FormAdminDetail
        editMode
        value={admin}
        onSubmit={onEditAdmin}
        loading={loadingAdmin ? loadingAdmin : loadingMerchant}
      />
    </div>
  );
};

export default EditAdmin;
