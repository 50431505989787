import { Button, Radio } from "antd";
import React, { useMemo } from "react";
import styled from "styled-components";
import { IoMdRadioButtonOff, IoMdRadioButtonOn } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { formatRMAmount } from "src/utils";
import { FEE_TYPE } from "src/variables";
import { device } from "src/setting/device";
/**
 * @typedef {
 *  id: number,
 *  name: string,
 *  createdAt: Date,
 *  updatedAt: Date,
 *  createdBy: number,
 *  feeTiers: Array<{feeType: 'fixed' | 'percentage', minTierAmount: Number, feeValue: Number}>
 *  isActive: boolean,
 * } Fee
 */

const FeeListContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;

  .ant-radio-group {
    display: flex;
    flex: 1;
    flex-direction: column;
    @media ${device.laptop} {
      flex-wrap: wrap;
      flex-direction: row;
    }
    width: 100%;
    .fee-item {
      height: 300px;
      display: flex;
      padding: 10px;
      width: 100%;
      overflow-y: hidden;
      @media ${device.laptop} {
        min-width: 40%;
        max-width: 50%;
      }
    }
    margin: -10px;
  }
  .ant-radio-button-wrapper {
    flex: 1;
    width: 100%;
    color: #000 !important;
    border: 1px #000 solid;
    padding: 0;
    height: 100%;
    &:before {
      width: 0;
    }
  }
`;

/**
 * @param {object} props
 * @param {Array<Fee>} props.data
 * @param {(Fee)=>any} props.onActiveFee
 * @param {(id: number)=>any} props.onClickEdit
 * @returns
 */
export default function FeeList({ data, onActiveFee, onClickEdit }) {
  const activeFee = useMemo(() => {
    if (!data || !data.length) return null;
    return data.find((item) => item.isActive);
  }, [data]);

  if (!data || !data.length) return null;

  return (
    <FeeListContainer>
      <Radio.Group
        onChange={(e) => {
          if (onActiveFee) {
            const feeId = e.target.value;
            onActiveFee(data.find((item) => item.id === feeId));
          }
        }}
        value={activeFee && activeFee.id}
      >
        {data.map((fee) => {
          return (
            <div key={fee.id} className="fee-item">
              <Radio.Button value={fee.id}>
                <FeeItem
                  {...fee}
                  onClickEdit={() => {
                    onClickEdit(fee.id);
                  }}
                />
              </Radio.Button>
            </div>
          );
        })}
        {!!(data.length % 2) && <div className="fee-item" style={{ border: "none", background: "none" }} />}
      </Radio.Group>
    </FeeListContainer>
  );
}

const FeeItemContainer = styled.div`
  height: 100%;
  padding: 10px;
  display: flex;

  .container {
    display: flex;
    flex-wrap: wrap;
    margin: -5px;
    flex: 1;
    .content {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;

      .tiers-container {
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        margin: -10px;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 5px;
          background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-thumb {
          background: #606060;
          border-radius: 10px;
        }
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          border-radius: 10px;
          background-color: #f5f5f5;
        }

        .tiers-content {
          display: flex;
          flex: 1;
          flex-direction: column;
          padding: 10px;
          height: 100%;

          .tiers-header {
            display: flex;
          }

          .tiers {
            display: flex;
            width: 100%;
            flex-direction: column;
            height: 100%;

            .tier {
              display: flex;
              margin: -5px;
            }
          }
        }
      }
    }
  }
`;

/**
 * @param {Fee & {className: string, onClickEdit: ()=>any}} props
 */
const FeeItem = (props) => {
  const { name, feeTiers, isActive, className, onClickEdit } = props;
  const { t } = useTranslation();
  const sortedFeeTiers = [...feeTiers].sort((a, b) => a.minTierAmount - b.minTierAmount);
  const minRequiredAmount = sortedFeeTiers[0].minTierAmount;
  return (
    <FeeItemContainer className={className}>
      <div className="container">
        <div style={{ padding: 5, paddingTop: 10 }}>
          {isActive ? <IoMdRadioButtonOn size={20} color="#1890ff" /> : <IoMdRadioButtonOff size={20} />}
        </div>
        <div className="content" style={{ padding: 5 }}>
          <div style={{ fontSize: 20 }}>
            <span style={{ marginRight: 10 }}>{name}</span>
            {isActive && <span style={{ color: "green" }}>ACTIVE</span>}
          </div>
          <div>
            {t("minimumRequiredCoinsToBeConverted")}: {formatRMAmount(minRequiredAmount)}
          </div>
          <div className="tiers-container">
            <div style={{ padding: 10 }}>{t("tiers")}</div>
            <div className="tiers-content">
              <div className="tiers-header" style={{ margin: -5 }}>
                <div style={{ flex: 1, minWidth: "40%", padding: 5 }}>{t("coinAmount")}</div>
                <div style={{ flex: 1, minWidth: "40%", padding: 5 }}>{t("serviceFee")}</div>
              </div>
              <div className="tiers">
                {feeTiers.map((item, idx, array) => {
                  return <FeeTier {...item} nextTier={array[idx + 1]} className="tier" />;
                })}
              </div>
            </div>
          </div>
        </div>
        <Button type="link" style={{ padding: 5 }} onClick={onClickEdit}>
          Edit
        </Button>
      </div>
    </FeeItemContainer>
  );
};

const FeeTier = ({ feeType, minTierAmount, feeValue, nextTier, className }) => {
  const maxTierAmount = nextTier ? nextTier.minTierAmount - 0.01 : null;
  const range = maxTierAmount
    ? `${formatRMAmount(minTierAmount)} - ${formatRMAmount(maxTierAmount)}`
    : `${formatRMAmount(minTierAmount)} and above`;
  return (
    <div className={className}>
      <div style={{ flex: 1, minWidth: "40%", padding: 5 }}>{range}</div>
      <div style={{ flex: 1, minWidth: "40%", padding: 5 }}>
        {feeType === FEE_TYPE.FIXED ? formatRMAmount(feeValue) : `${feeValue * 100}%`}
      </div>
    </div>
  );
};
