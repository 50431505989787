import axios from "./axios";
import { GET_ORDER_LISTING } from "./APILinks";
import { apiCreateKocekOutToken } from "./utils";

export const fetchOrders = async ({
  merchants,
  order_type,
  date,
  order_status,
  payment_status,
  order_id_or_client_name,
  pageSize,
  page,
}) => {
  try {
    const token = await apiCreateKocekOutToken();
    return axios.post(
      GET_ORDER_LISTING,
      {
        merchants,
        order_type,
        date,
        order_status,
        payment_status,
        order_id_or_client_name,
        params: {
          page,
          pageSize,
        },
      },
      {
        headers: {
          Authorization: `${token.data.token_type} ${token.data.access_token}`,
        },
      }
    );
  } catch (e) {
    console.log(e);
  }
};
