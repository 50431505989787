import { Form, Button, Row, Col, Input, Select } from "antd";
import { useTranslation } from "react-i18next";
import { SearchOutlined, ReloadOutlined } from "@ant-design/icons";

import DateRangePicker from "components/antdCustom/DateRangePicker";
import { ACTIONS_MAP } from "./Table/constants";

const { Option } = Select;
const FilterBar = (props) => {
  const { t } = useTranslation();
  const { loading } = props;

  return (
    <Form layout="horizontal" initialValues={props.initialValues} onFinish={props.onSubmit}>
      <Row gutter={8}>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Form.Item name="dateRange">
            <DateRangePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={5}>
          <Form.Item name="transactionId">
            <Input placeholder={t("transactionId")} allowClear />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={5}>
          <Form.Item name="actions">
            <Select defaultValue={[]} placeholder={t("action")} mode="multiple">
              {Object.entries(ACTIONS_MAP).map(([key, value], idx) => (
                <Option key={idx.toString()} value={key}>
                  {value}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={12} sm={12} md={6} lg={5} xl={4}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "100%" }}
            loading={loading}
            icon={<SearchOutlined />}
          >
            {t("search")}
          </Button>
        </Col>
        <Col xs={12} sm={12} md={6} lg={5} xl={4}>
          <Form.Item shouldUpdate>
            {(form) => {
              return (
                <Button
                  block
                  onClick={() => {
                    const objectValues = form.getFieldsValue();
                    const resetObject = {};

                    for (let key in objectValues) {
                      resetObject[key] = undefined; // set all the value to undefined to reset
                    }
                    form.setFieldsValue(resetObject);
                  }}
                  icon={<ReloadOutlined />}
                >
                  {t("resetFilter")}
                </Button>
              );
            }}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default FilterBar;
