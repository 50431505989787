import React from "react";
import "./styled.css";
import { UserOutlined, WalletOutlined } from "@ant-design/icons";
import { format } from "date-fns";
import PageLoading from "components/PageLoading";
import { fpxLogo, duitNow } from "images";
import { Col, Row } from "antd";
import { getStatusText } from "../AllOrder/TableOrders";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobileAlt, faStore } from "@fortawesome/free-solid-svg-icons";

function SectionCard(props) {
  const { orders } = props;

  if (!orders) {
    return <PageLoading fullScreen />;
  }

  return (
    <>
      {orders?.data.map((order) => {
        const status = getStatusText(order.payment_details.payment_status);
        const orderStatus = getStatusText(order.order_status);
        return (
          <>
            <div className="row-header">
              <span>{format(new Date(order.order_created_on), "dd/MM/yyyy h:mm a")}</span>
              {/* <span>2:40PM</span> */}
              <div
                className="green-status"
                style={{
                  backgroundColor: status.backgroundColor,
                  color: status.textColor,
                }}
              >
                <span>{status.textStatus}</span>
              </div>
              <div
                className="green-status"
                style={{
                  backgroundColor: orderStatus.backgroundColor,
                  color: orderStatus.textColor,
                }}
              >
                <span>{orderStatus.textStatus}</span>
              </div>
            </div>
            <Row>
              <Col xs={24} lg={16}>
                <div className="section-card-left" key={order.order_id}>
                  <div className="card">
                    <div className="card-body">
                      <div className="card-title">
                        <div className="card-title-body">
                          <div>
                            <div className="title-text">Amount paid</div>
                            <div className="title-text-1">RM {order.payment_details.payment_total_amount}</div>
                          </div>
                          <div>
                            <div className="title-text">KOCEK Out amount</div>
                            <div className="title-text-1">RM {order.payment_details.payment_amount}</div>
                          </div>
                          <div>
                            <div className="title-text">Total item</div>
                            <div className="title-text-1">{order.order_details.length}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr className="full-line" />
                    <div className="card-body">
                      {order.order_details.map((detail, detailIndex) => (
                        <div className="card-title" key={detailIndex}>
                          <div className="card-title-body">
                            <div className="coin-container">
                              <div className="coin-image-container">
                                <img className="coin-img" alt="product" src={detail.product_image} />
                              </div>
                              <div className="text-container">
                                <div className="product-name">{detail.product_name}</div>
                                <div>RM {detail.product_price.toFixed(2)}/pack</div>
                              </div>
                            </div>
                            <div>
                              <div className="top-space">X {detail.product_quantity}</div>
                            </div>
                            <div>
                              <div className="top-space">RM {detail.product_amount}</div>
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className="line"></div>

                      <div className="card-title">
                        <div className="container-total">
                          <div className="content-total">
                            <div className="total-by-side">
                              <div className="total-inner">
                                <div className="total-item">
                                  KOCEK Out amount <span className="grey-text-13">(Subtotal)</span>
                                </div>
                                <div className="total-item">
                                  Service fee <span className="grey-text-13">(Inclusive 6% SST)</span>
                                </div>
                                <div className="bold-text total-item">Amount Paid</div>
                              </div>
                              <div className="total-inner">
                                <div className="total-item">RM {order.payment_details.payment_amount}</div>
                                <div className="total-item">RM {order.payment_details.payment_sst_charge_amount}</div>
                                <div className="bold-text total-item">
                                  RM {order.payment_details.payment_total_amount}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} lg={8}>
                <div className="section-card-right" key={order.order_id}>
                  <div className="card card-right card-body">
                    {order.order_type === "Mobile" ? (
                      <FontAwesomeIcon icon={faMobileAlt} color="#303030" />
                    ) : (
                      <FontAwesomeIcon icon={faStore} color="#303030" />
                    )}{" "}
                    {order.order_type === "Mobile" ? "Online" : order.order_type} Order
                  </div>
                  <div className="card">
                    <div className="card-right card-body">
                      <UserOutlined /> Customer
                    </div>
                    <hr className="full-line" />

                    <div className="card-body">
                      <div className="bottom-space">Personal details</div>
                      <div className="bottom-space">
                        <div className="grey-text-13">Name</div>
                        <div>{order.client_details.client_name}</div>
                      </div>
                      <div className="bottom-space">
                        <div className="grey-text-13">Email</div>
                        <div>{order.client_details.client_email}</div>
                      </div>
                      <div className="bottom-space">
                        <div className="grey-text-13">Phone number</div>
                        <div>(+60) {order.client_details.client_phone}</div>
                      </div>
                      <div className="line"></div>

                      <div className="bottom-space">Business details</div>
                      <div className="bottom-space">
                        <div className="grey-text-13">Business name</div>
                        <div>
                          {order.client_details.client_company_name ? order.client_details.client_company_name : "-"}
                        </div>
                      </div>
                      <div className="bottom-space">
                        <div className="grey-text-13">Business sector</div>
                        <div>
                          {order.client_details.client_company_industry
                            ? order.client_details.client_company_industry
                            : "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-right card-body">
                      <WalletOutlined /> Payment method
                    </div>
                    <hr className="full-line" />

                    <div className="card-body">
                      <div className="bottom-space grey-text-14">
                        {order.payment_details.payment_method_type === "fpx"
                          ? "Online banking"
                          : order.payment_details.payment_method_type === "qr"
                          ? "QR Payment"
                          : order.payment_details.payment_method_type.charAt(0).toUpperCase() +
                            order.payment_details.payment_method_type.slice(1)}
                      </div>
                      <div className="bottom-space">
                        {order.payment_details.payment_method_type === "fpx" ? (
                          <img src={fpxLogo} alt="" className="bank-logo" />
                        ) : order.payment_details.payment_method_type === "qr" ? (
                          <img src={duitNow} alt="" className="bank-logo" />
                        ) : null}
                      </div>
                      {order.payment_details.payment_method_type === "qr" ? (
                        <div>
                          <div className="bottom-space grey-text-14">Attachment</div>
                          <a href={order.payment_details.payment_receipt_url} target="_blank" rel="noopener noreferrer">
                            <span className="bottom-space url-text-13">{order.payment_details.payment_number}</span>
                          </a>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </>
        );
      })}
    </>
  );
}

export default SectionCard;
