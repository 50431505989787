import { Form, Input, Button, Select, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import has from "lodash/has";
import { SearchOutlined, ReloadOutlined } from "@ant-design/icons";

import DateRangePicker from "components/antdCustom/DateRangePicker";
import SelectTransferTo from "./SelectTransferTo";
import { useEffect, useState } from "react";
import useAPILoading from "hooks/useAPILoading";
import { fetchStores } from "APIService/store";

const isBankId = (value) => has(value, "bankId");

const FilterBar = (props) => {
  const { t } = useTranslation();
  const [storeList, setStoreList] = useState([]);
  const { loading } = props;

  const [getStore, loadingGetStore] = useAPILoading(fetchStores);

  useEffect(() => {
    getStore()
      .then(({ data }) => {
        const { data: storeListData } = data;
        if (storeListData) {
          const mappedStoreList = storeListData.map(({ store_name, id }) => ({
            value: `${id},${store_name}`,
            label: store_name,
          }));
          setStoreList(mappedStoreList);
        }
      })
      .catch((error) => console.error("Store API Error:", error));
  }, [getStore]);

  const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <Form layout="horizontal" initialValues={props.value} onFinish={props.onSubmit} style={{ paddingBottom: 24 }}>
      <div style={{ overflow: "hidden" }}>
        <Row gutter={8}>
          <Col xs={12} md={8} lg={6} xxl={4}>
            <Form.Item name="id" label={t("transactionId")} labelCol={{ span: 24 }}>
              <Input placeholder={t("transactionId")} allowClear />
            </Form.Item>
          </Col>
          <Col xs={12} md={8} lg={6} xxl={4}>
            <Form.Item name="clientName" label={t("customerName")} labelCol={{ span: 24 }}>
              <Input placeholder={t("customerName")} allowClear />
            </Form.Item>
          </Col>
          <Col xs={12} md={8} lg={6} xxl={4}>
            <Form.Item name="clientPhone" label={t("phoneNumber")} labelCol={{ span: 24 }}>
              <Input placeholder={t("phoneNumber")} allowClear />
            </Form.Item>
          </Col>
          <Col xs={12} md={8} lg={6} xxl={4}>
            <Form.Item name="clientEmail" label={t("email")} labelCol={{ span: 24 }}>
              <Input placeholder={t("email")} allowClear />
            </Form.Item>
          </Col>
          <Col xs={12} md={8} lg={6} xxl={4}>
            <Form.Item name="store_name" label={t("storeName")} labelCol={{ span: 24 }}>
              <Select
                showSearch
                allowClear
                placeholder={t("storeName")}
                options={storeList}
                loading={loadingGetStore}
                filterOption={filterOption}
              />
            </Form.Item>
          </Col>

          <Col xs={12} md={8} lg={6} xxl={4}>
            <Form.Item name="dateRange" labelCol={{ span: 24 }} label={t("transactionDate")}>
              <DateRangePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          {/* <Col xs={12} md={8} lg={6} xxl={4} xl={4}>
              <Form.Item name="transactionStatus">
                <Select placeholder={t("transactionStatus")} allowClear>
                  <Option value={TRANSACTION_STATUS.CONFIRMED}>{t("coinReceived")}</Option>
                  <Option value={TRANSACTION_STATUS.WAITING_FOR_TRANSFER}>{t("waitingTransfer")}</Option>
                  <Option value={TRANSACTION_STATUS.PENDING_BANK_APPROVAL}>{t("pendingBankApproval")}</Option>
                  <Option value={TRANSACTION_STATUS.DONE}>{t("paid")}</Option>
                  <Option value={TRANSACTION_STATUS.FAILED}>{t("failed")}</Option>
                </Select>
              </Form.Item>
            </Col> */}
          <Col xs={12} md={8} lg={6} xxl={4}>
            <Form.Item name="transferTo" label={t("sendTo")} labelCol={{ span: 24 }}>
              <SelectTransferTo />
            </Form.Item>
          </Col>

          <Form.Item noStyle dependencies={["transferTo"]}>
            {(form) => {
              const transferTo = form.getFieldValue("transferTo");

              if (isBankId(transferTo))
                return (
                  <Col xs={12} md={8} lg={6} xxl={4}>
                    <Form.Item name="bankAccountNumber" label={t("bankAccountNumber")} labelCol={{ span: 24 }}>
                      <Input placeholder={t("bankAccountNumber")} allowClear />
                    </Form.Item>
                  </Col>
                );

              return null;
            }}
          </Form.Item>
        </Row>
        {/* <Row gutter={8}>
          </Row> */}

        <Row gutter={8}>
          <Col className="gutter-row" xs={12} md={8} lg={6} xxl={4}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
              loading={loading}
              icon={<SearchOutlined />}
            >
              {t("search")}
            </Button>
          </Col>
          <Col className="gutter-row" xs={12} md={8} lg={6} xxl={4}>
            <Form.Item noStyle shouldUpdate>
              {(form) => {
                return (
                  <Button
                    style={{ width: "100%" }}
                    onClick={() => {
                      const objectValues = form.getFieldsValue();
                      const resetObject = {};

                      for (let key in objectValues) {
                        resetObject[key] = undefined; // set all the value to undefined to reset
                      }
                      form.setFieldsValue(resetObject);
                    }}
                    icon={<ReloadOutlined />}
                  >
                    {t("resetFilter")}
                  </Button>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default FilterBar;
