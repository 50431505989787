import React, { useState, useEffect } from "react";
import { Layout } from "antd";
import get from "lodash/get";
import isEqual from "lodash/isEqual";

import FilterBar from "./components/FilterBar";
import TableTransactions from "./components/TableStores";
import { fetchStores } from "APIService/store";
import useAPILoading from "hooks/useAPILoading";
import useRouterQuery from "hooks/useRouterQuery";
import usePrevious from "hooks/usePrevious";
import getPaginationParams from "utils/getPaginationParams";
import styled from "styled-components";

const { Content } = Layout;

const Container = styled(Content)`
  padding: 20px;
  .group {
    background: #fff;
    padding: 20px;
  }
`;

const StoresPage = (props) => {
  const [getStore, loading] = useAPILoading(fetchStores);
  const [params, setParams] = useRouterQuery({
    pagination: { current: 1, pageSize: 10 },
    filter: {},
  });

  const prevParams = usePrevious(params);

  const [resData, setResData] = useState();

  useEffect(() => {
    if (!isEqual(prevParams, params))
      getStore(getPaginationParams(params)).then(({ data }) => {
        setResData(data);
      });
  }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTableChange = (pagination = {}) => {
    const { current, pageSize } = pagination;
    setParams({ ...params, pagination: { current, pageSize } });
  };

  const handleFilterChange = (filter) => {
    let { pagination } = params;
    pagination = { ...pagination, current: 1 };
    //set page to 1 when change filter

    setParams({ ...params, filter, pagination });
  };

  return (
    <Container>
      <div className="group">
        <FilterBar initialValues={params.filter} onFinish={handleFilterChange} loading={loading} />
        <TableTransactions
          dataSource={get(resData, "data")}
          pagination={{ ...params.pagination, total: get(resData, "total") }}
          loading={loading}
          onChange={handleTableChange}
        />
      </div>
    </Container>
  );
};

export default StoresPage;
