import moment from "moment";
import get from "lodash/get";

import packageJson from "../../package.json";

export const workingTimeFormat = "HH:mm";

export const defaultWorkingTime = {
  startTime: moment("08:00", workingTimeFormat),
  endTime: moment("17:00", workingTimeFormat),
};

const defaultDayWorking = {
  shifts: [],
};

export const defaultOpenDay = {
  mon: defaultDayWorking,
  tue: defaultDayWorking,
  wed: defaultDayWorking,
  thu: defaultDayWorking,
  fri: defaultDayWorking,
  sat: defaultDayWorking,
  sun: defaultDayWorking,
};

export const SETTING_AVAILABILITY = {
  AVAILABLE: "1",
  UNAVAILABLE: "0",
};

export const phoneCountryCode = ["+60", "+62", "+63", "+65", "+66", "+84", "+95", "+670", "+673", "+855", "+856"];

export const VERSION = get(packageJson, "version", "undefined");

export const FEE_TYPE = {
  FIXED: "fixed",
  PERCENTAGE: "percentage",
};

export const FEE_SCOPE = {
  GLOBAL: "global",
  CUSTOMIZED: "customized",
};
