export const STATUS_TEXT = {
    WAITING_FOR_TRANSFER: {
        textStatus: "Waiting for transfer",
        textColor: "#837300",
        backgroundColor: "#FFF5AD",
    },
    PENDING_BANK_APPROVAL: {
        textStatus: "Pending bank approval",
        textColor: "#3FBAFF",
        backgroundColor: "#3FBAFF1A",
    },
    FAILED: {
        textStatus: "Failed",
        textColor: "#D9534F",
        backgroundColor: "#F7DDDC",
    },
    DONE: {
        textStatus: "Paid",
        textColor: "#39B55D",
        backgroundColor: "#E9FFF9",
    },
};
