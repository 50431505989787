import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";

const UserCreateButton = ({ initialValue, onSubmit, loading }) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        marginBottom: 25,
      }}
    >
      <Link to="/admins/create">
        <Button type="primary" icon={<PlusOutlined />}>
          {t("createAdmin")}
        </Button>
      </Link>
    </div>
  );
};

export default UserCreateButton;
