import { useEffect, useState } from "react";
import { Form, Button, Select, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { SearchOutlined } from "@ant-design/icons";
import debounce from "lodash/debounce";

import DateRangePicker from "components/antdCustom/DateRangePicker";
import { fetchStores } from "APIService/store";
import useAPILoading from "hooks/useAPILoading";
import { useStatisticByOptions, mapDateRangePickerType } from "./constant";

const FilterBar = ({ initialValues, onFinish, loading }) => {
  const { t } = useTranslation();
  const [stores, setStores] = useState([{ label: "all", value: -1 }]);
  const [getStore, loadingGetStore] = useAPILoading(fetchStores);

  const statisticByOptions = useStatisticByOptions();

  const handleSearchStore = debounce((value) => {
    getStore({ filter: { store_name: value } }).then(({ data }) => {
      const { data: storeList } = data;
      if (storeList)
        setStores(
          [
            { label: "all", value: -1 },
            storeList.map(({ store_name, address, id }) => ({
              value: id,
              label: `${store_name} (${address})`,
            })),
          ].flat()
        );
      else setStores([{ label: "all", value: -1 }]);
    });
  }, 500);

  useEffect(() => {
    getStore().then(({ data }) => {
      const { data: storeList } = data;
      if (storeList)
        setStores(
          [
            { label: "all", value: -1 },
            storeList.map(({ store_name, address, id }) => ({
              value: id,
              label: `${store_name} (${address})`,
            })),
          ].flat()
        );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form layout="horizontal" initialValues={initialValues} onFinish={onFinish}>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          margin: -10,
          flexWrap: "wrap",
        }}
      >
        <Form.Item
          name="storeId"
          label={t("storeName")}
          style={{ flex: 1, padding: 10, minWidth: 300 }}
          normalize={(value) => (value !== -1 ? value : undefined)}
        >
          <Select
            showSearch
            defaultValue={-1}
            options={stores}
            onSearch={handleSearchStore}
            loading={loadingGetStore}
            notFoundContent={loadingGetStore ? <Spin size="small" /> : null}
            filterOption={false}
            placeholder="all"
          />
        </Form.Item>
        <div
          style={{
            padding: 10,
            margin: -10,
            display: "flex",
          }}
        >
          <Form.Item dependencies={["statisticBy"]} noStyle>
            {(form) => {
              return (
                <Form.Item
                  label={t("statisticBy")}
                  name="statisticBy"
                  rules={[{ required: true }]}
                  style={{ padding: 10 }}
                >
                  <Select
                    style={{ width: 100 }}
                    options={statisticByOptions}
                    onChange={() => {
                      form.setFieldsValue({ dateRange: null });
                    }}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item dependencies={["statisticBy"]} noStyle>
            {(form) => {
              const statisticBy = form.getFieldValue("statisticBy");

              return (
                <Form.Item
                  label={t("dateRange")}
                  name="dateRange"
                  rules={[{ required: true, message: t("error:dateRange") }]}
                  style={{ padding: 10 }}
                >
                  <DateRangePicker picker={mapDateRangePickerType[statisticBy]} />
                </Form.Item>
              );
            }}
          </Form.Item>
        </div>
      </div>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading} icon={<SearchOutlined />}>
          {t("search")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FilterBar;
