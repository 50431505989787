import CSVStringify from "csv-stringify";
import get from "lodash/get";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

const formatDataToExport = (data = [], columns = []) => {
  const CSVColumns = columns.filter((column) => {
    const { showOnCSV = true } = column;
    return showOnCSV;
  });

  //format body
  const bodyFormatted = data.map((dataRow) =>
    CSVColumns.map((column) => {
      const { dataIndex, render } = column;
      let value = dataIndex ? get(dataRow, dataIndex) : dataRow;

      if (render) {
        value = render(value);
      }

      return value;
    })
  );

  //add header
  const header = CSVColumns.map((column) => column.title);
  return [header, ...bodyFormatted];
};

export const exportCSV = (data, columns, exportedFileName = "fileName") =>
  new Promise((resolve, reject) => {
    const exportData = formatDataToExport(data, columns);
    CSVStringify(exportData, function (err, csvString) {
      if (err) {
        return reject(err);
      }

      //set separator charset => to correct display on excel
      csvString = "sep=,\n" + csvString;

      var blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFileName + ".csv");
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFileName + ".csv");
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
      resolve();
    });
  });

export const exportXLSX = (data, columns, fileName = "fileName") => {
  const exportData = formatDataToExport(data, columns);

  const ws = XLSX.utils.json_to_sheet(exportData, { skipHeader: true });
  const wb = { Sheets: { "sheet 1": ws }, SheetNames: ["sheet 1"] };

  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const excelData = new Blob([excelBuffer], { type: "xlsx" });
  saveAs(excelData, fileName + ".xlsx");
};

export default function exportFile(data, columns, fileName, fileType = "xlsx") {
  switch (fileType) {
    case "csv":
      return exportCSV(data, columns, fileName);
    case "xlsx":
    default:
      return exportXLSX(data, columns, fileName);
  }
}
