export const ACTIONS_MAP = {
  ADMIN_UPDATE_TRANSACTION_STATUS: "Admin updated transaction status",
  ADMIN_CREATE_ADMIN: "Admin created admin",
  CLIENT_CHOOSE_SEND_MONEY_TO_BANK: "Client chose to send money to bank",
  CLIENT_CHOOSE_SEND_MONEY_TO_CHARITY: "Client chose to send money to charity",
  MERCHANT_REQUEST_HELP: "Merchant requested help",
  CLIENT_CONFIRM_TRANSACTION: "Client confirmed transaction",
  ADMIN_CREATE_MERCHANT: "Admin created merchant",
  ADMIN_EDIT_MERCHANT: "Admin edited merchant",
  ADMIN_EDIT_ADMIN: "Admin edited admin",
  ADMIN_UPDATE_TRANSACTION_BANK_INFO: "Admin updated transaction bank info",
  ADMIN_CREATE_GLOBAL_FEE: "Admin create global fee",
  ADMIN_CREATE_CUSTOMIZED_FEE: "Admin created customized fee",
  ADMIN_EDIT_FEE: "Admin edit fee",
  ADMIN_SET_MERCHANT_ACTIVE_FEE: "Admin set merchant active fee",
  ADMIN_SET_GLOBAL_ACTIVE_FEE: "Admin set global active fee",
};
