import get from "lodash/get";

export const exportColumns = [
  {
    title: "Beneficiary Name",
    dataIndex: "transaction_detail_bank_account_name",
  },
  {
    title: "Beneficiary ID",
    dataIndex: "client_ic",
  },
  {
    title: "BNM Code",
    render: (data) => {
      const { bnm_code, bank_name } = data;
      if (!bnm_code || !bank_name) return "";
      return `${data.bnm_code}-${data.bank_name}`;
    },
  },
  {
    title: "Account Number",
    dataIndex: "transaction_detail_bank_account_number",
  },
  {
    title: "Payment Amount",
    dataIndex: "amount",
  },
  {
    title: "Reference Number",
    render: () => "FUND TRANSFER",
  },
  {
    title: "Payment Description",
    dataIndex: "id",
    render: (id) => "CoinConversion" + id,
  },
];

export const validateData = (listSelectedData = [], t) => {
  //transaction has status Done
  const isDone = listSelectedData.some((dataRow) => get(dataRow, "status") === "DONE");

  //unfinish transaction
  const isConfirmed = listSelectedData.some((dataRow) => get(dataRow, "status") === "CONFIRMED");

  //transaction missing data
  const isMissingData = listSelectedData.some(
    (dataRow) => get(dataRow, "status") !== "CONFIRMED" && !get(dataRow, "bnm_code")
  );

  const errors = [
    isDone && t("objExportError.isDone"),
    isConfirmed && t("objExportError.isConfirmed"),
    isMissingData && t("objExportError.isMissingData"),
  ].filter(Boolean);

  return errors;
};
