import styled from "styled-components";

export const ProductListingStyled = styled.div`
  .product-listing-card {
    background-color: white;
    margin: 20px;
    margin-bottom: 30px;
  }
  .product-listing {
    padding: 15px 12px;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    display: flex;
  }
  .product-image-container {
    padding: 8px;
    background: #ececec;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: flex;
  }
  .product-details {
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    display: flex;
  }
  .product-image {
    width: 97px;
    height: 59px;
    padding: 0 19px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .product-image > img {
    width: 100%;
    height: 100%;
  }
  .product-name {
    color: #303030;
    font-size: 18px;
    font-weight: 700;
    word-wrap: break-word;
  }
  .product-remark {
    padding: 4px;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: flex;
  }
  .product-remark-text {
    color: white;
    font-size: 10px;
    font-weight: 500;
    word-wrap: break-word;
  }
  .stock-status {
    border-radius: 100px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 6px 16px;
    font-size: 12px;
    font-weight: 500;
    word-wrap: break-word;
  }
  .update-button {
    padding: 8px 16px;
    background-color: white; /* You should specify a color here */
    color: #505050;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    cursor: pointer;
    height: 40px;
  }
  .ant-card-body {
    padding: 0;
  }
  .ant-table-thead > tr > th {
    color: #6c757d;
  }
  table {
    width: auto;
    min-width: unset !important;
  }
  .summary-details {
    flex: 1 1 0;
    align-self: stretch;
    padding: 30px 20px 40px;
    background: white;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: 1px solid #ececec;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
    display: flex;
  }
  .summary-details-title {
    color: #303030;
    font-size: 14px;
    font-weight: 400;
    word-wrap: break-word;
  }
  .summary-details-text {
    color: #303030;
    font-size: 20px;
    font-weight: 700;
    word-wrap: break-word;
  }
`;

export const SetStockStyled = styled.div`
  .update-stock-main {
    color: #303030;
    font-size: 20px;
    font-weight: 700;
    word-wrap: break-word;
  }
  .ant-input-disabled {
    color: #505050;
  }
  .product-info {
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    display: inline-flex;
  }
  .product-name {
    color: #303030;
    font-size: 16px;
    font-weight: 600;
    word-wrap: break-word;
  }
  .product-remark-badge {
    padding: 4px;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: flex;
  }
  .product-remark {
    color: white;
    font-size: 10px;
    font-weight: 500;
    word-wrap: break-word;
  }
  .form-group {
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    display: inline-flex;
    margin-top: 24px;
  }
  .form-item {
    flex: 1 1 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
    display: inline-flex;
  }
  input.ant-input {
    height: 36px;
  }
  .decreaseButton {
    height: 36px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .increaseButton {
    height: 36px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .tnc {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    display: inline-flex;
    margin-bottom: 40px;
  }
  .tnc-text {
    margin: 0;
    color: #303030;
    font-size: 14px;
    font-weight: 400;
  }
  .tnc-checkbox {
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 3px;
    padding-bottom: 3px;
    background: white;
  }
  .btn-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .cancel-button {
    border: none;
  }
  .reset-button {
    margin-right: 8px;
  }
  .stock-warning {
    margin-bottom: 24px;
  }
  .stock-input {
    text-align: center;
    border-radius: 0;
    border-right: 0;
    border-left: 0;
  }
  .stock-warning-text {
    margin-bottom: 24px;
    width: 100%;
    color: #d9534f;
    font-size: 12px;
    font-weight: 400;
    word-wrap: break-word;
  }
  .stock-warning-icon {
    color: #e5a54b;
    margin-right: 10px;
    font-weight: 900;
    line-height: 18.2px;
    word-wrap: break-word;
  }
  .stock-warning-message {
    flex: 1 1 0;
    color: #505050;
    font-size: 14px;
    font-weight: 400;
    word-wrap: break-word;
    height: 40px;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
  }
  .stock-warning-details {
    align-self: stretch;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 8px;
    display: inline-flex;
  }
`;
