import { React, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Button, Input, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import useAPILoading from "hooks/useAPILoading";
import { fetchStores } from "APIService/store";

// import { Link } from 'react-router-dom';
const { Option } = Select;
const FilterBar = ({ initialValue, onSubmit, onReset, loading, role }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [storeList, setStoreList] = useState([]);
  const [getStore, loadingGetStore] = useAPILoading(fetchStores);

  useEffect(() => {
    form.resetFields();
    if (role === "retail_staff" || role === "admin") {
      getStore()
        .then(({ data }) => {
          const { data: storeListData } = data;
          if (storeListData) {
            const mappedStoreList = storeListData.map(({ store_name, id }) => ({
              value: id,
              label: store_name,
            }));
            setStoreList(mappedStoreList);
          }
        })
        .catch((error) => console.error("Store API Error:", error));
    }
  }, [getStore, role, form]);

  return (
    <Form form={form} onFinish={onSubmit} layout="horizontal">
      <div
        style={{
          display: "flex",
          width: role === "retail_staff" || role === "admin" ? "100%" : "75%",
          // justifyContent: 'space-between',
          alignItems: "flex-start",
          margin: -10,
          flexWrap: "wrap",
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        <Form.Item
          name="search"
          label={t("search")}
          style={{ flex: 1, padding: 10, maxWidth: 400 }}
          // normalize={(value) => (value !== -1 ? value : undefined)}
        >
          <Input placeholder={t("searchUser")} />
        </Form.Item>
        <Form.Item
          name="status"
          label={t("statusLabel")}
          style={{ flex: 1, padding: 10, maxWidth: 200 }}
          // normalize={(value) => (value !== -1 ? value : undefined)}
        >
          <Select
          // onChange={ }
          // defaultValue={''}
          >
            <Option value="">{t("all")}</Option>
            <Option value="1">{t("active")}</Option>
            <Option value=" 0">{t("inactive")}</Option>
          </Select>
        </Form.Item>
        {(role === "retail_staff" || role === "admin") && (
          <Form.Item
            name="location"
            label={t("kioskLocation")}
            style={{ flex: 1, padding: 10, maxWidth: 400 }}
            // normalize={(value) => (value !== -1 ? value : undefined)}
          >
            <Select mode="multiple" placeholder="Select kiosk location" options={storeList} loading={loadingGetStore} />
          </Form.Item>
        )}
        <Form.Item style={{ paddingTop: 10 }}>
          <Button type="primary" htmlType="submit" loading={loading} icon={<SearchOutlined />}>
            {t("search")}
          </Button>
        </Form.Item>
        &nbsp;
        <Form.Item style={{ paddingTop: 10 }}>
          <Button
            type="default"
            onClick={() => {
              form.resetFields();
              onReset();
            }}
          >
            {t("resetFilter")}
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};

export default FilterBar;
