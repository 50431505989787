import React, { useMemo } from "react";
import { Table as TableAntd } from "antd";

const PAGE_SIZE_OPTIONS_DEFAULT = [10, 20, 50, 100];

const Table = ({ pagination, ...restProps }) => {
  const paginationCustom = useMemo(() => {
    const { pageSizeOptions = PAGE_SIZE_OPTIONS_DEFAULT, total } = pagination;
    let lastPageSizeIndx = pageSizeOptions.length;
    pageSizeOptions.every((pageSize, idx) => {
      if (pageSize >= total) {
        lastPageSizeIndx = idx;
        return false;
      }
      return true;
    });
    return {
      ...pagination,
      showSizeChanger: true,
      pageSizeOptions: pageSizeOptions.slice(0, lastPageSizeIndx),
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    };
  }, [pagination]);
  return <TableAntd pagination={paginationCustom} {...restProps} />;
};

export default Table;
