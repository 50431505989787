import { URIAdmin } from "./APILinks";
import axios from "./axios";

/**
 * @typedef {object} Tier
 * @property {number} minTierAmount
 * @property {'fixed' | 'percentage'} feeType
 * @property {number} feeValue
 *
 * @param {object} params
 * @param {string} params.name
 * @param {number} params.merchantId
 * @param {Array<Tier>} params.tiers
 * @returns {Promise<{data: {
 *  feeId: number
 * }}>}
 * - HTTP 400 with { message: 'minTierAmount can not be duplicated' }
 * - HTTP 400 with {
 *    message: 'Invalid input',
 *    tiers: Tier&{
 *      error: null | 'feeValue must be in the range [0, 1]' | 'Service fee or transferred amount can be lower than RM0.01' | 'Transferred amount can be lower than RM0.01'
 *   }[]
 * }
 */
export const createFee = ({ name, tiers, merchantId = null }) => {
  return axios.post(`${URIAdmin}/fees`, { name, tiers, merchantId });
};

/**
 * @param {number} merchantId
 * @returns {Promise<{data: Array<{
 *  id: Number,
 *  merchantId: Number,
 *  name: String,
 *  feeTiers: Array<{minTierAmount: number, feeValue: number, feeType: 'fixed' | 'percentage' }>,
 *  isActive: Boolean,
 *  createdAt: Date,
 *  updatedAt: Date,
 *  createdBy: Number,
 * }>}>}
 */
export const getFees = (merchantId) => {
  return axios.get(`${URIAdmin}/fees`, { params: { merchantId } });
};

/**
 * @param {number} merchantId
 * @returns {Promise<{data: {
 *  id: Number,
 *  merchantId: Number,
 *  name: String,
 *  tiers: Array<{minTierAmount: number, feeValue: number, feeType: 'fixed' | 'percentage' }>,
 *  updatedAt: String,
 * }>}
 */
export const getFee = (feeId) => {
  return axios.get(`${URIAdmin}/fees/${feeId}`);
};

/**
 * @param {number} feeId
 * @param {{
 *  updatedAt: String,
 *  name: String,
 *  merchantId: null | String,
 *  tiers: Array<{minTierAmount: number, feeValue: number, feeType: 'fixed' | 'percentage' }>
 * }} body
 *
 * @returns {Promise<{data: {
 *  feeId: Number,
 *  name: String,
 *  feeTiers: Array<{minTierAmount: number, feeValue: number, feeType: 'fixed' | 'percentage' }>,
 *  updatedAt: String,
 * }>}}
 * - HTTP 400 with { message: 'minTierAmount can not be duplicated' }
 * - HTTP 400 with {
 *    message: 'Invalid input',
 *    tiers: Tier&{
 *      error: null | 'feeValue must be in the range [0, 1]' | 'Service fee or transferred amount can be lower than RM0.01' | 'Transferred amount can be lower than RM0.01'
 *   }[]
 * }
 */
export const editFee = (feeId, body) => {
  return axios.put(`${URIAdmin}/fees/${feeId}`, body);
};
