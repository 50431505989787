import { useMemo } from "react";
import { get, reduce } from "lodash";
import { useTranslation } from "react-i18next";

export const typeStatisticBy = {
  daily: "DAILY",
  monthly: "MONTHLY",
};

export const useStatisticByOptions = () => {
  const { t } = useTranslation();
  return useMemo(
    () => [
      {
        label: t("day"),
        value: typeStatisticBy.daily,
      },
      {
        label: t("month"),
        value: typeStatisticBy.monthly,
      },
    ],
    [t]
  );
};

export const mapDateRangePickerType = {
  [typeStatisticBy.daily]: "date",
  [typeStatisticBy.monthly]: "month",
};

/**
 * this function will loop through an array then return the sum of arrayItem[key]
 * @param {array} arrayInput
 * @param {string} key - key to get data from
 * @returns {(number|string)}
 */
export const sumTotal = (arrayInput, key) =>
  reduce(arrayInput, (accumulator, currentValue) => accumulator + Number(get(currentValue, key, 0)), 0);
