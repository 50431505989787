import React, { useMemo } from "react";
import moment from "moment";
import Table from "src/components/antdCustom/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobileAlt, faStore } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { Badge } from "antd";
import { useTranslation } from "react-i18next";

const renderLocationColumn = (merchant) => {
  if (merchant) {
    const locationInitials = merchant.store_name
      .split(" ")
      .map(([initial]) => initial.toUpperCase())
      .join("");
    return (
      <Badge
        count={locationInitials}
        title={merchant.store_name}
        style={{ backgroundColor: "#ebfaff", color: "#6c757d" }}
      />
    );
  }
};

const getColumnsTranslated = (t, getOrderDetails) => [
  {
    title: "Order ID",
    key: "order_id",
    fixed: "left",
    render: (record) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ width: 15, textAlign: "center", marginRight: 10 }}>
          {record.order_type === "Kiosk" ? (
            <FontAwesomeIcon icon={faStore} color="#909090" />
          ) : (
            <FontAwesomeIcon icon={faMobileAlt} color="#909090" />
          )}
        </div>
        <p
          style={{
            fontSize: 14,
            marginBottom: 0,
            color: record.payment_status === "Cancelled" ? "#D9534F" : "#1551B0",
            textDecorationLine: "underline",
            cursor: "pointer",
          }}
          onClick={() => getOrderDetails(record.order_id)}
        >
          #{record.order_id}
        </p>
      </div>
    ),
    width: "150px",
  },
  {
    title: "Date",
    dataIndex: "order_created_on",
    key: "order_created_on",
    render: (value) => moment(value).format("DD/MM/YYYY"),
    width: "150px",
  },
  {
    title: "Location",
    dataIndex: "merchant",
    key: "merchant",
    render: (merchant) => renderLocationColumn(merchant),
  },
  {
    title: "Customer",
    key: "client_full_name",
    render: (record) => (
      <p
        style={{
          marginBottom: 0,
          color: record.payment_status === "Cancelled" ? "#D9534F" : "#1551B0",
        }}
      >
        {record.client_details.client_name}
      </p>
    ),
    width: "200px",
  },
  {
    title: "KOCEK Out amount (RM)",
    key: "order_amount",
    render: (record) =>
      record.payment_details.payment_amount &&
      record.payment_details.payment_amount.toFixed &&
      record.payment_details.payment_amount.toFixed(2),
    width: "200px",
  },
  {
    title: "Service fee (RM)",
    key: "total_amount",
    render: (record) =>
      record.payment_details.payment_sst_charge_amount &&
      record.payment_details.payment_sst_charge_amount.toFixed &&
      record.payment_details.payment_sst_charge_amount.toFixed(2),
  },
  {
    title: "Amount paid (RM)",
    key: "total_amount",
    render: (record) =>
      record.payment_details.payment_total_amount &&
      record.payment_details.payment_total_amount.toFixed &&
      record.payment_details.payment_total_amount.toFixed(2),
  },
];

const getOrderDetails = (history, orderId) => {
  const url = `/withdrawal/transactions/${orderId}`;
  history.push(url, { orderId });
};

const TableFilteredOrders = (props) => {
  const { dataSource, loading, onChange, pagination, category } = props;
  const history = useHistory();
  const { t } = useTranslation();
  // Need to useCallback to prevent the warning of re-rendering of the sendReceiptViaEmail function on every render by the useMemo

  const columns = useMemo(() => {
    const arr = getColumnsTranslated(t, (orderId) => getOrderDetails(history, orderId));
    if (category === "completed") arr.pop();
    return arr;
  }, [category, history, t]);

  return (
    <>
      <Table
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        onChange={onChange}
        pagination={pagination}
        scroll={{ x: 1300, y: "calc(100vh - 320px)" }}
        onRow={(record) => {
          return {
            onClick: () => getOrderDetails(history, record.order_id),
            style: { cursor: "pointer" },
          };
        }}
      />
    </>
  );
};

export default TableFilteredOrders;
