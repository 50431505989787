import "antd/dist/antd.css";
import { useMemo } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import {
  HomeOutlined,
  UnorderedListOutlined,
  ShopOutlined,
  UserOutlined,
  HistoryOutlined,
  LineChartOutlined,
  DollarOutlined,
  BarChartOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import Home from "pages/Home";
import CreateStore from "pages/stores/create";
import EditStore from "pages/stores/[id]";
import Stores from "pages/stores";
import Transactions from "pages/Transactions";
import ChangePassword from "pages/ChangePassword";
import AdminsPage from "pages/Admins";
import CreateAdminPage from "pages/AdminDetail";
import EditAdmin from "pages/AdminDetail/Edit";
import CoinConversionLog from "pages/CoinConversionLog";
import TransactionsStatistic from "pages/TransactionsStatistic";
import GraphAnalytic from "src/pages/GraphAnalytic";
import Layout from "components/Layout";
import { userInfoSelector } from "reduxStore/auth/selectors";
import GlobalFee from "src/pages/global-fee";
import CreateGlobalFee from "src/pages/global-fee/create";
import StoreFeeList from "src/pages/stores/[id]/fee-list";
import CreateCustomizedFee from "src/pages/stores/[id]/fee-list/create";
import EditGlobalFee from "src/pages/global-fee/[id]";
import EditFee from "src/pages/stores/[id]/fee-list/[id]";
import OrderListing from "src/pages/CoinWithdrawal/OrderListing";
import OrderDetails from "src/pages/CoinWithdrawal/OrderListing/OrderDetails";
import ProductListing from "src/pages/CoinWithdrawal/ProductListing";
import TransactionsStatisticKO from "src/pages/TransactionsStatisticKO";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard, faBox } from "@fortawesome/free-solid-svg-icons";

const getRoutes = (userRole) =>
  [
    {
      path: "/",
      component: Home,
      name: "routeName.home",
      icon: <HomeOutlined />,
      exact: true,
    },
    {
      path: "/stores/create",
      component: CreateStore,
      showOnSideBar: false,
    },
    {
      path: "/stores",
      component: Stores,
      name: "routeName.stores",
      icon: <ShopOutlined />,
      exact: true,
      category: "general",
    },
    {
      path: "/stores/:id",
      component: EditStore,
      showOnSideBar: false,
      exact: true,
    },
    {
      path: "/stores/:id/fee-list",
      component: StoreFeeList,
      showOnSideBar: false,
      exact: true,
    },
    {
      path: "/stores/:id/fee-list/create",
      component: CreateCustomizedFee,
      showOnSideBar: false,
      exact: true,
    },
    {
      path: "/stores/:id/fee-list/:feeId",
      component: EditFee,
      showOnSideBar: false,
      exact: true,
    },
    {
      path: "/transactions",
      component: Transactions,
      name: "routeName.transactionHistory",
      icon: <UnorderedListOutlined />,
      exact: true,
      category: "kocek_in",
    },
    {
      path: "/withdrawal/transactions",
      component: OrderListing,
      name: "routeName.orders",
      icon: <FontAwesomeIcon icon={faClipboard} style={{ fontSize: 14, marginLeft: 2 }} />,
      exact: true,
      category: "kocek_out",
    },
    {
      path: "/withdrawal/transactions/:id",
      component: OrderDetails,
      name: "order_details",
      showOnSideBar: false,
      backIcon: true,
      exact: true,
    },
    {
      path: "/withdrawal/products",
      component: ProductListing,
      name: "routeName.product",
      icon: <FontAwesomeIcon icon={faBox} style={{ fontSize: 14, marginLeft: 2 }} />,
      exact: true,
      category: "kocek_out",
    },
    {
      path: "/transaction-statistic",
      component: TransactionsStatistic,
      name: "routeName.statistic",
      icon: <LineChartOutlined />,
      exact: true,
      category: "statistics",
      children: [
        {
          path: "/transaction-statistic",
          component: TransactionsStatistic,
          name: "routeName.kocekIn",
          exact: true,
        },
        {
          path: "/transaction-statistic/kocek-out",
          component: TransactionsStatisticKO,
          name: "routeName.kocekOut",
          exact: true,
        },
      ],
    },
    {
      path: "/graph-analytic",
      component: GraphAnalytic,
      name: "routeName.graphAnalytic",
      icon: <BarChartOutlined />,
      exact: true,
      category: "statistics",
    },
    {
      path: "/account/change-password",
      component: ChangePassword,
      showOnSideBar: false,
      exact: true,
    },
    userRole === "super_admin" && {
      path: "/admins",
      component: AdminsPage,
      exact: true,
      name: "routeName.adminManage",
      icon: <UserOutlined />,
      category: "general",
    },
    userRole === "super_admin" && {
      path: "/admins/create",
      component: CreateAdminPage,
      showOnSideBar: false,
      exact: true,
    },
    userRole === "super_admin" && {
      path: "/admins/:name",
      component: EditAdmin,
      showOnSideBar: false,
      exact: true,
    },
    {
      path: "/coin-conversion-log",
      component: CoinConversionLog,
      name: "routeName.logs",
      icon: <HistoryOutlined />,
      exact: true,
      category: "general",
    },
    {
      path: "/global-fee",
      component: GlobalFee,
      name: "routeName.globalFee",
      icon: <DollarOutlined />,
      exact: true,
      category: "general",
    },
    {
      path: "/global-fee/create",
      component: CreateGlobalFee,
      exact: true,
      showOnSideBar: false,
    },
    {
      path: "/global-fee/:id",
      component: EditGlobalFee,
      exact: true,
      showOnSideBar: false,
    },

    {
      path: "/transaction-statistic/kocek-out",
      component: TransactionsStatisticKO,
      exact: true,
      showOnSideBar: false,
    },
  ].filter(Boolean);

const AuthorizedRoutes = (props) => {
  const userInfo = useSelector(userInfoSelector);
  const useRole = userInfo && userInfo.role;
  const routes = useMemo(() => getRoutes(useRole), [useRole]);
  const defaultRoute = routes[0];
  return (
    <Layout routes={routes}>
      <Switch>
        {routes.map((routeItem, index) => (
          <Route
            key={index}
            path={routeItem.path}
            component={routeItem.component}
            render={routeItem.render}
            exact={routeItem.exact}
          />
        ))}

        {defaultRoute && <Redirect to={defaultRoute.path} />}
      </Switch>
    </Layout>
  );
};
export default AuthorizedRoutes;
