import styled from "styled-components";

const OrderListingStyled = styled.div`
  width: 100%;
  height: 100%;

  .new-order-button-container {
    padding: 16px;

    .ant-tabs-nav: {
      margin-bottom: 10px !important;
    }

    .ant-picker-input input::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: black;
      opacity: 1; /* Firefox */
    }

    .ant-select-focused .ant-select-selection-placeholder {
      opacity: 0.4;
    }

    .ant-select-selection-placeholder {
      color: black;
      opacity: 1;
    }

    .ant-picker-input input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: red;
    }

    .ant-picker-input input::-ms-input-placeholder {
      /* Microsoft Edge */
      color: red;
    }

    .ant-tabs-nav::before {
      border-bottom: 1px solid #ececec;
    }

    .new-order-button {
      width: fit-content;
      margin-bottom: 10px;
    }

    .chip {
      padding: 6px 16px 6px 16px;
      border-radius: 100px;
      width: fit-content;
      font-size: 12px;
      font-weight: 500;
    }

    .pack-order-title {
      margin-bottom: 0px;
      font-size: 13px;
      color: #909090;
    }
  }
`;

export default OrderListingStyled;
