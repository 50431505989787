import React, { useState, useEffect } from "react";
import { Layout } from "antd";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import FilterBar from "./FilterBar";
import TableAdmins from "./TableAdmins";
import { fetchAdmins } from "APIService/admin";
import useAPILoading from "hooks/useAPILoading";
import useRouterQuery from "hooks/useRouterQuery";
import usePrevious from "hooks/usePrevious";
import getPaginationParams from "utils/getPaginationParams";
import TabRoles from "./TabRoles";
import styled from "styled-components";
// import { IoLogoHackernews } from 'react-icons/io';

const { Content } = Layout;

const Container = styled(Content)`
  padding: 32px 24px 32px 24px;
  .group {
    background: #fff;
    padding: 20px;
  }
`;

const AdminsPage = (props) => {
  const [getAdmin, loading] = useAPILoading(fetchAdmins);
  const [params, setParams] = useRouterQuery({
    pagination: { current: 1, pageSize: 10 },
    filter: { role: "", status: "", search: "", merchant_id: "" },
  });

  const prevParams = usePrevious(params);
  const [resData, setResData] = useState();
  // const [userRole, setUserRole] = useState('all_users');

  useEffect(() => {
    if (!isEqual(prevParams, params)) {
      onFetchAdmin(params);
    }
  }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  const onFetchAdmin = (params) => {
    getAdmin(getPaginationParams(params)).then(({ data }) => {
      setResData(data);
    });
  };

  const handleTableChange = (pagination = {}) => {
    const { current, pageSize } = pagination;
    setParams({ ...params, pagination: { current, pageSize } });
  };

  const handleFilterChange = (options) => {
    let { status, search, location } = options;
    let { pagination, filter } = params;
    pagination = { ...pagination, current: 1 };
    filter = { ...filter, status: status, search: search, merchant_id: location };

    setParams({ ...params, filter, pagination });
  };

  const onChangeTab = (role) => {
    let { filter, pagination } = params;
    filter = { status: "", search: "", merchant_id: "", role: role };
    pagination = { ...pagination, current: 1 };
    setParams({ ...params, filter, pagination });
  };
  const resetPage = () => {
    let { filter, pagination } = params;
    filter = { ...filter, status: "", search: "", merchant_id: "" };
    pagination = { ...pagination, current: 1 };
    setParams({ ...params, filter, pagination });
  };

  return (
    <Container>
      <TabRoles role={params.filter.role} onChange={onChangeTab} />
      <div className="group">
        <FilterBar
          // initialValues={params.filter}
          onSubmit={handleFilterChange}
          onReset={resetPage}
          role={params.filter.role}
          // loading={loading}
        />
        <TableAdmins
          dataSource={get(resData, "data")}
          pagination={{ ...params.pagination, total: get(resData, "total") }}
          role={params.filter.role}
          loading={loading}
          onChange={handleTableChange}
        />
      </div>
    </Container>
  );
};

export default AdminsPage;
