import axios from "./axios";
import { GET_PRODUCT_MERCHANT } from "./APILinks";
import { apiCreateKocekOutToken } from "./utils";


export const fetchProducts = async (merchantId) => {
  try {
    const token = await apiCreateKocekOutToken();
    // eslint-disable-next-line
    const response = await axios.get(GET_PRODUCT_MERCHANT, {
      headers: {
        Authorization: `${token.data.token_type} ${token.data.access_token}`,
      },
      params: {
        merchant_id: merchantId,
      },
    });

    return response;

  } catch (error) {
    // Handle errors
    console.error("Error fetching product:", error);
  }
};