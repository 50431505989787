import { useMemo, useState, useEffect } from "react";
import { TreeSelect } from "antd";
import get from "lodash/get";
import { useTranslation } from "react-i18next";

import { apiGetBanks } from "APIService/utils";

const useTreeData = () => {
  const [banks, setBanks] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    apiGetBanks().then((res) => {
      const banks = get(res, "data.banks");
      setBanks(banks);
    });
  }, []);

  const treeOptions = useMemo(() => {
    return [
      {
        value: JSON.stringify({ sendTo: "CHARITY" }),
        title: t("charity"),
        isLeaf: true,
      },
      {
        value: JSON.stringify({ sendTo: "BANK" }),
        title: t("bank"),
        children: banks.map((bank) => ({
          value: JSON.stringify({ sendTo: "BANK", bankId: bank.id }),
          title: (
            <div>
              <img src={bank.logo} alt="bank-logo" style={{ height: "2em" }} /> {bank.name}
            </div>
          ),
        })),
      },
      {
        value: JSON.stringify({ sendTo: "VOUCHER" }),
        title: t("voucher"),
        isLeaf: true,
      },
    ];
  }, [banks, t]);

  return treeOptions;
};

const OptionTransferTo = ({ value, onChange }) => {
  const { t } = useTranslation();
  const treeData = useTreeData();

  const onChangeOption = (stringValue) => {
    //value maybe null
    onChange(stringValue && JSON.parse(stringValue));
  };

  return (
    <TreeSelect
      value={value && JSON.stringify(value)} //value maybe null
      onChange={onChangeOption}
      showArrow={false}
      dropdownMatchSelectWidth={350}
      style={{ width: "100%" }}
      placeholder={t("sendTo")}
      allowClear
      treeData={treeData}
    />
  );
};

export default OptionTransferTo;
