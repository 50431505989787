import styled from "styled-components";

const TableTransactionStyled = styled.div`
  position: relative;

  .transaction-status {
    cursor: pointer;

    &.waiting-transfer {
      color: #7d2000;
      background: #fff7f0;
    }
  }

  .action-bar {
    position: absolute;
    right: 0;
    bottom: 100%;
  }
`;

export default TableTransactionStyled;
