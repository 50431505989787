import { Form, Input, Select } from "antd";
import { useTranslation } from "react-i18next";
import { find } from "lodash";

const { Option } = Select;

const EditBankInfoForm = (props) => {
  const { banks, loadingBank, currentBankInfo, onUpdateBankInfo } = props;

  const {
    transaction_detail_bank_account_number: oldBankAccNo,
    transaction_detail_bank_account_name: oldBankAccName,
    bank_id: oldBankId,
  } = currentBankInfo;

  const formInitialValues = {
    newBank: oldBankId,
    newBankAccNo: oldBankAccNo,
    newBankAccName: oldBankAccName,
  };

  const { t } = useTranslation();

  return (
    <Form
      id="edit-bank-form"
      name="form"
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 15 }}
      layout="horizontal"
      onFinish={onUpdateBankInfo}
      initialValues={formInitialValues}
    >
      <Form.Item name="newBank" label={t("bank")} rules={[{ required: true, message: t("selectBank") }]}>
        <Select showSearch placeholder={t("selectBank")} optionFilterProp="label" filterOption loading={loadingBank}>
          {banks.map((item) => (
            <Option key={item.id} label={item.name} value={item.id}>
              <BankDisplayContainers bank={item} />
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item noStyle dependencies={["newBank"]}>
        {(form) => {
          const newBankId = form.getFieldValue("newBank");
          let rules;
          if (!newBankId) {
            rules = [
              { required: true, message: t("pleaseInputBankAccNo") },
              {
                pattern: "^[0-9]*$",
                message: t("bankAccNoInvalid"),
              },
            ];
          } else {
            const { accountNumberMaxLength, accountNumberMinLength } = find(banks, { id: newBankId });

            rules = [
              { required: true, message: t("pleaseInputBankAccNo") },
              {
                pattern: "^[0-9]*$",
                message: t("bankAccNoInvalid"),
              },
              {
                min: accountNumberMinLength,
                message: t("requireBankNumberAccountLength")(accountNumberMinLength, accountNumberMaxLength),
              },
              {
                max: accountNumberMaxLength,
                message: t("requireBankNumberAccountLength")(accountNumberMinLength, accountNumberMaxLength),
              },
            ];
          }

          return (
            <Form.Item label={t("bankAccountNumber")} name="newBankAccNo" rules={rules}>
              <Input autoComplete="off" />
            </Form.Item>
          );
        }}
      </Form.Item>

      <Form.Item
        label={t("bankAccountName")}
        name="newBankAccName"
        rules={[
          { required: true, message: t("pleaseInputBankAccName") },
          {
            pattern: `^[-a-z A-Z@/']*$`,
            message: t("bankAccNameInvalid"),
          },
        ]}
        normalize={(value) => (value || "").toUpperCase()}
      >
        <Input autoComplete="off" />
      </Form.Item>
    </Form>
  );
};

const BankDisplayContainers = ({ bank }) => {
  return (
    <div>
      <img src={bank.logo} alt="bank-logo" style={{ height: "2em" }} /> {bank.name}
    </div>
  );
};
export default EditBankInfoForm;
